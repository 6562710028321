<template>
<div class="descPanel">


  <p>
    {{TaskDisc}}
  </p>
  <p>Your responses may be monitored by your clinician or assessor. You can complete the task at your own pace.</p>
  <p>
    Your data will be saved at every step, so if you happen to drop out of the
    task, you can log back in and pick up from where you left off.
  </p>
  <p>
    If you have any questions during the tasks, please ask your clinician/assesor for clarification.
  </p>
  <p>When you are ready to start, please press the next button.</p>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity';

export default 
{
    props:['taskDescription'], 
    setup(props){
        const TaskDisc = ref(props.taskDescription)

        return {TaskDisc}
    }
};
</script>

<style>

.descPanel p {
  font-size: 20px;
  padding: 10px 10px;
  font-weight: 400;
}
</style>