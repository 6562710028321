<template>
  <div class="TaskCompSelection">
      <div class="TaskCompSelectionItem">
        <div class="TaskCompHeader">
          <h2>{{TaskName}}</h2>
          <p>{{stringifiedDate}}</p>
          <div class="moduleLine">
            <div v-if="modules[0] == 'true'"><p>Card Sort</p></div>
            <div v-if="modules[1] == 'true'"><p>Top Five</p></div>
            <div v-if="modules[2] == 'true'"><p>Final Card</p></div>
            <div v-if="modules[3] == 'true'"><p> Worksheet</p></div>
          </div>
        </div>
        
        
        <span class="material-icons-round editButton" @click="EditTask()">edit</span>

      </div>
       <hr>
      
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import {useRouter} from 'vue-router'
import convertTimestamp from '../composables/convertTimestamp'
export default {
  props: ["TaskName", "TaskOptions",  "TaskRouteDetails", "TaskIndex"],
  setup(props) {
    const TaskName = ref(props.TaskName);
    const startDate = ref('No Date Set')
    const stringifiedDate = ref('No Date Set')
    const modules = ref('No Modules Set')
    const settings = ref(props.TaskOptions)
    const router = useRouter();


    const setupTask = () => 
    {
        if(settings.value)
        {
          modules.value = []
          settings.value.Modules.forEach(element => {
              modules.value.push(element)
          });
          if(settings.value.StartDate !== ''){
            startDate.value = Date.parse(settings.value.StartDate)
            stringifiedDate.value = convertTimestamp(startDate.value)
          }
        }
    }

    setupTask()
    const EditTask = () => 
    {
        router.push({name:"EditTask", params: {
          groupID: props.TaskRouteDetails.parentRoute,
          taskName : props.TaskName,
          taskIndex: props.TaskIndex,
          modules: modules.value,
          startDate: settings.value.StartDate 
        }})
    }


    return { TaskName, startDate, modules,settings, stringifiedDate,  EditTask };
  },
  
};
</script>

<style>
.TaskCompSelection {
  padding: 3px 20px;
  background-color: white;
}
.TaskCompSelectionItem {
  padding: 0px 20px;
  align-items: center;
  justify-items: center;
  display: flex;
  justify-content: space-between;
}

.TaskCompHeader h2 
{
  font-size: 20px;
  text-align: left;
}

.TaskCompHeader p 
{
  padding: 10px 20px;
  font-size: 18px;
  text-align: left;
}

.TaskCompHeader {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.editButton:hover
{
  color: #F37539;
  cursor: pointer;
}

.moduleLine
{
  display: flex;
}
</style>