<template>
  <div class="container list">
    <div class="groupHeader">
      <h2>Manage users</h2>
      <span class="material-icons-round add" @click="toggleAddUserPanel"
        >add</span
      >
    </div>

    <div class="listContainer" @click="toggleViewUserPanel('admin')">
      <div class="groupitem">
        <div class="textContent">
          <h2>Admins</h2>
        </div>
        <span class="material-icons-round arrow"> chevron_right </span>
      </div>
      <hr />
    </div>

      <div class="listContainer" @click="toggleViewUserPanel('viewers')">
      <div class="groupitem">
        <div class="textContent">
          <h2>Viewers</h2>
        </div>
        <span class="material-icons-round arrow"> chevron_right </span>
      </div>
      <hr />
    </div>

      <div class="listContainer" @click="toggleViewUserPanel('users')">
      <div class="groupitem">
        <div class="textContent">
          <h2>Users</h2>
        </div>
        <span class="material-icons-round arrow"> chevron_right </span>
      </div>
      <hr />
    </div>


  </div>



  <div class="container list" v-if="addUserPanelVisible">
    <div class="groupHeader">
      <h2>Add users</h2>
    </div>
    <SignUpForm @signup="toggleAddUserPanel"/>
  </div>

  <div class="container list" v-if="viewUserPanel">
    <div class="groupHeader">
      <h2>{{currentViewValue}}</h2>
    </div>
    <UserGroupView :data="currentViewValue"/>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import SignUpForm from "../../SignupForm.vue";
import UserGroupView from "../UsersGroup/UserGroupView.vue"
import { nextTick } from '@vue/runtime-core';
export default {
  components: { SignUpForm, UserGroupView },

  setup() {
    const addUserPanelVisible = ref(false);
    const viewUserPanel = ref(false);
    const currentViewValue = ref('')

    const toggleAddUserPanel = () => {
      addUserPanelVisible.value = !addUserPanelVisible.value;
      viewUserPanel.value = false;
      currentViewValue.value = ''
    };

    const toggleViewUserPanel = (panelType) => {
      viewUserPanel.value = false
      addUserPanelVisible.value = false
      if(panelType != currentViewValue.value){

          currentViewValue.value = panelType

          nextTick(function() {
              viewUserPanel.value = true
          })
          
      }
      else
      {
        currentViewValue.value = ''
      }
        
    }

    return { toggleAddUserPanel, addUserPanelVisible, viewUserPanel, toggleViewUserPanel, currentViewValue };
  },
};
</script>

<style>
.container.list {
  border-radius: 10px;
  margin: 10px 10px;
  min-width: 300px;
  min-height: 605px;
}

.container.list .groupHeader h2 {
  font-size: 18px;
  font-weight: 100;
  padding: 10px 30px;
}

.container.list .groupHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 50px;
}

.listContainer .groupitem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 10px;
}
.listContainer .groupitem .textContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-items: center;
  justify-content: center;
}

.listContainer .arrow {
  font-size: 20px;
  align-items: center;
}
.listContainer h2 {
  font-size: 25px;
}

.listContainer h3 {
  font-size: 15px;
  padding: 3px 2px;
}
.listContainer hr {
  width: 95%;
  margin: 0px auto;
  background-color: #ccc;
}

.listContainer:hover {
  cursor: pointer;
  color: #f26522;
}
.listContainer {
  display: block;
  padding: 10px;
}
</style>