<template>
  <div class="scrollBoxView" >
     <div class="cardView" v-for="(card, index) in cardUrls" :key="card.index">
         <div>
            <div>
            <Cards :imgURL="card.cardURL" 
             class="cardView" 
             :highlighted="highLighted[index]"
             :small="true"
             :hoverDisabled="true"/>
            </div>
         </div>
     </div>
            
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import Cards from '../../ClientComps/TaskComps/Cards'
import { onBeforeMount, watch } from '@vue/runtime-core'
export default 
{
    props: ['userData'],
    components: {Cards},
    setup(props)
    {
        const vimp = ref(props.userData.data.vimp)
        const imp = ref(props.userData.data.imp)
        const nimp = ref(props.userData.data.nimp)
        const top5 = ref(props.userData.data.Top5)
        const cardUrls = ref([])
        const highLighted = ref([])

        const assignCards = () =>
        {
            cardUrls.value = vimp.value;
            if(cardUrls.value.length < 5)
            {
                cardUrls.value = cardUrls.value.concat(imp.value)
                cardUrls.value = cardUrls.value.concat(nimp.value)
                cardUrls.value = cardUrls.value.slice(0, 5)
            }

            for (let index = 0; index < cardUrls.value.length; index++) 
            {
                highLighted.value.push(false)        
            }

            if(top5.value.length > 0)
            {
                for (let i = 0; i < cardUrls.value.length; i++) 
                {
                    for (let y = 0; y < top5.value.length; y++) 
                    {
                            
                        if(cardUrls.value[i].index === top5.value[y].index)
                        {
                            highLighted.value[i] = true
                            
                        }
                            
                    }
                    
                } 
            }
        }

        watch(() => top5.value, () => 
        {
            assignCards();
        })

        onBeforeMount(() => 
        {
            assignCards();
        })

        return {
            cardUrls,
            highLighted
        }
    }
}
</script>

<style>

</style>