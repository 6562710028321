import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store/store";
import './assets/main.css'

import {projectAuth, projectFirestore} from './firebase/config'

let app

projectAuth.onAuthStateChanged(async user => {
    if(!app)
    {   
        
        var user = await projectAuth.currentUser;
        if(user)
        {
        var userToken = await projectAuth.currentUser.getIdTokenResult()

        var permissions = userToken.claims;
        var collection = ''
        var priv = ''
        if (permissions.admin) {
            collection = 'admin'
            priv = 'admin'
        }

        if (permissions.viewer) {
            collection = 'viewers'
            priv= 'viewer'
        }

        if (permissions.user) {
            collection = 'users'
            priv = 'user'
        }

        if(collection !== ''){
        var doccument = await projectFirestore.collection(collection)
        .doc(projectAuth.currentUser.uid).get();

        projectFirestore.collection(collection).doc(projectAuth.currentUser.uid).update({online: true})
        window.addEventListener("beforeunload", function(e)
        {
            projectFirestore.collection(collection).doc(projectAuth.currentUser.uid).update({online: false})
        })

        let data = doccument.data()

        store.commit('storeCurrentUser',
        { uid: projectAuth.currentUser.uid, 
            priv: priv, 
            firstName: data.FirstName,
            lastName: data.LastName,
            email: data.email,
            orgUID: data.OrgUID,
            groups: data.groups})
        }
    }
        
        console.log(user)
        app = createApp(App)
                .use(router).use(store)
                .mount('#app')
    }
})


