<template>
  <div class="UserGroupViewCont" v-if="dataReady">
      <div v-for="(user, index) in profiles" :key="index">
          <UserListItem 
          :FirstName="user.FirstName" 
          :LastName="user.LastName" />
      </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import {projectFirestore} from "../../../firebase/config"
import { onBeforeMount, onMounted } from '@vue/runtime-core'
import store from "../../../store/store"
import UserListItem from "../UserListItem.vue"
export default {
    components: {UserListItem},
    props: ['data'],
    setup(props) 
    {
    
        const profiles = ref([])
        const dataReady = ref(false)
        
        onBeforeMount(async () => 
        {
            let groupIDs = []
            let res = await projectFirestore.collection('orgs').doc(store.state.user.orgUID).get()
            let data = res.data();

             if (props.data === 'admin') {
                groupIDs = data.Admins
                
            }
            else if (props.data === 'viewers') {
                groupIDs = data.Viewers
                
            }
            else if (props.data === 'users') {
                groupIDs = data.Users
                
            }

           if(groupIDs && groupIDs.length !== 0)
            {
            const promises = groupIDs.map((id) => {
                return projectFirestore.collection(props.data).doc(id).get();
            })

            await Promise.all(promises).then((data) => 
            {
                dataReady.value = false;
                profiles.value = []
                for (let index = 0; index < data.length; index++) {
                    const profile = data[index].data();
                    profiles.value.push(profile)
      

                    if(index == data.length -1)
                    {
                        dataReady.value = true;
                      
                    }
                }
            })
            }


        })
        return {profiles, dataReady}
    }
}
</script>

<style>

</style>