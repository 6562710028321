<template>
  <Navbar />
  <div v-if="dataReady && redraw">
    <div class="container-large">

  
    <div class="userAssignmentContainer">
      <div class="unassignedUsers">
        <h2>Unassigned Users</h2>
        <div class="columnOne">
          <draggable
            ghost-class="list-group"
            :list="unassignedUsers"
            :onAdd="redrawPanel"
            group="people"
            itemKey="name"
          >
                <template #header>
              <div v-if="unassignedUsers.length === 0">
                <UserDraggableItem :placeholderText="'Drag here to unassign'" />
              </div>
            </template>

            <template #item="{ element }">
              <div class="list-group-item">
                <UserDraggableItem :userInfo="element" />
              </div>
            </template>
          </draggable>
        </div>
      </div>
      <div class="assignedUsers">
         <h2>Assigned Users</h2>
        <div class="columnOne">
          <draggable
            ghost-class="list-group"
            :list="usersAssignedToTargetGroup"
            :onAdd="redrawPanel"
            group="people"
            itemKey="name"
          >
            <template #header>
              <div v-if="usersAssignedToTargetGroup.length === 0">
                <UserDraggableItem :placeholderText="'Drag here to assign'" />
              </div>
            </template>

            <template #item="{ element }">
              <div class="list-group-item">
                <UserDraggableItem :userInfo="element" />
              </div>
            </template>
          </draggable>
        </div>
      </div>

  
    </div>
        <div class="buttonFooter">
          <button @click='Cancel'>Cancel</button> <button @click='Confirm'>Confirm</button>
      </div>
  </div>
    </div>
</template>

<script>
import Navbar from "../../components/Navbar.vue";
import { useRouter, useRoute } from "vue-router";
import { projectFirestore } from "../../firebase/config";
import { ref } from "@vue/reactivity";
import { nextTick, onBeforeMount } from "@vue/runtime-core";
import store from "../../store/store";
import draggable from "vuedraggable";
import UserDraggableItem from "../../components/AdminComps/UserDraggableItem.vue";
export default {
  components: { Navbar, draggable, UserDraggableItem },
  props: ["groupID", "currentUsers"],
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const usersAssignedToOthergroups = ref([]);
    const unassignedUsers = ref([]);
    const usersAssignedToTargetGroup = ref([]);

    const dataReady = ref(false);
    const redraw = ref(false)
    onBeforeMount(async () => {

        
      


      let result = await projectFirestore
        .collection("orgs")
        .doc(store.state.user.orgUID)
        .get();
      let data = result.data();
      const promises = data.Users.map((id) => {
        return projectFirestore.collection("users").doc(id).get();
      });


      Promise.all(promises).then((res) => {

        console.log(res.length)
        for (let index = 0; index < res.length; index++) {
          const element = res[index].data();
          console.log(element)
          if (element.groups.includes(route.params.groupID)) {
            
            usersAssignedToTargetGroup.value.push(element);
          } else if (element.groups[0].length > 0 && element.groups[0] !== "") {
         
            usersAssignedToOthergroups.value.push(element);
          } else {
            
            unassignedUsers.value.push(element);
          }
        }

        dataReady.value = true;
        redraw.value = true;
      });
      });

    const Confirm = () => 
    {
        let users = []
        for (let index = 0; index < usersAssignedToTargetGroup.value.length; index++) {
          usersAssignedToTargetGroup.value[index].groups = [route.params.groupID];
          projectFirestore.collection('users').doc(usersAssignedToTargetGroup.value[index].UID)
          .update(usersAssignedToTargetGroup.value[index])
          users.push(usersAssignedToTargetGroup.value[index].UID)
        }

        for (let index = 0; index < unassignedUsers.value.length; index++) {
          unassignedUsers.value[index].groups = [''];
          projectFirestore.collection('users').doc(unassignedUsers.value[index].UID)
          .update(unassignedUsers.value[index])
        }
        
    
        projectFirestore.collection('groups').doc(route.params.groupID)
          .update({GroupUsers: users})

        router.push(({name:'ManageTasks', groupID: route.params.groupID}))
    }
    const Cancel = () => 
    {
         router.push(({name:'ManageTasks', groupID: route.params.groupID}))
    }

    const redrawPanel = () => 
    {
        redraw.value = false;
        nextTick(() =>{
          redraw.value = true;
        })
    }
    return {
      unassignedUsers,
      usersAssignedToOthergroups,
      usersAssignedToTargetGroup,
      dataReady,
      redraw,
      redrawPanel,
      Confirm,
      Cancel
    };
  },
};
</script>

<style>
.userAssignmentContainer {
  display: flex;
  justify-content: space-evenly;
  min-height: 900px;
}

.list-group {
  background: #e7e7e7;
}
.unassignedUsers {

  width: 50%;
}
.assignedUsers {

  width: 50%;
}
.columnOne
{
  padding: 30px 20px;
}
.vl {
  border-left: 6px solid #F26522;
  border-radius: 20px;
  height: 800px;
  left: 50%;
  margin-left: -3px;
  top: 0;
  margin-top: 10px;
}
.buttonFooter
{
  margin: 20px 20px;
}
</style>