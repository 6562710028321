<template>
  <div class="pileViewContainer">
        <div class="pileViewFooter">
        <div class="pileButtonContainer" v-if="fiveSelected">
            <button @click="moveToTaskThree">Continue</button>
        </div>
        
    </div>
      <div class="pileViewBody">
        <div class="pileViewInstructions">
            <h2>Instructions</h2>
            <p>You will now be shown all of the values you sorted into the "Very Important" pile.</p>
            <p>You need to select your top 5 values out of these cards.</p>
            <p>Do this by clicking on the five cards you want. </p>
            <p>Your selections will be highlighted green.</p>
        </div>
      <TaskOnePileView :interactable="true" @cardsSelected="checkIfFiveSelected" :viewMode="viewMode" @SkipSelection="moveToTaskThree"/>
      </div>
  
  </div>

</template>

<script>
import { ref } from '@vue/reactivity'

import TaskOnePileView from '../TaskOne/TaskOnePileView.vue'

export default 
{
    components: {TaskOnePileView},
    props: ['viewMode'],
    emits:['moveToTopOne'],
    setup(props, context) 
    {  
        const viewMode = ref(props.viewMode)
        const fiveSelected = ref(false)
        const checkIfFiveSelected = (number) => {
            if(number) {
                fiveSelected.value = true
            }
            else
            {
                fiveSelected.value = false;
            }
        }

        const moveToTaskThree = () => 
        {
            context.emit('moveToTopOne')
        }
        
        return {viewMode, fiveSelected, checkIfFiveSelected, moveToTaskThree}
    }
}
</script>

<style>
.pileViewContainer 
{
    width: 1400px;
    min-height: 400px;
    margin: 80px auto;
    border-radius: 20px;
    box-shadow: 2px 4px 6px rgba(28, 6, 49, 0.1);
    background: white;
}

.pileViewContainer .pileViewBody 
{
    display: flex;
    justify-content: space-between;
    padding: 20px 20px;
}
.pileViewContainer .pileViewBody .pileViewInstructions 
{
  max-width: 20%;
}

.pileViewContainer .pileViewBody .pileViewInstructions h2
{
    text-align: left;
    padding: 10px 10px;
}

.pileViewContainer .pileViewBody .pileViewInstructions p
{
    text-align: left;
    padding: 10px 10px;
}

.pileViewContainer .pileViewFooter 
{
    padding: 30px 30px;
}
</style>