import { ref } from "vue"
import { projectAuth, projectFirestore, firebaseDatabase, timestamp } from "../firebase/config"
import store from "../store/store"
const error = ref(null)

const login = async (email, password) => {
    error.value = null;

    try {
        const res = await projectAuth.signInWithEmailAndPassword(email, password)
        const tokenResults = ref('')
        const collection = ref('')
        const priv = ref('')

        if (projectAuth.currentUser) {
            await projectAuth.currentUser.getIdTokenResult().then(idTokenResult => {


                tokenResults.value = idTokenResult.claims
                
            })

            if (tokenResults.value.admin) {
                collection.value = 'admin'
                priv.value = 'admin'
            }

            if (tokenResults.value.viewer) {
                priv.value = 'viewer'
                collection.value = 'viewers'
            }

            if (tokenResults.value.user) {
                priv.value = 'user'
                collection.value = 'users'
            }

            if(collection.value !== '')
            {
            let doccumentID = await projectFirestore.collection(collection.value)
            .doc(projectAuth.currentUser.uid).get()
            

            

           
            projectFirestore.collection(collection.value).doc(projectAuth.currentUser.uid).update({online: true})
            window.addEventListener("beforeunload", function(e)
            {
                projectFirestore.collection(collection.value).doc(projectAuth.currentUser.uid).update({online: false})
            })
        

            let data = doccumentID.data()
            store.commit('storeCurrentUser',
                { uid: projectAuth.currentUser.uid, 
                    priv: priv.value, 
                    firstName: data.FirstName,
                    lastName: data.LastName,
                    email: email,
                    orgUID: data.OrgUID,
                    groups: data.groups})
                    error.value = null;
        }
    }
    else
    {
        error.value = 'something went wrong'
    }
      
        
        return res
    }
    catch (err) {
        
        error.value = err.message
    }
}

const useLogin = () => {
    return { error, login }
}

export default useLogin