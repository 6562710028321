<template>
  <div class="surveyContentFormatting">
    
    <div class="mainContent">
      <div class="surveyPanelContainer">
        <div class="surveyPanelBody">
          
          <div class="goalsAndObjectives">
            <button class="left-justified" @click="openPrintDialogue()" data-html2canvas-ignore="true">Print PDF</button>
            <div class="goalsHeader">
              <h2>Goals and Actions For This Week</h2>
              
            </div>
            <div class="goalsBody">
              <div>
                <h3>Goal One</h3>
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  class="textFormatting"
                  v-on:blur="updateDB(goalActionOne)"
                  v-model="goalActionOne"
                  :disabled="viewMode === 'true'"
                ></textarea>
              </div>
              <div>
                <h3>Goal Two</h3>
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  class="textFormatting"
                  v-on:blur="updateDB(goalActionTwo)"
                  v-model="goalActionTwo"
                  :disabled="viewMode === 'true'"
                ></textarea>
              </div>
              <div>
                <h3>Goal Three</h3>
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  class="textFormatting"
                  v-on:blur="updateDB(goalActionThree)"
                  v-model="goalActionThree"
                  :disabled="viewMode === 'true'"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="surveyPanelFooter">
          <span class="material-icons-round arrowPoint" data-html2canvas-ignore="true">
keyboard_double_arrow_down
</span>

        </div>
      </div>
      <div class="partTwoSurvey">
        <div class="surveyPanelContainer2">
          <div class="surveyPanelBody">
            <div class="barriers">
              <div class="barrierHeader">
                <img
                  src="../../../../assets/Worksheet/barrier_single.png"
                  alt=""
                />
                <h3>Barriers: what might stop you from achieving your goals</h3>
              </div>
              <div class="barrierBody">
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  v-on:blur="updateDB(barriers)"
                  v-model="barriers"
                  :disabled="viewMode === 'true'"
                ></textarea>
                <span class="material-icons-round arrowPoint" data-html2canvas-ignore="true">
                  keyboard_double_arrow_right
                </span>
              </div>
              <img
                src="../../../../assets/Worksheet/barrier_single.png"
                alt=""
              />
            </div>
          </div>
        </div>

        <div class="surveyPanelContainer2">
          <div class="surveyPanelBody">
            <div class="achieve">
              <div class="achieve_header">
                <h3>Strategies: What can i do to help me achieve my goals?</h3>
              </div>
              <div class="achieve_body">
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  v-on:blur="updateDB(solution)"
                  v-model="solution"
                  :disabled="viewMode === 'true'"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="surveyPanelContainer bordered">
        <div class="surveyPanelHeader">
          <div class="yourValue">
              <h2>Your Value</h2>
          <Cards :small="true" :imgURL="cardData.cardURL" />
          </div>
          
        </div>
      </div>
      <button @click="routeToEndScreen()" data-html2canvas-ignore="true">Finish</button>
    </div>
  </div>
</template>

<script>
import Cards from "../Cards.vue";
import store from "../../../../store/store";
import { ref } from "@vue/reactivity";
import { onBeforeMount } from "@vue/runtime-core";
import { projectFirestore } from "../../../../firebase/config";
import { useRouter } from "vue-router";
import emailjs from '@emailjs/browser';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';

export default {
  components: { Cards },
  props: ["viewMode"],
  setup(props) {
    const cardData = ref("");
    const barriers = ref("");
    const goalActionOne = ref("");
    const goalActionTwo = ref("");
    const goalActionThree = ref("");
    const solution = ref("");
    const router = useRouter();
    const viewMode = ref(props.viewMode);

    
    onBeforeMount(() => {
      cardData.value = store.state.lastChoice.finalCard;
      barriers.value = store.state.surveyAnswers.barriers;
      goalActionOne.value = store.state.surveyAnswers.goalActionOne;
      goalActionTwo.value = store.state.surveyAnswers.goalActionTwo;
      goalActionThree.value = store.state.surveyAnswers.goalActionThree;
      solution.value = store.state.surveyAnswers.solution;
    });

    const updateDB = (data) => {
      
      store.commit("storeBarriers", { barriers: barriers.value });
      store.commit("storeGoals", {
        goalsOne: goalActionOne.value,
        goalsTwo: goalActionTwo.value,
        goalsThree: goalActionThree.value,
      });
      store.commit("storeSolutions", { solutions: solution.value });

      projectFirestore
        .collection("SessionData")
        .doc(store.state.sessionMeta.sessionMetaDataLocation)
        .collection("data")
        .doc(store.state.sessionMeta.activeDataFile)
        .update({
          surveyResponses: {
            barriers: barriers.value,
            goalActionOne: goalActionOne.value,
            goalActionTwo: goalActionTwo.value,
            goalActionThree: goalActionThree.value,
            resolution: solution.value,
          },
        });
    };

    const routeToEndScreen = () => {
      projectFirestore
        .collection("SessionData")
        .doc(store.state.sessionMeta.sessionMetaDataLocation)
        .collection("data")
        .doc(store.state.sessionMeta.activeDataFile)
        .update({
          saveState: 6,
        });
      projectFirestore
        .collection("SessionData")
        .doc(store.state.sessionMeta.sessionMetaDataLocation)
        .get()
        .then((res) => {
          let data = res.data().SessionStates;
          data[store.state.sessionMeta.currentSessionIndex].SessionState = 2;
          if(!data[store.state.sessionMeta.currentSessionIndex].completedDate){
            data[store.state.sessionMeta.currentSessionIndex].completedDate = new Date();
          }
          projectFirestore
            .collection("SessionData")
            .doc(store.state.sessionMeta.sessionMetaDataLocation)
            .update({
              SessionStates: data,
            });
        });
        sendEmail();
      router.push({ name: "ClientHome" });
    };

    const sendEmail = () => 
    {

        if(viewMode.value === 'true' || viewMode.value === true)
        {
          //return;
        }

        emailjs.send('service_3obt5uy', 'template_ggajb2n', 
        {
            'reply_to': store.state.user.email,
            'barriers': barriers.value,
            'goalActionOne': goalActionOne.value,
            'goalActionTwo': goalActionTwo.value,
            'goalActionThree': goalActionThree.value,
            'resolution': solution.value,
            'cardURL': cardData.value.cardURL,
            'cardValue': cardData.value.cardValue
        },
        '3DbgmPq2gsu3PVEuV')

    }


    const openPrintDialogue = () => 
    {
      
      var pdf = jsPDF({orientation: 'landscape'});
      pdf.html(document.body, 
      {  html2canvas: {
        // insert html2canvas options here, e.g.
         scale: 0.12
    }}
      ).then(() => 
      {
        pdf.save("values.pdf")
      })
      
         
    }

    return {
      cardData,
      updateDB,
      barriers,
      goalActionOne,
      goalActionTwo,
      goalActionThree,
      solution,
      viewMode,
      routeToEndScreen,
      openPrintDialogue
    };
  },
};
</script>

<style>
.surveyContentFormatting {
  max-width: 1300px;
  background-color: #e7e7e7;
  border-radius: 20px;
  margin: 30px auto;
  padding: 10px 5px;
  display: flex;
  justify-content: space-around;
  box-shadow: 2px 4px 6px rgba(28, 6, 49, 0.1);
}
.surveyPanelContainer {
  width: 90%;
  max-width: 980px;
  min-width: 980px;
  min-height: 200px;
  margin: 20px auto;
  padding: 20px 10px;
  border-radius: 20px;
  box-shadow: 2px 4px 6px rgba(28, 6, 49, 0.1);
  background: white;
}

.surveyPanelContainer2 {
  width: 50%;
  max-width: 980px;
  min-height: 200px;
  margin: 10px 5px;
  padding: 20px 10px;
  border-radius: 20px;
  box-shadow: 2px 4px 6px rgba(28, 6, 49, 0.1);
  background: white;
}

.surveyPanelContainer .surveyPanelHeader {
  display: inline;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.surveyPanelContainer .surveyPanelHeader h2 {
  padding: 30px 30px;
}

.surveyPanelContainer .surveyPanelBody {
  align-items: center;
  display: flex;
  justify-content: space-evenly;
}

.surveyPanelContainer .surveyPanelBody .goalsAndObjectives {
    background-image: url("../../../../assets/Worksheet/bus.png");
    background-repeat: no-repeat;
    background-position-y: 10px;
    background-position-x: 60px;

}
.surveyPanelContainer .surveyPanelBody .goalsAndObjectives .goalsBody {
  display: flex;
  justify-content: space-around;
  padding: 10px 10px;

}

.surveyPanelContainer
  .surveyPanelBody
  .goalsAndObjectives
  .goalsBody
  .textFormatting {
  margin: 10px 10px;
}

.surveyPanelContainer .surveyPanelBody .goalsAndObjectives .goalsHeader {
  padding: 30px 30px;

}

.surveyPanelContainer .surveyPanelBody .barriers {
  padding: 20px 20px;
}

.surveyPanelContainer2 .surveyPanelBody .achieve {
  padding: 20px 20px;
}
.achieve_body {
  display: flex;
  justify-content: space-around;
  justify-items: center;
  align-items: center;
  padding: 50px 20px;
  background-image: url("../../../../assets/Worksheet/target.png");
  background-repeat: no-repeat;
  background-position: center;

}
.arrowPoint {
  font-size: 90px;
}

.barrierBody {
  display: flex;
  justify-content: space-around;
  justify-items: center;
  align-items: center;
  padding: 20px 20px;
}
textarea {
  border-radius: 20px;
  min-width: 300px;
  min-height: 200px;
  padding: 10px 10px;
  outline: none;
  font-size: 15px;
}
textarea:focus {
  box-shadow: 0 0 3pt 2pt cornflowerblue;
}

.partTwoSurvey {
  display: flex;
  max-width: 980px;
  margin: 10px auto;
  justify-content: space-between;
}

.surveyContentFormatting .surveyInstructionsPane {
  width: 20%;
  padding: 10px, 10px;
  margin: 10px 10px;
  margin: 20px 5px;
  padding: 20px 10px;
  border-radius: 20px;
  box-shadow: 2px 4px 6px rgba(28, 6, 49, 0.1);
  background: white;
}


.bordered{
  border: 5px solid #7CCCBF;
}

@media print {
    body {
      
        transform: scale(0.7);
    }
}
</style>