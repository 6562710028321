<template>
  <transition name="itemPanel">
    <div class="container list">
      <div class="groupHeader">
        <h2>Active Groups</h2>
        <span
          v-if="priv === 'admin'"
          class="material-icons-round add"
          @click="toggleGroupVisible"
          >add</span
        >
      </div>
      <div class="activeGroups">
        <div v-for="item in groupObjects" :key="item.groupID">
          <ListItem :data="item.element" @click="toggleGroupItem(item)" />
        </div>
      </div>
    </div>
  </transition>

  <transition name="itemPanel">
    <div class="container list addGroup" v-show="addGroupVisible">
      <div class="groupHeader">
        <h2>Add New Group</h2>
      </div>
      <AddNewGroup @cancel="toggleGroupVisible" />
    </div>
  </transition>

  <transition name="itemPanel">
    <div class="container list viewGroup" v-if="viewGroupVisible">
      <div class="groupHeader">
        <GroupDetails :data="groupDetails" @loadUserDetails="toggleUserView" />
      </div>
    </div>
  </transition>

  <transition name="itemPanel">
    <div class="container list viewGroup" v-if="userView">
      <div class="groupHeader">
        <UserLists :data="selectedViewingGroup"/>
      </div>
    </div>
  </transition>
</template>

<script>
import { ref } from "@vue/reactivity";
import store from "../store/store";
import { projectFirestore } from "../firebase/config";
import { nextTick } from "@vue/runtime-core";

import AddNewGroup from "../components/AdminComps/GroupForms/AddNewGroup.vue";
import ListItem from "../components/AdminComps/ListItem.vue";
import GroupDetails from "../components/AdminComps/GroupDetails.vue";
import UserLists from "../components/AdminComps/UserLists.vue";

export default {
  components: { AddNewGroup, ListItem, GroupDetails, UserLists },
  props: [],
  created() {
    if (store.state.user.priv === "admin") {
      projectFirestore
        .collection("admin")
        .doc(store.state.user.key)
        .onSnapshot((snapshot) => {
          let t_data = snapshot.data();
          this.groupIDs = t_data.groups;

          if(this.groupIDs === undefined)
          {
            return;
          }
          const promises = this.groupIDs.map((id) => {
            return projectFirestore.collection("groups").doc(id).get();
          });

          Promise.all(promises)
            .then((data) => {
              this.groupObjects = [];
              for (let index = 0; index < data.length; index++) {
                const element = data[index].data();

                const newElement = {
                  groupID: this.groupIDs[index],
                  element,
                };
                //
                this.groupObjects.push(newElement);
              }
            })
            .catch((err) => {
              
            });
        });
    }
  },
  setup() {
    const addGroupVisible = ref(false);
    const viewGroupVisible = ref(false);
    const groups = ref(store.state.user.groups);
    const priv = ref(store.state.user.priv);
    const groupIDs = ref([]);

    const groupViewActive = ref("");
    const groupDetails = ref("");
    const groupObjects = ref([]);

    const userView = ref(false);
    const selectedViewingGroup = ref('')
    const currentlySelectedViewingGroup = ref('')

    const toggleGroupVisible = () => {
      viewGroupVisible.value = false;
      groupViewActive.value = "";
      currentlySelectedViewingGroup.value = ''
      userView.value = false;
      nextTick(function () {
        addGroupVisible.value = !addGroupVisible.value;
      });
    };

    const toggleGroupItem = (item) => {
      addGroupVisible.value = false;
      viewGroupVisible.value = false;
      userView.value = false;
      currentlySelectedViewingGroup.value = ''

      if (groupViewActive.value == item.groupID) {
        groupViewActive.value = "";
        groupDetails.value = "";
      } else {
        groupViewActive.value = item.groupID;
        groupDetails.value = item;
        nextTick(function () {
          viewGroupVisible.value = true;
        });
      }
    };

    const toggleUserView = (group) => {
      
      userView.value = false

      if (groupDetails) {

        if(currentlySelectedViewingGroup.value === group){
          userView.value = false
          selectedViewingGroup.value = ''
          currentlySelectedViewingGroup.value = ''
          return
        }
        currentlySelectedViewingGroup.value = group
        if (group === "admin") 
        {
          
          selectedViewingGroup.value = {group: "admin", ids: groupDetails.value.element.GroupAdmins}
        } 
        else if (group === "viewers") 
        {
          selectedViewingGroup.value = {group: "viewers", ids: groupDetails.value.element.GroupViewers}
        } 
        else if (group === "users") 
        {
          selectedViewingGroup.value = {group: "users", ids: groupDetails.value.element.GroupUsers}
        }
        
        
        nextTick(function(){
          userView.value = true
        })
      }


    };

    return {
      addGroupVisible,
      viewGroupVisible,
      toggleGroupVisible,
      groups,
      priv,
      groupIDs,
      groupObjects,
      toggleGroupItem,
      groupDetails,
      toggleUserView,
      userView,
      selectedViewingGroup
    };
  },
};
</script>

<style>
.container.list {
  border-radius: 10px;
  margin: 10px 10px;
  min-width: 300px;
  min-height: 605px;
}
.container.list .groupHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 50px;
}
.container.list .groupHeader h2 {
  font-size: 18px;
  font-weight: 100;
  padding: 10px 30px;
}

.add {
  font-size: 30px;
  padding: 10px 20px;
  cursor: pointer;
  user-select: none;
}
</style>