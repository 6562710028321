<template>
<div class="outer">
    <div class="middle">
        <div class="welcome container inner">
            <div class="titleHeading">
                <img src="../assets/logo.png" width="45" height="45">
                <h2>Welcome to [Insert Title]</h2>
            </div>
            
            <LoginForm @login="loginUser" @resetPassword="resetPassword"/>
            
        </div>
    </div>
</div>
</template>

<script>
import LoginForm from '../components/LoginForm.vue'
import SignupForm from '../components/SignupForm.vue'
import {useRouter} from 'vue-router'
import getUser from '../composables/getUser'
import generateUserDataBundle from '../composables/generateUserDataBundle'
import { projectAuth, firebaseFuncs, projectFirestore } from "../firebase/config"
export default {
    components: {LoginForm, SignupForm},
    setup()
    {
        const router = useRouter()
        
        const loginUser = () =>
        {
            const { user, userToken } = getUser()
            if(userToken.value.user)
            {
                router.push({name: 'ClientHome'})
            }
            else if(userToken.value.admin) {
                router.push({name: 'AdminHome'})
            }
            else if(userToken.value.viewer)
            {
                router.push({name: 'MoniterGroup'})
            }
        }

        const resetPassword = () =>
        {
            router.push({name: 'ResetPassword'})
        }

        const {generateGroupDataBundle, error} = generateUserDataBundle()
        //generateGroupDataBundle('zvLroxIHmkc0nJZA2hwNACiool43')
         //const addRole = firebaseFuncs.httpsCallable('addUserRole');
        //addRole({emails: "D.Wong@latrobe.edu.au", permissions: "Admin"})
        return {loginUser, resetPassword}
    }
}
</script>

<style>

.welcome
{
    width: 100%;
    padding: 10px;
    border-radius: 20px;
    border: 1px solid #eee;
    outline: none;
    color: #999;
    margin: 20px auto;
}

.welcome input 
{
        width: 90%;
        padding: 15px;
        border-radius: 20px;
        border: 1px solid #eee;
        outline: none;
        color: #999;
        margin: 20px auto;
}

.welcome button {
        margin: 20px auto;
}

.welcome h3 
{
    text-align: left;
    padding: 5px 20px;
}

.welcome .titleHeading
{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px;
    
}
</style>