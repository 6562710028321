<template>
  <Navbar />
  <div class="big-boi-container" :v-if="dataReady">
    <div class="container-large-long">
        <h3>Task</h3>
        <select class="selectGroupForm" 
        v-model="selectedWeek" @change="SelectedWeekChange" :disabled="isWeekSelectDisabled"> 
            <option
          v-for="option in taskData"
          :key="option.id"
          :value="option"
        >
          {{option.text}}
        </option>
        </select>
        <hr>
        <h3>Users</h3>
        <SelectUserToView :userData='finalData' :groupID="groupID"
        :selectedWeek="selectedWeek"
        @userSelected="UserSelected" v-if="isReset"
        :selectedUserIDX="selectedUserIDX"
        @UserStateChanged="userStateChange"/>    
    </div>
    <div class="container-large-2">
      <div class="tabBanner">
          <button :class="['tablinks', {active: isActive[0]}]" @click="ToggleActiveState(0)">Card Sort 
              <span class="material-icons-round small" v-if="userState === 1"> radio_button_checked </span>
</button>
          <button :class="['tablinks', {active: isActive[1]}]" @click="ToggleActiveState(1)">Top five
               <span class="material-icons-round small" v-if="userState === 2"> radio_button_checked </span>
          </button>
          <button :class="['tablinks', {active: isActive[2]}]" @click="ToggleActiveState(2)">Top Card
               <span class="material-icons-round small" v-if="userState === 3"> radio_button_checked </span>
          </button>
          <button :class="['tablinks', {active: isActive[3]}]" @click="ToggleActiveState(3)">Worksheet
               <span class="material-icons-round small" v-if="userState >= 5"> radio_button_checked </span>
          </button>
      </div>
      <div class="content" v-if="isReset && selectedUser && selectedWeek">
            <div v-if="isActive[0]  && activeData.data.saveState > 0">
                <CardMonitor :userData="activeData" :selectedWeek="selectedWeek"/>
            </div>
            <div v-else-if="isActive[1] && activeData.data.saveState > 1">
                <TopFiveView :userData="activeData"/>
            </div>
            <div v-else-if="isActive[2] && activeData.data.saveState > 2 ">
              <FinalSelectMonitor :userData="activeData"/>
            </div>
            <div v-else-if="isActive[3] && activeData.data.saveState > 3">
              <SurveyMonitor :userData="activeData"/>
            </div>
            <div v-else>
                <h2 style="text-align:center; padding: 20px 20px; font-size: 18px">User Hasnt started this section yet</h2>
            </div>
      </div>
  </div>
  </div>

</template>

<script>
import Navbar from "../../components/Navbar.vue";
import {useRouter, useRoute} from 'vue-router'
import { ref } from '@vue/reactivity';
import { nextTick, onBeforeMount } from '@vue/runtime-core';
import { projectFirestore } from "../../firebase/config"
import SelectUserToView from "../../components/AdminComps/MonitoringComponents/SelectUserToView.vue"
import CardMonitor from "../../components/AdminComps/MonitoringComponents/CardMonitor.vue"
import TopFiveView from "../../components/AdminComps/MonitoringComponents/TopFiveView.vue"
import FinalSelectMonitor from "../../components/AdminComps/MonitoringComponents/FinalSelectMonitor.vue"
import SurveyMonitor from "../../components/AdminComps/MonitoringComponents/SurveyMonitor.vue"
export default 
{
    components: { Navbar, SelectUserToView, CardMonitor, TopFiveView, FinalSelectMonitor,
    SurveyMonitor}, 
    props:['groupID'],
    setup(props)
    {
        const route = useRoute();
        const dataReady = ref(false);
        const finalData = ref([]);
        const isActive = ref([true, false, false, false])
        const selectedUser = ref()
        const selectedUserSnapshotData = ref([])
        const snapShotDataRaw = ref([])
        const activeData = ref()
        const taskData = ref([{id:0, text:"Task One: Life overall"}, {id:1, text:"Task Two: Health"}, 
        {id:2, text:"Task Three: Having a purpose"}, {id:3, text:"Task Four: Leisure"}, 
        {id:4, text:"Task Five: Relationships"}])
        const selectedWeek = ref()
        const isReset = ref(false)
        const isWeekSelectDisabled = ref(true)
        const isRefreshed = ref(false)
        const onlineStates = ref([])
        const groupID = ref(route.params.groupID)
        const selectedUserIDX = ref(-1);
        const userState = ref(0)

        onBeforeMount(async () =>
        {
            
            let res = await projectFirestore.collection("groups").doc(groupID.value).get()
            let data = res.data();
            let groupUsers = data.GroupUsers
            let allData = []
            
            for (let index = 0; index < groupUsers.length; index++) {
                const element = groupUsers[index];
                let user = await projectFirestore.collection("users").doc(element).get()
                let userData = user.data();

    
                 if(index === groupUsers.length -1)
                    {
                        
                        dataReady.value = true;
                        isReset.value = true;
                    }
                
                allData.push(userData)
                finalData.value.push(userData)
                          
            }

        
        })

        const ToggleActiveState = (val) =>
        {
            for (let index = 0; index < isActive.value.length; index++) 
            {
               isActive.value[index] = false;      
            }

            isActive.value[val] = true;
            
        }
        const userStateChange = (e, UID) => 
        {
           if(selectedUser.value.UID === UID){
               userState.value = e;
               console.log(userState.value)
           }
            
        }
        const UserSelected = async (e, idx) => 
        {
            
            let sessionMeta = await projectFirestore.collection("SessionData").doc(e.SessionMeta).get()
            let sessionMetaData = sessionMeta.data();
            selectedUserIDX.value = idx;
            userState.value = 0
            for (let index = 0; index < selectedUserSnapshotData.value.length; index++) 
            {
                let unsubscribe = selectedUserSnapshotData.value[index];
                unsubscribe();
                
            }

            selectedUserSnapshotData.value = []
            snapShotDataRaw.value = []
            selectedUser.value = e
            for (let index = 0; index < sessionMetaData.DataLinks.length; index++) {
                const element = sessionMetaData.DataLinks[index];
                snapShotDataRaw.value.push({id: element, data:''})
            }
            for (let index = 0; index < sessionMetaData.DataLinks.length; index++) 
            {
                const element = sessionMetaData.DataLinks[index];
                //
                selectedUserSnapshotData.value.push( 
                    await projectFirestore.collection("SessionData").doc(e.SessionMeta)
                    .collection("data").doc(element).onSnapshot(snap =>
                        {
                           
                            for (let index = 0; index < snapShotDataRaw.value.length; index++) 
                            {
                                const element = snapShotDataRaw.value[index];
                                if(element.id === snap.id)
                                {
                                    snapShotDataRaw.value[index].data = snap.data();
                                    console.log(snapShotDataRaw.value)
                                    RerenderDoms()
                                    break;
                                }
                            }

                            if(index === sessionMetaData.DataLinks.length -1)
                            {
                                if(selectedWeek.value)
                                {
                                    SelectedWeekChange()
                                }
                                else
                                {
                                    
                                }
                                isWeekSelectDisabled.value = false;
                            }
                         
                            //
                        })
                );
                
            }

        
        }

        const RerenderDoms= () => 
        {
            isReset.value = false;
            nextTick(() => 
            {
                isReset.value = true
            })
        }

        const returnSnapshotData = (index) => 
        {
            activeData.value = ""
            activeData.value = snapShotDataRaw.value[index]
        }

        const SelectedWeekChange = () => 
        {
            if(!selectedUser.value)
            {
                
                return
            }
            isReset.value = false;
            
            activeData.value = snapShotDataRaw.value[selectedWeek.value.id]
            
            nextTick(() => 
            {
                isReset.value = true
            })
        }
        return {isActive, 
        ToggleActiveState, 
        dataReady, 
        finalData,
        groupID, 
        UserSelected, 
        activeData, 
        returnSnapshotData,
        taskData,
        selectedWeek,
        SelectedWeekChange,
        isReset,
        selectedUser,
        isWeekSelectDisabled,
        isRefreshed,
        onlineStates,
        selectedUserIDX,
        userStateChange,
        userState
        }
    }
}
</script>

<style>
.container-large-2 .tabBanner
{
    overflow: hidden;
    background-color: #eee;
    border-radius: 20px 20px 0px 0px;
}
.tabBanner button 
{
    background-color: #FFFFFF;
    float: left;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
    color: #2F4858;
    border-radius: 20px 20px 0px 0px;
    border-top: 2px solid #EEEEEE;
    border-left: 2px solid #EEEEEE;
    border-right: 2px solid #EEEEEE;
    border-bottom: none;
    display: flex;
    justify-content: space-around;
    align-items: center;

}

span.material-icons-round.small 
{
    font-size: 13px;
    text-align: center;
    align-content: center;
    align-items: center;
    justify-items: center;
    justify-content: center;
}

.tabBanner button.active {
   background-color: #7BCBBE;
   border-radius: 20px 20px 0px 0px;
   color: white;
}

.tabBanner button:hover {
  background-color: #F26522;
  border-radius: 20px 20px 0px 0px;
  color:white;
}

.container-large-2
{
    width: 80%;
    max-width: 1280px;
    min-height: 900px;
    margin: 10px auto;
    border-radius: 20px;
    box-shadow: 2px 4px 6px rgba(28, 6, 49, 0.1);
    background: #FFFFFF;
}

.container-large-long
{
    width: 19%;
    max-width: 500px;
    min-height: 900px;
    max-height: 1000px;
    overflow-y: auto;
    margin: 10px auto;
    border-radius: 20px;
    box-shadow: 2px 4px 6px rgba(28, 6, 49, 0.1);
    background: #FFFFFF;
}
.container-large-long h3
{
    text-align: left;
    margin: 10px 10px;
}
.big-boi-container
{
    display: flex;
    justify-content: space-around;
    max-width: 1700px;
    margin: 80px auto;
}

.selectGroupForm {
  margin: 20px auto;
}

.big-boi-container.container-large-long.selectGroupForm h3 {
  text-align: left;
  padding: 5px 20px;
}

.big-boi-container.container-large-long.selectGroupForm select {
  width: 90%;
  padding: 15px;
  border-radius: 20px;
  border: 1px solid #eee;
  outline: none;
  color: #2f4858;
  margin: 20px auto;
}

.big-boi-container.container-large-long.selectGroupForm option {
  width: 90%;
  padding: 15px;
  border-radius: 20px;
  border: 1px solid #eee;
  outline: none;
  color: #2f4858;
  margin: 20px auto;
}
</style>