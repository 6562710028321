<template>

<div v-if="dataAvailable" class="userListContainer">
    <div  v-if="groupType === 'admin'">
        <h1>Admins</h1>
    </div>
        <div  v-if="groupType === 'viewers'">
        <h1>Viewers</h1>
    </div>
        <div  v-if="groupType === 'users'">
        <h1>Users</h1>
    </div>
    
     <div v-for="user in userInfoList" :key="user.UID">
         <div class="userContainer">
             <h3>{{user.LastName}}, {{user.FirstName}}</h3>
             <span class="material-icons-round arrow"> chevron_right </span>
         </div>
         <hr>
     </div>
</div>
 
 <div v-else class="userListContainer">
     <div  v-if="groupType === 'admin'">
        <h1>No Admins assigned.</h1>
    </div>
        <div  v-if="groupType === 'viewers'">
        <h1>No Viewers assigned.</h1>
    </div>
        <div  v-if="groupType === 'users'">
        <h1>No Users assigned.</h1>
    </div>
 </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { projectFirestore } from '../../firebase/config'
export default {
    props: ['data'],
    setup(props)
    {   
        
        
        const groupType = ref(props.data.group)
        const dataAvailable = ref(false)
        const userInfoList = ref([])
       
        const checkIfDataExists = () =>{
            if(props.data.ids[0] === ""){
                dataAvailable.value = false
            }
            else{
                dataAvailable.value = true

                const promises = props.data.ids.map((id) => {
                    return projectFirestore.collection(groupType.value).doc(id).get();
                });

                Promise.all(promises).then((data) => {
                    let arrayOfUsers = []

                    for (let index = 0; index < data.length; index++) {
                        const element = data[index].data();
                        arrayOfUsers.push(element)
                    }

                    userInfoList.value = arrayOfUsers
                })
            }
        }

        checkIfDataExists()
        
        return { dataAvailable, groupType, userInfoList}
    }
}
</script>

<style>
.userListContainer {
    text-align: left;
    width: 100%;
}
.userListContainer hr{
    text-align: left;
    width: 95%;
    margin: auto;
}
.userListContainer h1
{
    font-size: 18px;
    font-weight: 100;
    padding: 10px 30px;
}

.userListContainer .userContainer {
    padding: 10px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}

.userListContainer .userContainer h3 
{
    font-size: 24px;
    padding: 10px 0px;
}

.userListContainer .userContainer:hover 
{
    cursor: pointer;
    color: #f26522;
}
</style>