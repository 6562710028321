<template>
  <div class="container selectGroup">
    <h2>Select group to monitor</h2>
    <form @submit.prevent="handleSubmit" class="selectGroupForm">
      <h3>Group</h3>
      <select name="group" id="groupID" v-model="groupSelection">
        <option
          v-for="option in groupData"
          :key="option.groupID"
          :value="option.groupID"
        >
          {{ option.element.groupName }}
        </option>
      </select>
      <button>Monitor Group</button>
    </form>
  </div>
</template>

<script>
import { onBeforeMount } from "@vue/runtime-core";
import { projectFirestore } from "../../../firebase/config";
import {useRouter} from 'vue-router'
import { ref } from "@vue/reactivity";
import store from "../../../store/store";
export default {
  setup(props) {
    const groupData = ref([]);
    const groupSelection = ref("");
    const router = useRouter();

    onBeforeMount(() => {
      if (store.state.user.priv === "admin" || store.state.user.priv === "viewer") {
        const promises = store.state.user.groups.map((id) => {
          return projectFirestore.collection("groups").doc(id).get();
        });

        Promise.all(promises)
          .then((data) => {
            groupData.value = [];
            for (let index = 0; index < data.length; index++) {
              const element = data[index].data();
              const newElement = {
                groupID: store.state.user.groups[index],
                element,
              };
              groupData.value.push(newElement);
            }
          })
          .catch((err) => {
            
          });
      }
    });
     const handleSubmit = () =>
     {
       
       router.push({name:'GroupView', params:
        {groupID: groupSelection.value}
       });
     }
    return { groupData, groupSelection, handleSubmit };
  },
};
</script>

<style>
.selectGroup h2 {
  align-content: left;
  text-align: left;
  font-size: 15px;
  padding: 0px 10px;
}

.selectGroupForm {
  margin: 20px auto;
}

.selectGroupForm h3 {
  text-align: left;
  padding: 5px 20px;
}

.selectGroupForm select {
  width: 90%;
  padding: 15px;
  border-radius: 20px;
  border: 1px solid #eee;
  outline: none;
  color: #2f4858;
  margin: 20px auto;
}

.selectGroupForm option {
  width: 90%;
  padding: 15px;
  border-radius: 20px;
  border: 1px solid #eee;
  outline: none;
  color: #2f4858;
  margin: 20px auto;
}
</style>