<template>
  <Navbar />
  <SideNav :selectedView="{'id': 3}" v-if="priv !== 'viewer'"/>
  <ViewerSideNav :selectedView="{'id': 1}" v-if="priv === 'viewer'" />
  <div class="content">
    <ExportGroupData />
  </div>
  <div>

  </div>
</template>

<script>
import Navbar from "../../components/Navbar.vue";
import SideNav from "../../components/AdminComps/SideNav.vue";
import ExportGroupData from "../../components/AdminComps/ExportGroupData.vue"
import ViewerSideNav from "../../components/AdminComps/ViewerSideNav.vue";
import { ref } from "vue";
import store from "../../store/store";
export default {
components: { Navbar, SideNav, ExportGroupData, ViewerSideNav},

setup()
{
  const priv = ref(store.state.user.priv)

  return {priv}
}
}
</script>

<style>

</style>