<template>
  <div class="tutorialContainer">
    <div class="instructions">
      <h1>Card Sort Task</h1>
      <p>
        In this task, you will be asked to sort each value card into one of three piles, which are
        labelled:
      </p>
      <p>Not Important to me, Important to me, Very Important to me</p>
      <p>
        You will drag and drop each card into the pile that best reflects how
        important that value is to you.
      </p>
    </div>
    <CardSlot @tutorialFinished="showContinueButton"/>
  </div>
</template>

<script>
import Sortable from "sortablejs";
import CardSlot from '../CardSlot.vue'
export default {
    components: {CardSlot},
    setup(props, context)
    {
        const showContinueButton= () => {
            context.emit("showContinueButton")
        }

        return { showContinueButton} 
    }
};
</script>

<style>
.tutorialContainer {
  width: 1400px;
  min-height: 400px;
  margin: 80px auto;
  border-radius: 20px;
  box-shadow: 2px 4px 6px rgba(28, 6, 49, 0.1);
  background: white;
}
.tutorialContainer .instructions li {
  text-decoration: none;
  list-style-type: none;
  padding: 5px 10px;
}

.tutorialContainer .instructions h1 {
  padding: 20px 10px;
}

.tutorialContainer .instructions p {
  font-size: 20px;
  padding: 10px 10px;
  font-weight: 400;
}
</style>