<template>
<div class="manageGroupPanel">
    <h2>Managing Group: {{groupName}}</h2>

    <div>
        <div v-if="emptyGroup">
            <p>Group has no Active Schema</p>
        </div>

        <div class="sideBySidePanels">
            <div class="selections" v-if="redraw">
                <ListItem :text="{'text': 'Viewers'}" @click="showViewers()" :class="{'selectedItem': highlighted[0]}"/>
                <ListItem :text="{'text': 'Users'}" @click="showUsers() "  :class="{'selectedItem': highlighted[1]}"/>
                <ListItem :text="{'text': 'Tasks'}" @click="showTasks()" :class="{'selectedItem': highlighted[2]}"/>
            </div>
            <div class="selectedGroup">
                <div v-if="showViewersVar">
                    <AssignViewers :groupMeta="groupDetails"/>
                </div>
                <div div v-if="showUsersVar">
                    <AssignUser :groupMeta="groupDetails" :parentRoute="parentRoute"/>
                </div>

                <div div v-if="showTasksVar">
                    <AssignTasks :groupMeta="groupMetaDetails" :parentRoute="parentRoute"/>
                </div>
            </div>
        </div>
        
    </div>
</div>
    
</template>

<script>
import { ref } from '@vue/reactivity'
import ListItem from '../../ListItem.vue'
import AssignTasks from '../AssignTasks.vue'
import AssignViewers from '../AssignViewers.vue'
import AssignUser from '../AssignUsers.vue'
import { nextTick } from '@vue/runtime-core'
import {useRoute} from 'vue-router'
export default 
{
    components: {ListItem, AssignTasks, AssignViewers, AssignUser},
    props:['groupDetails', 'metaDetails', 'parentRoute'],
    setup(props)
    {
        const route = useRoute()

        const groupName = ref(props.groupDetails.groupName)
        const groupDetails = ref(props.groupDetails)
        const groupMetaDetails = ref(props.metaDetails)
        const emptyGroup = ref(true)
        const parentRoute = ref(props.parentRoute)

        const showUsersVar = ref(false)
        const showViewersVar = ref(false)
        const showTasksVar = ref(true)

        const highlighted = ref([false, false, true])
        const redraw = ref(false)

       
        if(props.metaDetails){
            if(Object.keys(props.metaDetails).length !== 0)
            {
                emptyGroup.value = false;
            }
        }


        const showUsers = () => 
        {
            showViewersVar.value = false;
            showTasksVar.value = false
            showUsersVar.value = true;
            highlighted.value = [false, true, false]
            toggleReady()
        }

        const showViewers = () => 
        {
            showViewersVar.value = true;
            showTasksVar.value = false
            showUsersVar.value = false;
            highlighted.value = [true, false, false]
            toggleReady()
        }

        const showTasks = () => 
        {
            showViewersVar.value = false;
            showTasksVar.value = true
            showUsersVar.value = false;
            highlighted.value = [false, false, true]
            toggleReady()
        }

        const toggleReady = () => 
        {
            redraw.value = false;
            //
            nextTick(() => {
                redraw.value = true
                //
            })
        }

        toggleReady()
        return {groupName, emptyGroup, groupMetaDetails, groupDetails, parentRoute,showViewersVar,showTasksVar, showUsersVar,redraw, highlighted, showUsers, showViewers, showTasks ,toggleReady}
    }
}
</script>

<style>
.manageGroupPanel h2 
{
    text-align: left;
    padding: 20px 20px;
}

.sideBySidePanels{
    display: flex;
    width: 100%;
}

.selections 
{
    min-width: 30%;
}
.selectedGroup 
{
    background-color: #f7f7f7;
    border-radius: 20px;
    width: 78%;
    height: 900px;
    margin: 10px 10px;

}

.selectedItem
{
    color: #FFA072;
}
</style>