import { ref } from "vue"
import { projectAuth, projectFirestore } from "../firebase/config"
import { useRouter } from "vue-router"
import store from '../store/store'
const error = ref(null)

const logout = async () => 
{
    error.value = null
    try 
    {
        const collection = ref('')

        if (store.state.user.priv === 'admin') {
            collection.value = 'admin'
        }

        if (store.state.user.priv === 'viewer') {
            collection.value = 'viewers'
        }

        if (store.state.user.priv === 'user') {
            collection.value = 'users'
        }


        await projectFirestore.collection(collection.value).doc(projectAuth.currentUser.uid).update({online: false})
        await projectAuth.signOut()
        store.commit("logoutCurrentUser")
        
    }
    catch(err)
    {
        
        error.value = err.message
    }
}

const useLogout = () => 
{
    return {logout, error}
}

export default useLogout