<template>
    <form @submit.prevent="handleSubmit">
        <h3>Email</h3>
        <input type="email" required placeholder="email" v-model="email">
        <h3>Password</h3>
        <input type="password" required placeholder="password" v-model="password">
        <div class="error" v-if="error">{{ error }}</div>
        <button>Login</button>
    </form>
    <a class="clickable" @click="routeToPasswordResetForm()">Forgot password?</a>
</template>

<script>
import { ref } from '@vue/reactivity'
import useLogin from '../composables/useLogin'

export default 
{
    setup(props, context)
    {
        const email = ref("")
        const password = ref("")
        
        
        const {error, login} = useLogin()

        const handleSubmit = async () => 
        {
            await login(email.value, password.value)
            if(!error.value){
                
                context.emit('login')
            }
        }

        const routeToPasswordResetForm = () => 
        {
            context.emit('resetPassword')
        }

        return {email, password, handleSubmit, routeToPasswordResetForm, error}
    }
}
</script>

<style>
.clickable
{
    cursor: pointer;
}
</style>