<template>
    <Navbar />

    <div class="container">
        <EditTaskForm :groupID="groupID" :taskIndex="taskIndex" :groupName="groupName"/>
    </div>
 
</template>

<script>
import Navbar from '../../components/Navbar.vue'
import EditTaskForm from '../../components/AdminComps/GroupForms/EditTaskForm.vue'
import {useRoute} from 'vue-router'
import { ref } from '@vue/reactivity'
export default {
    components: {Navbar, EditTaskForm},
    props: ['groupID', 'taskName'],
    setup(props)
    {
        const route = useRoute();
        
        const groupID = ref(route.params.groupID)
        const taskIndex = ref(route.params.taskIndex)
        const groupName = ref(route.params.taskName)
        return {groupID, taskIndex, groupName}
    }
}
</script>

<style>

</style>