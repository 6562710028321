<template>
<div class="listItemFormat">
    <div class="internalItemFormat">
        <h2>{{textData}}</h2>
     <span class="material-icons-round arrow"> chevron_right </span>
    </div>
    
     <hr>
</div>
    
</template>

<script>
import { ref } from '@vue/reactivity'
export default 
{
    props:['text', 'highlighted'],
    setup(props)
    {
        const textData = ref(props.text.text)
        const highlight = ref(props.highlighted)
        return {textData}
    }
}
</script>

<style>
.listItemFormat .internalItemFormat{
    display: flex;
    justify-content: space-between;
    justify-items: center;
    align-items: center;
}
.listItemFormat hr
{
    max-width: 95%;
    margin: auto;
}

.listItemFormat 
{
    padding: 10px 10px;
    max-width: 100%;
}

.listItemFormat:hover 
{
    cursor: pointer;
    color: #f26522;
}

</style>