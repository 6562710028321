<template>
  <div class="sessionItem" :class="{'notAvailable': state === -1, 'notStarted': state === 0, 'inProgress': state === 1, 'finished': state === 2}">
    <div class="topBlock" >
      <div class="cardDetails" >
        <h2>{{sessionData.TaskName}}</h2>
        <p>
          <span v-if="sessionData.SessionState === -1"> Not Available,</span>  
          <span v-if="sessionData.SessionState === 0"> Not Started, </span>
          <span v-if="sessionData.SessionState === 1"> In Progress, </span> 
          <span v-if="sessionData.SessionState === 2"> Completed, </span>  
          {{sessionData.completedDate ? new Date(sessionData.completedDate.seconds * 1000).toString().split('GMT')[0]: sessionData.SessionStartDate}}
        </p>
      </div>
       
      <span class="material-icons-round chevron">chevron_right</span>
    </div>
    <hr>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
export default {
  props: ['SessionData'],
  setup(props)
  {
      const sessionData = ref(props.SessionData)
      const state = ref(sessionData.value.SessionState)
      //
      return {sessionData, state}
  }
}
</script>

<style>
.sessionItem 
{
  align-items: center;
  padding: 10px 10px;
}

.sessionItem hr
{
  width: 95%;
  margin: auto;
  
}

.notAvailable 
{
  color: #a7a7a7;
}

.finished 
{
  color: #89D1C6
}

.sessionItem .topBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.sessionItem .topBlock .cardDetails{
  text-align: left;
  padding: 10px 10px;
}

.sessionItem .topBlock .cardDetails p{
  text-align: left;
  padding: 0px 20px;
}
.sessionItem :hover 
{
  color: #F26522;
  cursor: pointer;
}

.notAvailable :hover
{
  color: #575757
}

.inProgress 
{
  color: #F26522;
}

</style>