import { firebaseStorage, projectFirestore, timestamp } from "../firebase/config";
import { ref } from "@vue/reactivity"

const error = ref(null)


const generateGroupDataBundle = async (uid, groups) => {
    console.log('generateGroupDataBundle')
    let sessionStates = []
    let docRef = ''

    let groupData = await projectFirestore.collection("groups").doc(groups).get()
    let data = groupData.data();
    console.log(data)
    let groupMetaData = await projectFirestore.collection("GroupMeta").doc(data.groupMetaLocation).get();
    let groupMetaDataData = groupMetaData.data();

    let modulesInfo = groupMetaDataData.TaskMeta

    const CardPacks = ref(['TaskOne',
        'TaskTwo',
        'TaskThree',
        'TaskFour',
        'TaskFive'])
    const TaskNames = ref
    ([
        'Task One: Life Overall',
        'Task Two: Health',
        'Task Three: Having a Purpose',
        'Task Four: Leisure',
        'Task Five: Relationships'
    ])

    for (let index = 0; index < 5; index++) {

        sessionStates.push(generateMetaPacket(CardPacks.value[index], modulesInfo[index], TaskNames.value[index]))
    }


    await projectFirestore.collection('SessionData').add({ SessionStates: sessionStates, DataLinks: ['', '', '', '', '', ''] }).then((docref) => {
        docRef = docref.id
        console.log(docRef)
        let promises = []

        for (let index = 0; index < 5; index++) {
            promises.push(projectFirestore.collection('SessionData')
                .doc(docRef).collection('data').add(generateDataPacket(CardPacks.value[index])))

        }


        Promise.all(promises).then((data) => {
            
            let dataLinks = []
            for (let index = 0; index < data.length; index++) {
                
                dataLinks.push(data[index].id)
            }


            projectFirestore.collection('SessionData').doc(docRef).update(
                {
                    DataLinks: dataLinks
                }
            )

            if(uid)
            {
                projectFirestore.collection('users').doc(uid).update({
                    SessionMeta: docRef
                })
            }
            else
            {
                console.log('no uid')
            }

        })
    })


}

const generateMetaPacket = (cardPack, moduleInfo, taskName) => {
    return {
        CardPack: cardPack,
        SessionModules: {
            CardSort: moduleInfo.Modules[0],
            CardFilter:  moduleInfo.Modules[1],
            CardFinal:  moduleInfo.Modules[2],
            WorkSheet:  moduleInfo.Modules[3],
        },
        SessionStartDate: moduleInfo.StartDate,
        SessionState: -1,
        TaskName: taskName
    }
}

const generateDataPacket = (taskName) => {
    return {
        saveState: 0,
        nimp: [],
        imp: [],
        vimp: [],
        Top5: [],
        finalCard: { cardValue: '', cardIndex: -1 },
        surveyResponses: {
            goalActionOne: '',
            goalActionTwo: '',
            goalActionThree: '',
            barriers: '',
            resolution: ''
        },
        cardPack: taskName
    }
}

const generateUserDataBundle = () => {
    return { generateGroupDataBundle, error }
}

export default generateUserDataBundle