<template>
  <div v-show="!showPile">
    <div class="draggableCard">
      <div v-if="!tutorialComplete">
        <h3>Your Card</h3>
        <draggable :class="displayCard" :list="currentCard" group="values" @change="log" itemKey="index"
          class="cardSlot" id="mainSlot">
          <template #item="{}">
            <div class="list-group-item">
              <Cards v-if="cardVisible" :imgURL="currentCard" :small="true" :hoverDisabled="false" />
            </div>
          </template>
        </draggable>
      </div>
      <div v-else class="draggableCard">
        <button @click="emitTaskTwo">Continue Task</button>
      </div>
    </div>

    <div class="cardHeaders">
      <button @click="expandPile(2)">
        <span class="larger-font">Very Important to me</span><span class="material-icons-round"> chevron_right</span>
      </button>
      <button @click="expandPile(1)">
        <span class="larger-font">Important to me</span><span class="material-icons-round"> chevron_right</span>
      </button>
      <button @click="expandPile(0)">
        <span class="larger-font">Not Important to me</span><span class="material-icons-round"> chevron_right</span>
      </button>
    </div>

    <div class="cardRow">
      <div class="col-3">
        <draggable :class="displayCard" :list="veryImportantPile" :sort="false" :move="itemMoved" :onAdd="itemAdded"
          :emptyInsertThreshold="20" ghost-class="ghost" group="values" itemKey="index" id="vimp">
          <template #item="{ element }">
            <div class="list-group-item">
              <div class="piles">
                <Cards v-if="cardVisible" :imgURL="element.cardURL" :small="true" :shifted="true"
                  :hoverDisabled="true" />
              </div>
            </div>
          </template>
        </draggable>
      </div>

      <div class="col-3">
        <draggable :class="displayCard" :list="importantPile" :sort="false" :move="itemMoved" ghost-class="ghost"
          :onAdd="itemAdded" :emptyInsertThreshold="20" itemKey="index" group="values" id="imp">
          <template #item="{ element }">
            <div class="list-group-item">
              <div class="piles">
                <Cards v-if="cardVisible" :imgURL="element.cardURL" :small="true" :shifted="true"
                  :hoverDisabled="true" />
              </div>
            </div>
          </template>
        </draggable>
      </div>
      <div class="col-3">
        <draggable :class="displayCard" :list="notImportantPile" :sort="false" :onAdd="itemAdded" :move="itemMoved"
          :emptyInsertThreshold="20" ghost-class="ghost" group="values" itemKey="index" id="nimp">
          <template #item="{ element }">
            <div class="list-group-item">
              <div class="piles">
                <Cards v-if="cardVisible" :imgURL="element.cardURL" :small="true" :shifted="true"
                  :hoverDisabled="true" />
              </div>
            </div>
          </template>
        </draggable>
      </div>
    </div>
  </div>
  <div v-if="showPile" class="pileView">
    <TaskOnePileView :cardsToDisplay="currentViewPile" :interactable="false" />
    <button style="margin:10px 10px" @click="expandPile(-1)">Return</button>
  </div>

  <div v-if="canUndo">
    <button @click="UndoMove()">Undo</button>
  </div>
  <div class="invisible">
    <img :src="nextImg" alt="" />
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { nextTick, onBeforeMount } from "@vue/runtime-core";

import { projectFirestore } from "../../../../firebase/config";
import store from "../../../../store/store";

import TaskOnePileView from "../TaskOne/TaskOnePileView.vue";
import Cards from "../../TaskComps/Cards.vue";
import draggable from "vuedraggable";
import { watch } from "vue";

export default {
  components: { draggable, Cards, TaskOnePileView },
  props: ['viewMode'],
  emits: ["taskOneFinished"],
  setup(props, context) {

    const viewMode = ref(props.viewMode)
    const testData = ref([]);
    const nextImg = ref("");

    const notImportantPile = ref([]);
    const importantPile = ref([]);
    const veryImportantPile = ref([]);
    const currentViewPile = ref([]);

    const showPile = ref(false);
    const currentCard = ref([
      { cardValue: "to hold nine", index: 10, cardURL: "" },
    ]);

    const displayCard = ref("");
    const tutorialComplete = ref(false);
    const cardVisible = ref(false);

    const canUndo = ref(false);

    const previousCard = ref();

    onBeforeMount(async () => {
      projectFirestore
        .collection("CardTasks")
        .doc(store.state.sessionMeta.currentCardPack)
        .get()
        .then((res) => {
          let data = res.data();

          testData.value = data[data.name].reverse();

          let max = -1;
          let cardPiles = store.state.pileData;
          if (cardPiles.notImportantPile) {
            for (
              let index = 0;
              index < cardPiles.notImportantPile.length;
              index++
            ) {
              if (cardPiles.notImportantPile[index].index > max) {
                max = cardPiles.notImportantPile[index].index;
              }
            }
          }
          else {
            store.state.pileData.notImportantPile = []
          }

          if (cardPiles.importantPile) {
            for (
              let index = 0;
              index < cardPiles.importantPile.length;
              index++
            ) {
              if (cardPiles.importantPile[index].index > max) {
                max = cardPiles.importantPile[index].index;
              }
            }
          }
          else {
            store.state.pileData.importantPile = []
          }

          if (cardPiles.veryImportantPile) {
            for (
              let index = 0;
              index < cardPiles.veryImportantPile.length;
              index++
            ) {
              if (cardPiles.veryImportantPile[index].index > max) {
                max = cardPiles.veryImportantPile[index].index;
              }
            }
          }
          else {
            store.state.pileData.veryImportantPile = []
          }

          veryImportantPile.value = cardPiles.veryImportantPile;
          importantPile.value = cardPiles.importantPile;
          notImportantPile.value = cardPiles.notImportantPile;
          if (max === -1) {
            currentCard.value = [testData.value.pop()];
            testData.value.splice(0, -1);
          } else {
            projectFirestore
              .collection("SessionData")
              .doc(store.state.sessionMeta.sessionMetaDataLocation)
              .collection("data")
              .doc(store.state.sessionMeta.activeDataFile)
              .update({
                saveState: 1,
              });
            for (let index = 0; index < max + 1; index++) {
              testData.value.pop();
            }
            if (testData.value.length > 0) {
              currentCard.value = [testData.value.pop()];
              testData.value.splice(0, -1);
            }
            else {
              currentCard.value = null
              tutorialComplete.value = true;
            }
          }

          nextTick(() => {
            cardVisible.value = true;
          });
        });
    });

    const log = (evt) => {
      const img = new Image();
      if (evt.removed) {
        if (testData.value.length !== 0) {
          cardVisible.value = false;
          currentCard.value = [testData.value.pop()];

          if (testData.value.length > 0) {
            nextImg.value = testData.value[testData.value.length - 1].cardURL;
          }

          nextTick(() => {
            cardVisible.value = true;
          });
        } else {
          currentCard.value = null;
          tutorialComplete.value = true;
        }
      }
    };

    const emitTaskTwo = () => {
      store.commit("storePileData", {
        nimp: notImportantPile.value,
        vimp: veryImportantPile.value,
        imp: importantPile.value,
      });
      context.emit("taskOneFinished");
    };

    const itemMoved = (evt, origEvt) => {
      if (evt.to.id === "mainSlot" || evt.from.id === "vimp" || evt.from.id === "imp" || evt.from.id === "nimp") {
        return false;
      }
    };

    const itemAdded = (evt, origEvt) => {
      reorderArray(notImportantPile);
      reorderArray(importantPile);
      reorderArray(veryImportantPile);

      store.commit("storePileData", {
        nimp: notImportantPile.value,
        vimp: veryImportantPile.value,
        imp: importantPile.value,
      });

      
      if(evt.to.id === "nimp")
      {
        previousCard.value = {pile:0, card: notImportantPile.value[notImportantPile.value.length - 1]};
        canUndo.value = true;
      }

      if(evt.to.id === "imp")
      {
        previousCard.value = {pile:1, card:importantPile.value[importantPile.value.length - 1]};
        canUndo.value = true;
      }

      if(evt.to.id === "vimp")
      {
        previousCard.value = {pile:2, card: veryImportantPile.value[veryImportantPile.value.length - 1]};
        canUndo.value = true;
      }

      projectFirestore
        .collection("SessionData")
        .doc(store.state.sessionMeta.sessionMetaDataLocation)
        .collection("data")
        .doc(store.state.sessionMeta.activeDataFile)
        .update({
          imp: importantPile.value,
          vimp: veryImportantPile.value,
          nimp: notImportantPile.value,
          saveState: 1,
        });

      projectFirestore
        .collection("SessionData")
        .doc(store.state.sessionMeta.sessionMetaDataLocation)
        .get()
        .then((res) => {
          let data = res.data().SessionStates;
          data[store.state.sessionMeta.currentSessionIndex].SessionState = 1;
          projectFirestore
            .collection("SessionData")
            .doc(store.state.sessionMeta.sessionMetaDataLocation)
            .update({
              SessionStates: data,
            });
        });
    };

    const expandPile = (val) => {
      if (val === -1) {
        showPile.value = false;
      } else {
        if (val === 0) {
          currentViewPile.value = notImportantPile.value;
        } else if (val === 1) {
          currentViewPile.value = importantPile.value;
        } else if (val === 2) {
          currentViewPile.value = veryImportantPile.value;
        } else {
          currentViewPile.value = [];
          return;
        }
        nextTick(() => {
          showPile.value = true;
        });
      }
    };

    const reorderArray = (arr) => {
      for (let i = 1; i < arr.value.length; i++) {
        for (let j = i - 1; j > -1; j--) {
          if (arr.value[j + 1].index < arr.value[j].index) {
            [arr.value[j + 1], arr.value[j]] = [arr.value[j], arr.value[j + 1]];
          }
        }
      }
    };


    const UndoMove = () => {
      testData.value.push(currentCard.value[0]);
      currentCard.value = [previousCard.value.card];

      console.log(previousCard.value.card);
      if (previousCard.value.pile === 0) {
        notImportantPile.value.pop();
      }
      else if (previousCard.value.pile === 1) {
        importantPile.value.pop();
      }
      else if (previousCard.value.pile === 2) {
        veryImportantPile.value.pop();
      }
      canUndo.value = false;

      projectFirestore
        .collection("SessionData")
        .doc(store.state.sessionMeta.sessionMetaDataLocation)
        .collection("data")
        .doc(store.state.sessionMeta.activeDataFile)
        .update({
          imp: importantPile.value,
          vimp: veryImportantPile.value,
          nimp: notImportantPile.value,
          saveState: 1,
        });

      projectFirestore
        .collection("SessionData")
        .doc(store.state.sessionMeta.sessionMetaDataLocation)
        .get()
        .then((res) => {
          let data = res.data().SessionStates;
          data[store.state.sessionMeta.currentSessionIndex].SessionState = 1;
          projectFirestore
            .collection("SessionData")
            .doc(store.state.sessionMeta.sessionMetaDataLocation)
            .update({
              SessionStates: data,
            });
        });
    }

    return {
      testData,
      notImportantPile,
      importantPile,
      veryImportantPile,
      log,
      currentCard,
      displayCard,
      tutorialComplete,
      itemMoved,
      emitTaskTwo,
      itemAdded,
      cardVisible,
      nextImg,
      expandPile,
      showPile,
      currentViewPile,
      viewMode,
      canUndo,
      UndoMove
    };
  },
};
</script>

<style>
.cardRow {
  display: flex;
  justify-content: space-around;
  margin: 20px -70px;
  padding: 100px 10px;
  min-width: 840px;
}

.list-group-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 300px;
}

.list-group-item .piles {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 280px;
  margin: -98px 20px;
}

.col-3 {
  width: 360px;
  padding: 20px 10px;
  margin: 5px 10px;
  min-height: 200px;
  max-height: 600px;
  background-color: #e7e7e7;
  border-radius: 20px;
}

.col-3::before {
  margin: 3rem;
  content: "Drag Here";
  color: rgb(0, 0, 0);
  top: 50%;
  left: 50%;
  z-index: -1;
}

.invisible {
  visibility: hidden;
  display: none;
}

.cardHeaders {
  display: flex;
  justify-content: space-between;
  padding: 0px 0px;
  margin: -40px 0px;
}

.cardHeaders button {
  width: 250px;
  background: white;
  color: #2f4858;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  transform: translate(0, 90%);
}

.ghost {
  margin: -98px 20px;
}

.cardSlot {
  min-height: 244px;
}

.pileView {
  padding: 10px 20px;
}

.larger-font {
  font-size: 16px;
}
</style>