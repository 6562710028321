<template>
  <div class="taskOneContainer">

    <div class="instructionsContainer">
      <div class="instructionsPane">
        <div class="textContainer">
          <h2>Instructions</h2>
          <p>
           You will need to drag and drop the card into the box which best describes how important this value is to you.
          </p>
          <p>
            Click on the undo button to change your choice
          </p>
        </div>
      </div>
    </div>

    <div class="Taskstuff">
        <TaskOneCardSort @taskOneFinished="moveToTaskTwo" :viewMode="viewMode"/>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import CardSlot from '../CardSlot.vue'
import TaskOneCardSort from '../TaskOne/TaskOneCardSort.vue'
export default {
    components: {TaskOneCardSort},
    props: ['viewMode'],
    emits:['moveToTaskTwo'],
    setup(props, context)
    {
      const viewMode = ref(props.viewMode)
       const moveToTaskTwo = () => {
    
        context.emit('moveToTaskTwo')
       }

       return {moveToTaskTwo, viewMode}
    }

};
</script>

<style>
.taskOneContainer {
  width: 1500px;
  height: 760px;
  margin: 80px auto;
  border-radius: 20px;
  box-shadow: 2px 4px 6px rgba(28, 6, 49, 0.1);
  background: #efefef;
  display: flex;
  justify-items: center
}

.taskOneContainer .TaskStuff{
    display: flex;
    
}

.taskOneContainer .instructionsContainer {
  display: block;
  flex-direction: column;
  width: 400px;
}
.taskOneContainer .instructionsContainer .instructionsPane {
  width: 300px;
  margin: 10px 20px;
  height: 600px;
  background-color: white;
  border-radius: 20px;
}

.taskOneContainer .instructionsContainer .buttonHolder {
  width: 20%;
  margin: 10px 20px;
  display: flex;
}
.taskOneContainer .instructionsContainer .instructionsPane .textContainer {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-items: flex-start;
  padding: 10px 10px;
}

.taskOneContainer .instructionsContainer .instructionsPane .textContainer h2 {
  padding: 10px 10px;
  color: #364757;
  margin-right: auto;
}

.taskOneContainer .instructionsContainer .instructionsPane .textContainer p {
  padding: 10px 10px;
  color: #364757;
  margin-right: auto;
  text-align: left;
}
</style>