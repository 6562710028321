<template>
  <div class="groupDetails">
      <div class="groupHeader">
          <h2>
      {{ data.groupName }} 
    </h2>
    <span class="material-icons-round clickable" @click="editGroup"> edit </span>
      </div>
    
    <div class="contents">
      <div class="subContent">
        <h3>Date Created:</h3>
        <p>{{ data.createdAt.toDate() }}</p>
      </div>
      <hr />
      <div class="subContent">
        <h3>Notes:</h3>
        <p>{{ data.notes }}</p>
      </div>
      <hr />

      <div class="subContent">
        <div class="itemContainer" @click="ManageTasks()">
          <h3>Manage Tasks</h3>
          <span class="material-icons-round arrow"> chevron_right </span>
        </div>
      </div>
      <hr />
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useRouter } from "vue-router";

export default {
  props: ["data"],
  setup(props, context) {
    const data = ref(props.data.element);
    const router = useRouter()
    const viewGroups = (group) => {
      context.emit("loadUserDetails", group);
    };
    const editGroup = () => 
    {
        
        router.push({name: 'EditGroup', params:{groupID: props.data.groupID}})
    }
    
    const ManageTasks = () => 
    {
        router.push({name: 'ManageTasks', params:{groupID: props.data.groupID}})
    }
    return { data, viewGroups, editGroup, ManageTasks };
  },
};
</script>

<style>
.groupDetails {
  justify-items: left;
  align-items: left;
  text-align: left;
}

.clickable 
{
    cursor: pointer;
}

.groupDetails .groupHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
    padding: 10px 10px;
}

.groupDetails .contents {
  padding: 10px 20px;
}

.groupDetails .contents .subContent {
  padding: 10px 10px;
}
.groupDetails .contents .subContent .itemContainer {
  padding: 10px 0px;
  display: flex;
  justify-content: space-between;
}

.groupDetails .contents .subContent .itemContainer:hover {
  cursor: pointer;
  color: #f26522;
}
</style>