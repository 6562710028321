<template>
  <form @submit.prevent="handleSubmit" class="signup" v-if="showErr === false">
    <h3>Email</h3>
    <input type="email" required placeholder="email" v-model="email" />
    <h3>Password</h3>
    <input type="password" required placeholder="password" v-model="password" />
    <h3>Name</h3>
    <input type="text" required placeholder="First Name" v-model="name" />
    <h3>Last Name</h3>
    <input type="text" required placeholder="Last Name" v-model="lastName" />
    <h3>User Role</h3>
    <select name="role" id="id" v-model="selected">
      <option v-for="option in options" :key="option.id">
        {{ option.role }}
      </option>
    </select>
    <h3>User Group:</h3>
     <select required name="group" id="groupID" v-model="groupSelection">
      <option v-for="option in groupData" :key="option.groupID" :value="option.groupID">
         {{option.element.groupName}}
      </option>
    </select>

    <div class="error" v-if="error">{{ error }}</div>
    <button>Add User</button>
  </form>

  <div v-if="showErr === true">
    <h2>Please make a group before creating users</h2>
  </div>

</template>

<script>
import { ref } from "@vue/reactivity";
import useSignup from "../composables/useSignup";
import { onBeforeMount } from "@vue/runtime-core";
import { projectFirestore } from "../firebase/config";
import store from "../store/store";

export default {
  emits:["signup"],
  setup(props, context) {
    const email = ref("");
    const password = ref("");
    const name = ref("");
    const lastName = ref("");
    const showErr= ref(false)
    const selected = ref("User");
    const groupSelection = ref('')
    const groupData = ref([]);
    const options = ref([
      { role: "User", id: 1 },
      { role: "Viewer", id: 2 },
      {role: "Admin", id: 3}
    ]);

    const { error, signup } = useSignup();

    onBeforeMount(() => {
      if (store.state.user.priv === "admin") {
        console.log(store.state.user.groups)
        if(store.state.user.groups == undefined)
        {
          showErr.value = true;
          return;
        }

        const promises = store.state.user.groups.map((id) => {
          return projectFirestore.collection("groups").doc(id).get();
        });

        Promise.all(promises)
          .then((data) => {
            groupData.value = [];
            for (let index = 0; index < data.length; index++) {
              const element = data[index].data();

              const newElement = {
                groupID: store.state.user.groups[index],
                element,
              };
              groupData.value.push(newElement);
            }
          })
          .catch((err) => {
            
          });
      }
    });

    const handleSubmit = async () => {
        let groupID = [""]

        if(groupSelection.value !== ''){
            groupID = [groupSelection.value]
        }
        else
        {
           error.value="User must be assigned to group"
          return;
         
        }
        
        
      await signup(
        email.value,
        password.value,
        name.value,
        lastName.value,
        selected.value,
        store.state.user.key,
        groupID,
        store.state.user.orgUID
      );
      if (!error.value) {
        
        context.emit("signup");
      }
    };

    return {
      email,
      password,
      handleSubmit,
      error,
      selected,
      options,
      name,
      lastName,
      groupSelection,
      groupData,
      showErr
    };
  },
};
</script>

<style>
.signup {
  width: 100%;
  padding: 10px;
  outline: none;
  color: #2f4858;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
}

.signup input {
  width: 90%;
  padding: 15px;
  border-radius: 20px;
  border: 1px solid #eee;
  outline: none;
  color: #999;
  margin: 20px auto;
}

.signup button {
  margin: 20px auto;
}

.signup h3 {
  text-align: left;
  padding: 5px 20px;
}

.signup .titleHeading {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px;
}

.signup select {
  width: 90%;
  padding: 15px;
  border-radius: 20px;
  border: 1px solid #eee;
  outline: none;
  color: #2f4858;
  margin: 20px auto;
}

.signup option {
  width: 90%;
  padding: 15px;
  border-radius: 20px;
  border: 1px solid #eee;
  outline: none;
  color: #2f4858;
  margin: 20px auto;
}
</style>