<template>
<div class="finalTaskContainer">
    <div class="taskHeader">
            <h2>Your Final Card</h2>
    </div>
    <div class="taskBody">
        <Cards :imgURL="finalCard.cardURL" />
    </div>
    <div class="taskFooter">
        <button @click="moveToWorkSheet()">Continue</button>
    </div>
</div>
  
</template>

<script>
import { ref } from '@vue/reactivity'
import Cards from '../Cards.vue'
import store from '../../../../store/store'
import {projectFirestore} from '../../../../firebase/config'
import {useRouter} from "vue-router";
export default 
{
    components: {Cards},
    props: ['viewMode'],
    setup(props, context)
    {
        const router = useRouter()
        const finalCard = ref('');
        finalCard.value = store.state.lastChoice.finalCard
        if(props.viewMode === false){
         projectFirestore.collection('SessionData')
            .doc(store.state.sessionMeta.sessionMetaDataLocation)
            .collection('data').doc(store.state.sessionMeta.activeDataFile).update({
                saveState: 4
            })
        }
        
        const moveToWorkSheet = () => 
        {
              router.push({name: 'WorkSheet', params: {routeValid: true, viewMode: props.viewMode}})
        }
        
        return {finalCard, moveToWorkSheet}
    }
}
</script>

<style>
.finalTaskContainer 
{
    width: 90%;
    max-width: 980px;
    min-height: 400px;
    margin: 80px auto;
    border-radius: 20px;
    box-shadow: 2px 4px 6px rgba(28, 6, 49, 0.1);
    background: white;
}

.finalTaskContainer .taskHeader 
{
    padding: 30px 30px;
}

.finalTaskContainer .taskBody 
{
    display: flex;
    justify-content: space-around;
    padding: 30px 30px;
}

.finalTaskContainer .taskFooter
{
    padding: 20px 20px;
}
</style>