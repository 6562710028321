const  convertTimestamp = (timestamp) => 
{
    if(isNaN(timestamp))
    {
        return "No date set"
    }
    var d = new Date(timestamp),	// Convert the passed timestamp to milliseconds
        yyyy = d.getFullYear(),
        mm = ('0' + (d.getMonth() + 1)).slice(-2),	// Months are zero based. Add leading 0.
        dd = ('0' + d.getDate()).slice(-2),			// Add leading 0.
        hh = d.getHours(),
        h = hh,
        min = ('0' + d.getMinutes()).slice(-2),		// Add leading 0.
        ampm = 'AM',
        time,
    day = d.getDay();

          
    if (hh > 12) {
        h = hh - 12;
        ampm = 'PM';
    } else if (hh === 12) {
        h = 12;
        ampm = 'PM';
  }   else if (hh == 0) {
      h = 12;
  }

  if(day==0){ day="Sunday"; }
if(day==1){ day="Monday"; }
if(day==2){ day="Tuesday"; }
if(day==3){ day="Wednesday"; }
if(day==4){ day="Thursday"; }
if(day==5){ day="Friday"; }
if(day==6){ day="Saturday"; }
  
  // ie: 2013-02-18, 8:35 AM	
  time = day + ", " + dd + '-' + mm + '-' + yyyy + ', ' + h + ':' + min + ' ' + ampm;
      
  return time;
}

export default convertTimestamp