<template>
<div class="centered">
    <Cards :imgURL="finalCard.cardURL" />
</div>
<hr>
  <div class="taskThreeCards">

        <div
          class="fiveCardView"
          v-for="(card, index) in topFiveData"
          :key="card.index">
            <div>
                <Cards
                    :imgURL="card.cardURL"
                    :class="{fiveCardView: true, selected: selectedCard[index]}"
                    :small="true" />
            </div>
        </div>
    </div>
</template>

<script>
import Cards from "../../ClientComps/TaskComps/Cards.vue"
import { onBeforeMount, ref } from '@vue/runtime-core';
export default 
{
    components: {Cards},
    props: ['userData'],
    setup(props)
    {
        const topFiveData = ref(props.userData.data.Top5)
        const finalCard = ref(props.userData.data.finalCard)
        const selectedCard = ref([false, false, false, false, false]);

        const assignCards = () => 
        {
            selectedCard.value = ref([false, false, false, false, false])
            
            if(finalCard.value)
            {
                for (let index = 0; index < topFiveData.value.length; index++) 
                {
                     
                    if(topFiveData.value[index].index === finalCard.value.index)
                    {
                        selectedCard.value[index] = true
                        break;
                    }
                   
                    
                }
            }
        }

        onBeforeMount(() => 
        {
            assignCards()
        })

        
        return {
            topFiveData,
            finalCard,
            selectedCard
        }
    }
}
</script>

<style>
.taskThreeCards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.taskThreeCards .fiveCardView {
  padding: 20px 20px;
}
.taskThreeCards .fiveCardView .selected {
  border: 5px solid #7ccbbf;
}

.centered
{
    margin: auto auto;
    padding: 20px 33%;
    align-content: center;
    width: 100%;
}
</style>