<template>
  <Navbar />
  <div v-if="dataReady">
    <div class="container-large">

  
    <div class="userAssignmentContainer">
      <div class="unassignedviewers">
        <h2>Viewers Not in Group</h2>
        <div class="columnOne">
          <draggable
            ghost-class="list-group"
            :list="unassignedviewers"
            itemKey="name"
            group="people"
            :onAdd="resetDataReadyflag"
          >
                <template #header>
              <div v-if="unassignedviewers.length === 0">
                <UserDraggableItem :placeholderText="'Drag here to unassign'" />
              </div>
            </template>
            <template #item="{ element }">
              <div class="list-group-item">
                <UserDraggableItem :userInfo="element" />
              </div>
            </template>
          </draggable>
        </div>
      </div>
      <div class="vl">

      </div>
      <div class="assignedviewers">
        <h2>Viewers in Group</h2>
        <div class="columnOne">
          <draggable
            ghost-class="list-group"
            :list="viewersAssignedToTargetGroup"
            group="people"
            itemKey="name"
          >
            <template #header>
              <div v-if="viewersAssignedToTargetGroup.length === 0">
                <UserDraggableItem :placeholderText="'Drag here'" />
              </div>
            </template>

            <template #item="{ element }">
              <div class="list-group-item">
                <UserDraggableItem :userInfo="element" />
              </div>
            </template>
          </draggable>
        </div>
      </div>

  
    </div>
        <div class="buttonFooter">
          <button @click='Cancel'>Cancel</button> <button @click='ConfirmViewer'>Confirm</button>
      </div>
  </div>
    </div>
</template>

<script>
import Navbar from "../../components/Navbar.vue";
import { useRouter, useRoute } from "vue-router";
import { projectFirestore } from "../../firebase/config";
import { ref } from "@vue/reactivity";
import {nextTick, onBeforeMount } from "@vue/runtime-core";
import store from "../../store/store";
import draggable from "vuedraggable";
import UserDraggableItem from "../../components/AdminComps/UserDraggableItem.vue";
export default {
  components: { Navbar, draggable, UserDraggableItem },
  props: ["groupID", "currentViewers"],
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const unassignedviewers = ref([]);
    const viewersAssignedToTargetGroup = ref([]);
    
    const adminsList = ref([])
    const viewersList = ref([])
    const dataReady = ref(false);


    const currentAdmin = ref(null);
    onBeforeMount(async () => {

      let result = await projectFirestore
        .collection("orgs")
        .doc(store.state.user.orgUID)
        .get();
      let data = result.data();
      
      const promises = data.Viewers.map((id) => {
        return projectFirestore.collection("viewers").doc(id).get();
      });

      const promisesAdmin = data.Admins.map((id) => {
        return projectFirestore.collection("admin").doc(id).get();
      });

      await Promise.all(promises).then((res) => {
        for (let index = 0; index < res.length; index++) 
        {
          const element = res[index].data();

          if(!element || !element.groups ) continue;
        
          if (element.groups.includes(route.params.groupID)) {
            
            viewersAssignedToTargetGroup.value.push(element);
          } else if (element.groups.length > 0 && element.groups[0] !== "") 
          {
            unassignedviewers.value.push(element);
          } else {
            
            unassignedviewers.value.push(element);
          }

          viewersList.value.push(element);
        }
       
      });

      const adminRes = await Promise.all(promisesAdmin);
        for (let index = 0; index < adminRes.length; index++) 
        {
          const element = adminRes[index].data();
          if(!element || !element.groups ) continue;
          if(element.UID === store.state.user.key) {
            currentAdmin.value = element;
            continue
            };
          if (element.groups.includes(route.params.groupID)) {
            
            viewersAssignedToTargetGroup.value.push(element);
          } else if (element.groups.length > 0 && element.groups[0] !== "") 
          {
            unassignedviewers.value.push(element);
          } else {
            
            unassignedviewers.value.push(element);
          }
          adminsList.value.push(element)
        }

        

         dataReady.value = true;
    });

    const ConfirmViewer = () => 
    {
        
        let viewers = []
        let admins = []

        for (let index = 0; index < viewersAssignedToTargetGroup.value.length; index++) {

          if( !viewersAssignedToTargetGroup.value[index].groups.includes(route.params.groupID) && viewersList.value.includes(viewersAssignedToTargetGroup.value[index]))
          {
                viewersAssignedToTargetGroup.value[index].groups.push(route.params.groupID)
                projectFirestore.collection('viewers').doc(viewersAssignedToTargetGroup.value[index].UID)
                .update(viewersAssignedToTargetGroup.value[index])
          }

          if(!viewersAssignedToTargetGroup.value[index].groups.includes(route.params.groupID) && adminsList.value.includes(viewersAssignedToTargetGroup.value[index]))
          {
                viewersAssignedToTargetGroup.value[index].groups.push(route.params.groupID)
                projectFirestore.collection('admin').doc(viewersAssignedToTargetGroup.value[index].UID)
                .update(viewersAssignedToTargetGroup.value[index])
          }

          if(viewersList.value.includes(viewersAssignedToTargetGroup.value[index])){
            viewers.push(viewersAssignedToTargetGroup.value[index].UID)
          }
          if(adminsList.value.includes(viewersAssignedToTargetGroup.value[index])){
            admins.push(viewersAssignedToTargetGroup.value[index].UID)
          }
        }

        for (let index = 0; index < unassignedviewers.value.length; index++) {
          var idx = unassignedviewers.value[index].groups.indexOf(route.params.groupID)
          if(idx > -1 && viewersList.value.includes(unassignedviewers.value[index]))
          {
              unassignedviewers.value[index].groups.splice(idx, 1);
              projectFirestore.collection('viewers').doc(unassignedviewers.value[index].UID)
              .update(unassignedviewers.value[index])
          }
          
          if(idx > -1 && adminsList.value.includes(unassignedviewers.value[index]))
          {
              unassignedviewers.value[index].groups.splice(idx, 1);
              projectFirestore.collection('admin').doc(unassignedviewers.value[index].UID)
              .update(unassignedviewers.value[index])
          }
        }
        
        
        projectFirestore.collection('groups').doc(route.params.groupID)
          .update({GroupViewers: viewers})

        console.log(admins)

        admins.push(currentAdmin.value.UID)
        console.log(admins)
        projectFirestore.collection('groups').doc(route.params.groupID).update({GroupAdmins: admins})

        router.push(({name:'ManageTasks', groupID: route.params.groupID}))
    }
    const Cancel = () => 
    {
         router.push(({name:'ManageTasks', groupID: route.params.groupID}))
    }

    const resetDataReadyflag = () =>
    {
      dataReady.value = false;

      nextTick(() => {
        dataReady.value = true
      })
    }

    return {
      unassignedviewers,
      viewersAssignedToTargetGroup,
      dataReady,
      resetDataReadyflag,
      ConfirmViewer,
      Cancel
    };
  },
};
</script>

<style>
.userAssignmentContainer {
  display: flex;
  justify-content: space-evenly;
  min-height: 900px;
}

.list-group {
  background: #e7e7e7;
}
.unassignedviewers {

  width: 50%;
}
.assignedviewers {

  width: 50%;
}
.columnOne
{
  padding: 30px 20px;
}
.vl {
  border-left: 6px solid #F26522;
  border-radius: 20px;
  height: 800px;
  left: 50%;
  margin-left: -3px;
  top: 0;
  margin-top: 10px;
}
.buttonFooter
{
  margin: 20px 20px;
}
</style>