import firebase from "firebase/app";
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/functions'
import 'firebase/storage'
import 'firebase/database'
const firebaseConfig = {
  apiKey: "AIzaSyDgRpEyWIrGWm-NUUQ70gwsg8M358QpQDE",
  authDomain: "udemy-vue-firebase-site-da799.firebaseapp.com",
  databaseURL: "https://udemy-vue-firebase-site-da799-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "udemy-vue-firebase-site-da799",
  storageBucket: "udemy-vue-firebase-site-da799.appspot.com",
  messagingSenderId: "519939373244",
  appId: "1:519939373244:web:d6d4fc62c084a6565af8b9",
  measurementId: "G-9V0EXVL8EP"
  };

  //init firebase

  firebase.initializeApp(firebaseConfig)

  //initialize firestore
  const projectAuth = firebase.auth();
  const projectFirestore = firebase.firestore();
  const firebaseFuncs = firebase.functions();
  const firebaseStorage = firebase.storage();
  const firebaseDatabase = firebase.database();


  const timestamp = firebase.firestore.FieldValue.serverTimestamp
  
  export { projectAuth, projectFirestore, firebaseFuncs, firebaseStorage, firebaseDatabase, timestamp}