<template>
  <div class="userColumns" v-if="m_userdataReady">
    <div v-if="dataAvailable" class="UserData">
    <div class=" listContainerDrag assigned">
        <div class="headerItem">
             <h3>Assigned Users</h3>
               <span
          class="material-icons-round add"
          @click="assignUsers"
          >edit</span
        >
        </div>
        <hr>
        <div v-for="(users, index) in userData" :key="index">
              <UserListItem :FirstName="users.FirstName" :LastName="users.LastName" />
        </div>
    </div>
    </div>
    <div v-else class="listContainerDrag">
       <div class="headerItem">
             <h3>No Users Assigned</h3>
               <span
          class="material-icons-round add"
          @click="assignUsers"
          >edit</span
        >
        </div>
    </div>
  </div>
  <div v-else>
    <p>Loading Data...</p>
  </div>
</template>

<script>
import { onBeforeMount, ref } from '@vue/runtime-core'
import {projectFirestore} from '../../firebase/config'
import {useRoute, useRouter} from 'vue-router'
import UserListItem from '../AdminComps/UserListItem.vue'
export default {
   components: {UserListItem},
  props:['groupMeta', 'parentRoute'],
  setup(props)
  {
    const m_userdataReady = ref(false)
    const dataAvailable = ref(false)
    const userData = ref([])
    const router = useRouter()
    const route = useRoute()
    
    onBeforeMount( () => {
     
          
          if(props.groupMeta.GroupUsers.length != 0 && props.groupMeta.GroupUsers[0] !== ""){
            dataAvailable.value = true
             const promises = props.groupMeta.GroupUsers.map((id) => {
                    return projectFirestore.collection('users').doc(id).get();
                });

                Promise.all(promises).then(res => {
                     
                     for (let index = 0; index < res.length; index++) {
                         const element = res[index].data();
                         userData.value.push(element)
                     }
                     m_userdataReady.value = true
                })
          }
          else{
              m_userdataReady.value = true
          }
         
    })

    const assignUsers = () => 
    {
        router.push({name: 'AssignUsers', params: {groupID: route.params.groupID, 
        currentUsers:props.groupMeta.GroupUsers}})
    }
    return {m_userdataReady, dataAvailable, userData, assignUsers} 
  }
}
</script>

<style>

.userColumns
{
    width: 100%;
}
.UserData
{
    display: flex;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
  background-color: white;
}

.listContainerDrag
{
  width: 100%;
  margin: 20px 20px;
}
.headerItem
{
    display: flex;
    justify-content: space-between;
    justify-items: center;
    align-content: center;
    align-items: center;
     margin: 20px 20px;
}

.add:hover
{
    color: #FFA072;
}
</style>