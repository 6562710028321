<template>
  <div class="sessionContainer all" v-if="incompletedSessions.length > 0">
      <h2>Your active sessions...</h2>
       <div v-for="(session, index) in incompletedSessions" :key="index">
          <SessionItem :SessionData="session.sessionInfo"  @click="moveToTaskScreen(session.sessionInfo, session.idx)"/>
      </div>
  </div>

  <div class="sessionContainer all" v-if="notStartedSessions.length > 0">
      <h2>Your available sessions...</h2>
      <div v-for="(session, index) in notStartedSessions" :key="index">
          <SessionItem :SessionData="session.sessionInfo"  @click="moveToTaskScreen(session.sessionInfo,  session.idx)"/>
      </div>
  </div>

  <div class="sessionContainer all" v-if="completedSessions.length > 0">
      <h2>Your completed sessions...</h2>
      <div v-for="(session, index) in completedSessions" :key="index">
          <SessionItem :SessionData="session.sessionInfo"  @click="moveToTaskScreen(session.sessionInfo,  session.idx)"/>
      </div>
  </div>

    <div class="sessionContainer all" v-if="unavailableSessions.length > 0">
      <h2>Your upcoming sessions...</h2>
      <div v-for="(session, index) in unavailableSessions" :key="index">
          <SessionItem :SessionData="session.sessionInfo"  @click="moveToTaskScreen(session.sessionInfo,  session.idx)"/>
      </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import SessionItem from './SessionItem.vue'
import {useRouter} from 'vue-router'
import store from '../../store/store'
import { projectFirestore } from '../../firebase/config'

export default {
    components: {SessionItem},
    props: ['SessionData', 'GroupMetaData'],
    setup(props)
    {
        const sessionData = ref(props.SessionData.SessionStates)
        const completedSessions = ref([])
        const incompletedSessions = ref([])
        const unavailableSessions = ref([])
        const notStartedSessions = ref([])
        const groupMetaData = ref(props.GroupMetaData)
        const currentActiveSession = ref()
        const currentActiveSessionIndex = ref()
        const router = useRouter()
        const currentActive = ref(false)

        

        const buildSessionData = () => 
        {
            for (let index = 0; index < sessionData.value.length; index++) {
                
                if(sessionData.value[index].SessionState === 1){
                    currentActiveSession.value = sessionData.value[index]
                    currentActiveSessionIndex.value = index
                    currentActive.value = true;
                    incompletedSessions.value.push({sessionInfo: sessionData.value[index], idx: index})
                    console.log(incompletedSessions.value[0].sessionInfo)
                }
                
                if(sessionData.value[index].SessionState === 2)
                {
                    completedSessions.value.push({ sessionInfo: sessionData.value[index], idx: index})
                }

                if(sessionData.value[index].SessionState === -1)
                {
                    unavailableSessions.value.push({ sessionInfo: sessionData.value[index], idx: index})
                }

                  if(sessionData.value[index].SessionState === 0)
                {
                    notStartedSessions.value.push({ sessionInfo: sessionData.value[index], idx: index})
                }

            }
        }

        buildSessionData()

        const moveToTaskScreen = async (i_session, i_index) => 
        {
            
            if(i_session.SessionState !== -1)
            {
                //
                
                store.commit('storeActiveDataFile', {dataFileLink: props.SessionData.DataLinks[i_index], 
                cardPack: i_session.CardPack, 
                sessionIndex: i_index})
               await projectFirestore.collection('SessionData').doc(store.state.sessionMeta.sessionMetaDataLocation)
                .collection('data').doc(props.SessionData.DataLinks[i_index]).get().then(res => {
                    let data = res.data()
                    store.commit('storeCurrentSessionProgress', {progress: data.saveState})
                    store.commit('storePileData', {nimp: data.nimp, vimp: data.vimp, imp: data.imp})
                    store.commit('storeTopFive', {topFiveData: data.Top5})
                    store.commit('storeFinal', {finalCard: data.finalCard})
                    store.commit('storeBarriers', {barriers: data.surveyResponses.barriers})
                    store.commit('storeGoals', {goalsOne: data.surveyResponses.goalActionOne,
                                                goalsTwo: data.surveyResponses.goalActionTwo,
                                                goalsThree: data.surveyResponses.goalActionThree})
                    store.commit('storeSolutions', {solutions: data.surveyResponses.resolution})
                })
                let viewMode = false
                if(i_session.SessionState >= 2){
                    viewMode = true
                }
                
                router.push({name:'TaskView', 
                params: {sessionMetaDataFile: store.state.sessionMeta.sessionMetaDataLocation,
                dataFile: props.SessionData.DataLinks[i_index],  cardPack: i_session.CardPack, 
                viewMode:viewMode, groupMeta: groupMetaData.value.TaskMeta[i_index].Modules}
               })
                
    
            }
        }
        return {sessionData, currentActiveSession, moveToTaskScreen, currentActiveSessionIndex, currentActive,
        completedSessions, incompletedSessions, notStartedSessions,unavailableSessions}
    }
}
</script>

<style>

.sessionContainer 
{
    max-width: 960px;
    border-radius: 20px;
    background: white;
    box-shadow: 2px 4px 6px rgba(28, 6, 49, 0.1);
    align-items: center;
    margin: 40px auto;
}

.sessionContainer h2 {
    text-align: left;
    padding: 20px 20px;
    font-size: 16px;
}
.sessionContainer .all {
    display: flex;
    flex-direction: column;
}
</style>