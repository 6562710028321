<template>
  <div cla="listContent">
      <div v-for="(user, index) in userInfo" :key="index">
          <UserStatus 
          :userData="user" 
          :selectedWeek="selectedWeek" 
          :class="['selectable', {active: isActive[index]}]"
          @click="ToggleActive(index)"
          @UserState="userStateChange"/>
      </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
//import UserListItem from "../UserListItem.vue"
import UserStatus from "../MonitoringComponents/UserStatus.vue"
import { projectFirestore } from "../../../firebase/config"
import { nextTick, onBeforeMount } from '@vue/runtime-core';

export default 
{
    components:{UserStatus},
    props:['userData', 'groupID', 'selectedWeek', 'selectedUserIDX'],
    emits:['userSelected', 'UserStateChanged'],
    setup(props, context)
    {
        const userInfo = ref(props.userData)
        const isActive = ref([])
        const selectedWeek = ref(-1)

        if(props.selectedWeek !== undefined)
        {
            selectedWeek.value = props.selectedWeek
        }
        
        for (let index = 0; index < props.userData.length; index++) {
            const element = props.userData[index];
            isActive.value.push(false)
        }

        if(props.selectedUserIDX !== -1)
        {
            isActive.value[props.selectedUserIDX] = true;
        }

        const ToggleActive = (idx) => 
        {
            for (let index = 0; index < isActive.value.length; index++) {
                isActive.value[index] = false;
                
            }
            isActive.value[idx] = true;
            
            context.emit("userSelected", userInfo.value[idx], idx)
        }
        
        const userStateChange = (e, UID) => 
        {
            context.emit("UserStateChanged", e, UID)
            
        }
        return {userInfo, isActive, ToggleActive, selectedWeek, userStateChange}
    }
};
</script>

<style>
.selectable:hover
{
    color: #F26522;
}

.selectable.active
{
    color: #7CCBBE;
}

.selectable
{
    cursor: pointer;
}
</style>