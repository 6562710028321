<template>
    <div class="listContainerItem" v-if="ready">
 
      
      <h3>{{ firstName }} - {{ lastName }}</h3>
      <h3> 
        <span class="material-icons-round" title="Online State"
        :class="{'isOnline':online === true, 
        'isOffline':online ===false}">
        account_circle
        </span>

        <span class="material-icons-round" title="Card Sort"
        :class="{'notStarted':userSaveState === 0,
                 'inProgress':userSaveState === 1,
                 'completed':userSaveState > 1}"
        >
        sort
        </span>

        <span class="material-icons-round" title="Top 5"
        :class="{'notStarted':userSaveState < 2,
                 'inProgress':userSaveState === 2,
                 'completed':userSaveState > 2}"
        >
        looks_5
        </span>

        <span class="material-icons-round" title="Final Card"
        :class="{'notStarted':userSaveState < 3,
                 'inProgress':userSaveState === 3,
                 'completed':userSaveState > 3}"
        >
        looks_one
        </span>
          
        <span class="material-icons-round" title="Survey"
        :class="{'notStarted':userSaveState < 5,
                 'inProgress':userSaveState === 5,
                 'completed':userSaveState > 5}"
        >
        poll
        </span>

          </h3>

    <hr>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { nextTick, onBeforeMount } from '@vue/runtime-core';
import {projectFirestore} from '../../../firebase/config'
export default 
{
    props: ["userData", "selectedWeek"],
    emits: ["UserState"],
    setup(props, context)
    {
        const Captilize = (string)  => 
        {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
        const data = ref(props.userData)
        const firstName = ref(Captilize(data.value.FirstName));
        const lastName = ref(Captilize(data.value.LastName));
        const selectedWeek = ref(props.selectedWeek);
        const snapshotData = ref();
        const ready = ref(false);
        const online = ref(false);
        const userSaveState= ref(0);
        onBeforeMount(() => 
        {
            projectFirestore.collection("users").doc(data.value.UID).onSnapshot(snap => 
            {
                snapshotData.value = snap.data();
                if(snapshotData.value.online !== undefined){
                online.value = snapshotData.value.online;
                }
                ResetDom();
            })

           
            if(selectedWeek.value !== -1){
            projectFirestore.collection("SessionData")
            .doc(data.value.SessionMeta).get().then(res => 
            {
                let userSessionMeta = res.data();

                projectFirestore.collection("SessionData")
                .doc(data.value.SessionMeta).collection("data").doc(
                    userSessionMeta.DataLinks[selectedWeek.value.id]).onSnapshot(snap =>
                    {
                        userSaveState.value = snap.data().saveState;
                        context.emit('UserState', userSaveState.value, data.value.UID)
                        ResetDom();
                    })
            })
            }
        })

        const ResetDom = () => 
        {
            ready.value = false;
            nextTick(() => 
            {
                ready.value = true;
            })
        }
        return {firstName, lastName, ready, online,userSaveState}
    }
}
</script>

<style>
.listContainerItem {
  text-align: left;
  margin: 20px 20px;
  padding: 10px;
}
.listContainerItem h3 {
  font-size: 22px;
}

.isOffline
{
    color: #cc274d;
}
.isOnline
{
    color:#74BFB5;
}

.notStarted
{
    color: #2C3E50;
}
.inProgress
{
  color: #DC6F2A
}
.completed
{
    color: #74BFB5;
}
</style>