import { ref } from "vue"
import { projectAuth } from "../firebase/config"

const error = ref(null)

const user = ref(projectAuth.currentUser)
const userToken = ref('')

projectAuth.onAuthStateChanged(_user => 
{
    if(_user){
    _user.getIdTokenResult().then(idTokenResult => {
        //
        userToken.value = idTokenResult.claims
        user.value = _user
    })
    }
    
})

const getUser = () => 
{
    return { user, userToken}
}

export default getUser