import { ref } from "vue"
import { projectAuth, firebaseFuncs, projectFirestore } from "../firebase/config"
import store from '../store/store'
import generateUserDataBundle from "./generateUserDataBundle"
const error = ref(null)

const signup = async (email, password, name, lastname, role, adminUID, groupIDs, orgUID) => 
{
    error.value = null
    const addRole = firebaseFuncs.httpsCallable('addUserRole');
    const register = firebaseFuncs.httpsCallable('register');
    try 
    {
        const concatName = name + ' ' + lastname;
        const res = await register({email: email, pass: password, name: concatName })

        console.log(res);


        if(!res)
        {
            throw new Error('Could not complete the signup')
        }

        if(res.error)
        {
            throw new Error(res.error)
        }

        const uid = res.data.success;
        
        addRole({emails: email, permissions: role})

       

        if(role === 'User')
        {
            const newUser = 
            {
                FirstName:name,
                LastName: lastname,
                UID: uid,
                OrgUID:orgUID,
                adminUID: adminUID,
                groups:groupIDs,
                online: false,
                email: email,
                UserType: "user"
            }

            projectFirestore.collection('users').doc(uid).set(newUser)

            projectFirestore.collection('groups').doc(groupIDs[0]).get().then(result => {
                let data = result.data()
                
                let groups = data.GroupUsers
                if(groups[0] === ""){
                    groups = [uid]
                }
                else{
                groups.push(uid)
                }

                projectFirestore.collection('groups').doc(groupIDs[0]).update({
                    GroupUsers: groups
                })
            })

            projectFirestore.collection('orgs').doc(store.state.user.orgUID).get().then(result => {
                let orgData = result.data()
                let userGroup = orgData.Users
                
                if(userGroup[0] === "")
                {
                    userGroup = [uid]
                }
                else{
                    userGroup.push(uid)
                }

                projectFirestore.collection('orgs').doc(store.state.user.orgUID).update({
                    Users: userGroup
                })

                const {generateGroupDataBundle, error} = generateUserDataBundle()

                generateGroupDataBundle(uid, groupIDs[0])
            })
        }
        if(role == 'Viewer'){
            const newViewer = 
            {
                FirstName:name,
                LastName: lastname,
                UID: uid,
                OrgUID: orgUID,
                adminUID: adminUID,
                groups: groupIDs,
                online: false,
                email: email,
                UserType: "viewer"
            }
            projectFirestore.collection('viewers').doc(uid).set(newViewer)
            
            if(groupIDs[0] !== ""){
            projectFirestore.collection('groups').doc(groupIDs[0]).get().then(result => {
                let data = result.data()
                let groups = data.GroupViewers
                if(groups[0] === ""){
                    groups = [uid]
                }
                else{
                groups.push(uid)
                }

                projectFirestore.collection('groups').doc(groupIDs[0]).update({
                    GroupViewers: groups
                })
            })
        }

            projectFirestore.collection('orgs').doc(store.state.user.orgUID).get().then(result => {
                let orgData = result.data()
                let userGroup = orgData.Viewers

                if(userGroup[0] === "")
                {
                    userGroup = [uid]
                }
                else{
                    userGroup.push(uid)
                }

                projectFirestore.collection('orgs').doc(store.state.user.orgUID).update({
                    Viewers: userGroup
                })
            })
        }

        if(role == 'Admin'){
            const newAdmin = 
            {
                FirstName:name,
                LastName: lastname,
                UID: uid,
                OrgUID: orgUID,
                online: false,
                email: email,
                UserType: "admin"
            }
            projectFirestore.collection('admin').doc(uid).set(newAdmin)

            if(groupIDs[0] !== ""){
            projectFirestore.collection('groups').doc(groupIDs[0]).get().then(result => {
                let data = result.data()
                let groups = data.GroupAdmins
                if(groups[0] === ""){
                    groups = [uid]
                }
                else{
                groups.push(uid)
                }

                projectFirestore.collection('groups').doc(groupIDs[0]).update({
                    GroupAdmins: groups
                })
            })
        }
            projectFirestore.collection('orgs').doc(store.state.user.orgUID).get().then(result => {
                let orgData = result.data()
                let userGroup = orgData.Admins

                if(userGroup[0] === "")
                {
                    userGroup = [uid]
                }
                else{
                    userGroup.push(uid)
                }

                projectFirestore.collection('orgs').doc(store.state.user.orgUID).update({
                    Admins: userGroup
                })
            })
        }
        
     

        error.value = null
        return res
    }
    catch(err)
    {
        
        error.value = err.message
    }
}

const useSignup = () => 
{    
    return {error, signup}
}

export default useSignup