<template>
  <Navbar />

  <div class='container-large'>
    <div v-if="viewReady">
        <ManageGroup :groupDetails="groupDetails" :metaDetails="groupMetaDetails" :parentRoute="parentRoute"/>
    </div>
    <div v-else>
      Loading...
    </div>
  </div>
</template>

<script>
import Navbar from '../../components/Navbar.vue'
import ManageGroup from '../../components/AdminComps/GroupForms/ManageGroup.vue'
import {useRouter, useRoute} from 'vue-router'
import {projectFirestore} from '../../firebase/config'
import { ref } from '@vue/reactivity'
export default 
{
    components: {Navbar, ManageGroup},
    props: ['groupID'],
    setup(props)
    {
        const router = useRouter()
        const route = useRoute()
        const groupDetails = ref('')
        const groupMetaDetails = ref('')
        const viewReady = ref(false)
        const parentRoute = ref(route.params.groupID)

      
        projectFirestore.collection('groups').doc(route.params.groupID).get().then(res => {
            groupDetails.value = res.data();

            let groupMeta = {}

            console.log(groupDetails.value)

            projectFirestore.collection('GroupMeta')
            .doc(groupDetails.value.groupMetaLocation).get().then(result => {
              groupMeta = result.data()
              

              if(Object.keys(groupMeta).length !== 0){
                groupMetaDetails.value = groupMeta;
              }

              viewReady.value = true
            })
        })

      return {viewReady, groupDetails, groupMetaDetails, parentRoute}
    }
}
</script>

<style>

</style>