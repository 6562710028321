import { ref } from "vue"
import { projectFirestore } from "../firebase/config"
import store from "../store/store"
import { useRouter } from "vue-router"
const error = ref(null)
const router = useRouter()

const deleteGroup = async(groupID) => 
{
    await projectFirestore.collection('groups').doc(groupID).get().then(res => {
        let groupData = res.data();
        let groupAdmins = groupData.GroupAdmins;
        let groupUsers = groupData.GroupUsers;
        let groupViewers = groupData.GroupViewers;

        
        
        if(groupAdmins.length > 0){
            const promises = groupAdmins.map((id) => {
                return projectFirestore.collection('admin').doc(id).get()
            })

            Promise.all(promises).then(data => {
                for (let index = 0; index < data.length; index++) {
                    let profile = data[index].data();
                    profile.groups = profile.groups.filter(function(e) {
                        return e !== groupID
                    })
                    projectFirestore.collection('admin').doc(profile.UID).update({
                        groups: profile.groups
                    })
                }
            })
        }
    
        if(groupUsers && groupUsers[0] !== ''){
            const promisesUser = groupUsers.map(id => {
                return projectFirestore.collection('users').doc(id).get()
            })
            
            
        Promise.all(promisesUser).then(data => {
            
            let sessionMetaFiles = []


            for (let index = 0; index < data.length; index++) {
                let profile = data[index].data();
                if(profile.sessionMeta){
                sessionMetaFiles.push(profile.SessionMeta)
                }
                projectFirestore.collection('users').doc(profile.UID).update({
                    groups: ['']
                })     
            }

            const sessionMetaFilesPromises = sessionMetaFiles.map((id) => {
                projectFirestore.collection('SessionData').doc(id).get()
            })

            Promise.all(sessionMetaFilesPromises).then((data) => {
                for (let index = 0; index < data.length; index++) 
                {
                    let SessionData = data[index].data().SessionStates;
                    for (let y = 0; y < SessionData.length; y++) 
                    {
                        SessionData[y].SessionState = -1
                    }
                    projectFirestore.collection('SessionData')
                    .doc(sessionMetaFiles[index])
                    .update
                    (
                        {SessionStates: SessionData}
                    )
                }
            })
        })
        }

        if(groupViewers[0] !== '')
        {
            const promisesViewer = groupViewers.map(id => {
                return projectFirestore.collection('viewers').doc(id).get()
            })
    
            Promise.all(promisesViewer).then(data => {
                for (let index = 0; index < data.length; index++) {
                    let profile = data[index].data();
                    profile.groups = profile.groups.filter(function(e) {
                        return e !== groupID
                    })
                    projectFirestore.collection('viewers').doc(profile.UID).update({
                        groups: profile.groups
                    })
                }
            })
        }
        
        projectFirestore.collection('groups').doc(groupID).delete()
        let router = useRouter();
        router.push({name:'AdminHome'})
    })
}

const removeGroup = () => 
{
    return {error, deleteGroup}
}

export default removeGroup