<template>
  <div class="userColumns" v-if="adminReady && viewerReady">
    <div v-if="dataAvailable" class="UserData">
    <div class=" listContainerDrag assigned">
        <div class="headerItem">
             <h3>Assigned Viewers</h3>
               <span
          class="material-icons-round add"
          @click="assignUsers"
          >edit</span
        >
        </div>
       
        <div v-for="(viewers, index) in viewerData" :key="index">
              <UserListItem :FirstName="viewers.FirstName" :LastName="viewers.LastName" />
        </div>
    </div>
    </div>
    <div v-else class="listContainerDrag">
       <div class="headerItem">
             <h3>No Viewers Assigned</h3>
               <span
          class="material-icons-round add"
          @click="assignUsers"
          >edit</span
        >
        </div>
    </div>
  </div>
  <div v-else>
    <p>Loading Data...</p>
  </div>
</template>

<script>
import { onBeforeMount, ref } from '@vue/runtime-core'
import {projectFirestore} from '../../firebase/config'
import {useRoute, useRouter} from 'vue-router'
import UserListItem from '../AdminComps/UserListItem.vue'
import store from '../../store/store'
export default {
  components: {UserListItem},
  props:['groupMeta', 'parentRoute'],
  setup(props)
  {
    const dataReady = ref(false)
    const dataAvailable = ref(false)
    const viewerData = ref([])
    const router = useRouter()
    const route = useRoute()
    

    const adminReady = ref(false)
    const viewerReady = ref(false)

    onBeforeMount( async () => {
     
          
        if(props.groupMeta.GroupViewers.length != 0 && props.groupMeta.GroupViewers[0] !== "")
        {
            dataAvailable.value = true
             const promises = props.groupMeta.GroupViewers.map((id) => {
                    return projectFirestore.collection('viewers').doc(id).get();
                });

             
                await Promise.all(promises).then(res => {
                     
                     for (let index = 0; index < res.length; index++) {
                         const element = res[index].data();
                         console.log(element)
                         if(element){
                         viewerData.value.push(element)
                         }

                         if(index == res.length - 1)
                         {
                             viewerReady.value = true
                         }
                     }
                  
                })
          }
          else{
            viewerReady.value = true;
          }

          if(props.groupMeta.GroupAdmins && props.groupMeta.GroupAdmins.length !=0 && props.groupMeta.GroupAdmins[0] != '')
          {
            dataAvailable.value = true
            const promisesAdmin = props.groupMeta.GroupAdmins.map((id) => {
                    return projectFirestore.collection('admin').doc(id).get();
                });

             
                await Promise.all(promisesAdmin).then(res => {
                     
                     for (let index = 0; index < res.length; index++) {

                         const element = res[index].data();
                         console.log(element)
                         if(!element) continue;
                         if(element.UID != store.state.user.key){
                             viewerData.value.push(element)
                         }
                        

                          console.log(res.length)
                         if(index == res.length -1)
                         {
                            adminReady.value = true;
                            console.log("Admin Ready")
                         }

                         console.log(index)
                     }
                           
                })
          }
          else
          {
            adminReady.value = true;
          }
         
        
    })

    const assignUsers = () => 
    {
        router.push({name: 'AssignViewers', params: {groupID: route.params.groupID, 
        currentViewers:props.groupMeta.GroupViewers}})
    }
    return {adminReady, viewerReady, dataAvailable, viewerData, assignUsers} 
  }
}

</script>

<style>
.userColumns{
  display: flex;
  justify-content: space-between;
  height: 100%;
  background-color: white;
}

.listContainerDrag
{
  width: 50%;
  margin: 10px 10px;
}

</style>