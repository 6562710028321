<template>
    <div v-if="dataReady">
        
        <div v-for="(task, index) in taskList" :key="index">
            <TaskOptions :TaskName="task" :TaskIndex="index" :TaskRouteDetails="RouteDetails" :TaskOptions="taskSettings[index]"/>
        </div>
    </div>
</template>

<script>
import { onBeforeMount, ref } from '@vue/runtime-core';
import draggable from "vuedraggable";
import TaskOptions from '../TaskOptions.vue'
import {projectFirestore} from '../../firebase/config'
export default 
{
    components:{draggable, TaskOptions},
    props: ['groupMeta', 'parentRoute'],
    setup(props) 
    {
        const dataReady = ref(false)
        const taskList = ref([])

        const taskSettings = ref(props.groupMeta.TaskMeta)

        
        onBeforeMount( async () => {
            projectFirestore.collection('AvailableTasks')
            .doc('TaskList').get().then(res => {
                let data = res.data()
                taskList.value = data.Tasks
                dataReady.value = true;
            })
        })

        const RouteDetails = ref({"parentRoute": props.parentRoute})
        return {dataReady, taskList, RouteDetails, taskSettings}
    }
}
</script>

<style>

</style>