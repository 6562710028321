<template>
  <div :class="{'cardContainer-small': cardSize, 'cardContainer' :!cardSize, 'shifted': shifted, 'highlight': highlighted}" v-if="!hoverDisabled" v-on:click="emitCardClicked">
        <img :src="imgData" />  
  </div>
  <div :class="{'cardContainerHoverDisabled-small': cardSize, 'cardContainerHoverDisabled' :!cardSize, 'shifted': shifted, 'highlight': highlighted}" v-if="hoverDisabled" v-on:click="emitCardClicked">
      <img :src="imgData" /> 
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { onBeforeMount } from "@vue/runtime-core";
import { projectFirestore } from "../../../firebase/config";

import generateImageCache from "../../../composables/generateImageCache";

export default {
  props: ["imgURL", 'small', 'shifted', 'hoverDisabled', 'highlighted'],
  emits: ["cardClicked"],
  setup(props, context) {
    const imgData = ref("");
    const cardSize = ref(false);
    const shifted = ref(false)
    const hoverDisabled = ref(false)
    const highlighted = ref(false)
    if (props.imgURL) 
    {
      if (typeof props.imgURL === "string" || props.imgURL instanceof String) 
      {
        imgData.value = props.imgURL;
      } 
      else 
      {
        imgData.value = props.imgURL[0].cardURL;
      }
    }
    //
    if(props.small === true){
      cardSize.value = true; 
    }
 
    if(props.shifted === true) {
      
      shifted.value = true
    }

    if(props.hoverDisabled === true)
    {
      hoverDisabled.value = true
    }

    if(props.highlighted === true)
    {
      highlighted.value = true
      console.log("highlighted")
    }

    const emitCardClicked = () => 
    {
      context.emit("cardClicked");
    }
    //
    return { imgData, cardSize, shifted, hoverDisabled,highlighted,emitCardClicked };
  },
};
</script>

<style>
.cardContainer {
  width: 90%;
  max-width: 360px;
  min-width: 360px;
  min-height: 220px;
  max-height: 220px;
  border-radius: 20px;
  box-shadow: 4px 4px 4px 6px rgba(28, 6, 49, 0.1);
  padding: 20px 10px;
  background-color: white;
}

.cardContainer-small 
{
  width:90%;
  max-width: 300px;
  min-width: 300px;
  min-height: 180px;
  max-height: 200px;
  border-radius: 20px;
  box-shadow: 4px 4px 4px 6px rgba(28, 6, 49, 0.1);
  padding: 20px 10px;
 
  background-color: white;
}

.cardContainer img {
  width: 100%;
  max-width: 360px;
  max-height: 180px;
  border-radius: 20px;
}

.cardContainer-small img {
  width: 100%;
  max-width: 300px;
  max-height: 180px;
  border-radius: 20px;
}
.cardContainer:hover {
  -moz-transform: scale(1.33);
  -webkit-transform: scale(1.33);
    -o-transform: scale(1.33);
    -ms-transform: scale(1.33);
    -webkit-transform: scale(1.33);
    transform: scale(1.33);

    -webkit-transition: transform 0.35s ease-in-out;
    -moz-transition:transform 0.35s  ease-in-out;
    -ms-transition:transform 0.35s  ease-in-out;
}

.cardContainerHoverDisabled 
{
  width: 90%;
  max-width: 360px;
  min-width: 360px;
  min-height: 220px;
  max-height: 220px;
  border-radius: 20px;
  box-shadow: 4px 4px 4px 6px rgba(28, 6, 49, 0.1);
  padding: 20px 10px;
  background-color: white;
}

.cardContainerHoverDisabled img {
  width: 100%;
  max-width: 360px;
  max-height: 180px;
  border-radius: 20px;
}

.cardContainerHoverDisabled-small 
{
  width: 100%;
  min-height: 180px;
  max-height: 200px;
  border-radius: 20px;
  box-shadow: 4px 4px 4px 6px rgba(28, 6, 49, 0.1);
  padding: 20px 10px;
 
  background-color: white;
}

.cardContainerHoverDisabled-small img {
  width: 100%;
  max-width: 300px;
  max-height: 180px;
  border-radius: 20px;
}


.shifted
{
   transform: translate(0%, 30%);
}

.cardContainer-small:hover {
    -moz-transform: scale(1.33);
  -webkit-transform: scale(1.33);
    -o-transform: scale(1.33);
    -ms-transform: scale(1.33);
    -webkit-transform: scale(1.33);
    transform: scale(1.33);

    -webkit-transition: transform 0.35s  ease-in-out;
    -moz-transition:transform 0.35s  ease-in-out;
    -ms-transition:transform 0.35s ease-in-out;
}

</style>