<template>
  <div class="sidebar">
        <div class="IconContainer">
            <div
            class="menuItem"
            :class="{ selected: iconClicked[0] }"
            @click="select(0, 'MoniterGroup')">
                <span class="material-icons-round">home</span>
            </div>
        </div>

        <div class="IconContainer">
            <div
            class="menuItem"
            :class="{ selected: iconClicked[1] }"
            @click="select(1, 'DataView')">
                <span class="material-icons-round">insights</span>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import {useRouter, useRoute} from 'vue-router'
export default 
{   
    props: ['selectedView'],
    setup(props) {
        const iconClicked = ref([false, false]);
        const router = useRouter();
        
        iconClicked.value[props.selectedView.id] = true
    
        
        const select = (item, destination) => {
        iconClicked.value = [false, false]
        iconClicked.value[item] = true
        console.log(destination)
        router.push({name: destination})
        }
    
        return {iconClicked, select}
    }
}
</script>

<style>
.sidebar {
  position: fixed;
  width: 88px;
  height: 100%;
  left: 0px;
  background: #f37539;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  user-select: none
}

.sidebar .IconContainer .menuItem .material-icons-round {
  font-size: 60px;
}

.sidebar .IconContainer .menuItem {
  padding: 0px 0px;
  background: #f37539;
  width: 88px;
  height: 88px;
  color: #2f4858;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.sidebar .IconContainer .menuItem :hover {
  width: 88px;
  height: 88px;
  background: #ffa072;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
 	border-left-color: #2f4858 5px solid;
	transition: color 0.2s ease-in;
}

.sidebar .IconContainer .menuItem.selected {
  padding: 0px 0px;
  width: 88px;
  height: 88px;
  background: #ffa072;
  color: white;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-left: #2f4858 5px solid;
  pointer-events: none;

}
</style>