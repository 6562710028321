<template>
 <Navbar />
 <SideNav :selectedView="{'id': 2}" v-if="priv !=='viewer'"/>
 <ViewerSideNav :selectedView="{'id': 0}" v-if="priv === 'viewer'" />
  <div class="content">
      <SelectGroupToMonitor />
  </div>
</template>

<script>
import Navbar from "../../components/Navbar.vue";
import SideNav from "../../components/AdminComps/SideNav.vue";
import ViewerSideNav from "../../components/AdminComps/ViewerSideNav.vue";
import SelectGroupToMonitor from "../../components/AdminComps/MonitoringComponents/SelectGroupToMonitor.vue"
import store from "../../store/store"
import { ref } from '@vue/reactivity';
export default 
{
    components: { Navbar, SideNav, SelectGroupToMonitor, ViewerSideNav},
    setup()
    {
        const priv = ref(store.state.user.priv)

        return {priv}
    }
}
</script>

<style>

</style>