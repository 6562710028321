<template>
  <div class="draggableContainerItems">
      {{userFirstName}} {{userLastName}} ({{userType}})
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
export default {
    props:['userInfo', 'placeholderText'],
    setup(props)
    {

        const userFirstName = ref(props.placeholderText)
        const userLastName = ref('...')
        const userType = ref('NT')
        if(props.userInfo)
        {
            userFirstName.value = props.userInfo.FirstName;
            userLastName.value = props.userInfo.LastName
            if(props.userInfo.UserType)
            {
                userType.value = props.userInfo.UserType
            }
        }
        return {userFirstName, userLastName, userType}
    }
}
</script>

<style>
.draggableContainerItems
{
    background: white;
    width: 300px;
    border: 1px solid black;
    border-radius: 20px;
    margin: 10px auto;
    padding: 10px 10px;
    cursor: move  ;
      -webkit-user-select: none;  /* Chrome all / Safari all */
  -moz-user-select: none;     /* Firefox all */
  -ms-user-select: none;      /* IE 10+ */
  user-select: none;  
}

.draggableContainerItems:hover
{
     background: #F26522;
     color: white;
}
</style>