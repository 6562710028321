<template>
  <Navbar data-html2canvas-ignore="true"/>
  <div class="surveyContainerHome" v-if="homeView">
    <div class="containerHeader">
      <h2>Worksheet</h2>
      <p>
        You will now be asked to fill out a worksheet exploring your top value for this week.
      </p>
     
    </div>
    <div class="containerBody">
      <Cards :imgURL="cardURL.cardURL" />
    </div>
     <p>At the end of the worksheet, an email will be sent to you with your filled in responses.</p>
    <div class="containerFooter">
      <button @click="onStartClicked">Continue</button>
    </div>
  </div>
  <div v-if="surveyView">
    <SurveyPanel :viewMode="viewMode"/>
  </div>
</template>

<script>
import Navbar from "../../components/Navbar.vue";
import Cards from "../../components/ClientComps/TaskComps/Cards.vue";
import SurveyPanel from "../../components/ClientComps/TaskComps/SurveyComps/SurveyPanel.vue";

import { useRoute, useRouter } from "vue-router";

import store from "../../store/store";
import { ref } from "@vue/reactivity";
import { nextTick, onBeforeMount } from "@vue/runtime-core";
import { projectFirestore } from "../../firebase/config";
export default {
  components: { Navbar, Cards, SurveyPanel },
  props: ["routeValid", "viewMode"],
  setup(props) {
    const homeView = ref(true);
    const surveyView = ref(false);
    const route = useRoute();
    const router = useRouter();
    const cardURL = ref();
    const viewMode = ref(route.params.viewMode)
        
      if (!route.params.routeValid) {
        router.push("/");
        return;
      }
    onBeforeMount(() => {
        cardURL.value = store.state.lastChoice.finalCard;
    
    });
      
    const onStartClicked = () => {
      homeView.value = false;
      console.log(route.params.viewMode)
      if(route.params.viewMode === false || route.params.viewMode === "false"){
      projectFirestore
        .collection("SessionData")
        .doc(store.state.sessionMeta.sessionMetaDataLocation)
        .collection("data")
        .doc(store.state.sessionMeta.activeDataFile)
        .update({
          saveState: 5,
        });
      }
      nextTick(() => {
        surveyView.value = true;
      });
    };
    return { homeView, surveyView, viewMode, onStartClicked, cardURL };
  },
};
</script>

<style>
.surveyContainerHome {
  width: 90%;
  max-width: 980px;
  min-height: 400px;
  margin: 80px auto;
  border-radius: 20px;
  box-shadow: 2px 4px 6px rgba(28, 6, 49, 0.1);
  background: white;
}

.surveyContainerHome .containerHeader {
  padding: 20px 20px;
}
.surveyContainerHome .containerHeader h2 {
  padding: 20px 20px;
}

.surveyContainerHome .containerBody {
  padding: 20px 20px;
  display: flex;
  justify-content: space-around;
}

.surveyContainerHome .containerFooter {
  padding: 20px 20px;
}
</style>