<template>
  <Navbar />

  <div class="container">
    <div v-if="!dataReady">Loading group information...</div>
    <div v-else>
      <form class="addParticipantForm" @submit.prevent>
        <h2 style='font-size:17px; padding:20px 00px'>Currently editing: {{oldGroupName}}</h2>
         <div class="addParticipantFormHeader">
           <h2>Group Name:</h2>
           <span class="material-icons-round icon-delete" @click='DeleteDialogue()'>
            delete_forever
            </span>

         </div>
        
        <input
          type="text"
          required
          placeholder="Group Name..."
          v-model="groupName"
        />
        <h2>Notes:</h2>
        <textarea
          name="notesArea"
          id=""
          cols="30"
          rows="10"
          class="textBox"
          placeholder="group notes here..."
          v-model="notes"
        ></textarea>
        <div class="buttonContainer">
          <button formnovalidate @click="cancel">Cancel</button>
          <button @click="validate" type="submit">Save Group</button>
        </div>
      </form>
    </div>
  </div>

  <div class="modal-mask" v-if="modalVisible">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
            <h2>Are you sure you want to permanantly delete "{{groupName}}" ?</h2>
        </div>
        <div class="modal-body">
          <p>This will remove all data asociated with the group and its users. </p>
          <p class='danger'>This action cannot be reveresed.</p>
          <p>Please enter the group name to proceed:</p>
          <input type="text" class='text-input' v-model="deleteConfirmation" @input='validateDelete()'>
        </div>
        <div class="modal-footer">
              <button :disabled="deleteDisabled" @click="FinalizeDelete()" :class="{'isDisabled': deleteDisabled}">Delete</button>
              <button @click='cancelDelete()'>Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onBeforeMount, ref } from "@vue/runtime-core";
import Navbar from "../../components/Navbar.vue";
import { projectFirestore } from "../../firebase/config";
import { useRoute, useRouter } from "vue-router";
import removeGroup from '../../composables/removeGroup'
export default {
  components: { Navbar },
  props: ["groupID"],
  setup() 
  {
    const data = ref("");
    const dataReady = ref(false);
    const route = useRoute();
    const router = useRouter();

    const groupName = ref('')
    const oldGroupName = ref('')
    const date = ref('')
    const numberOfParticipants = ref(1)
    const notes  = ref('')
    const groupWeek = ref('')
    const deleteConfirmation = ref('')
    const modalVisible = ref(false)
    const deleteDisabled = ref(true)

    const {error, deleteGroup} = removeGroup()

    onBeforeMount(async () => {
      await projectFirestore
        .collection("groups")
        .doc(route.params.groupID)
        .get()
        .then((res) => {
          data.value = res.data();
          groupName.value = data.value.groupName
          date.value = data.value.createdAt
          notes.value = data.value.notes
          numberOfParticipants.value = data.value.numParticipants
          groupWeek.value = data.value.groupWeek
          dataReady.value = true;
          oldGroupName.value = groupName.value
        });
    });

    const validate = async () => {
        await projectFirestore
        .collection("groups")
        .doc(route.params.groupID).update({
            groupName: groupName.value,
            notes: notes.value,
            groupWeek: groupWeek.value,
            numParticipants: numberOfParticipants.value,
            StartDate: date.value
        }).then(() => {
            router.push({name:"AdminHome"})
        })
    }

    const cancel = () => 
    {
        router.push({name:"AdminHome"})
    }

    const DeleteDialogue = () => 
    {
        modalVisible.value = !modalVisible.value
    }

    const validateDelete = () => 
    {
        if(oldGroupName.value === deleteConfirmation.value)
        {
            deleteDisabled.value = false;
        }
        else
        {
            deleteDisabled.value = true;
        }
    }

    const FinalizeDelete = () => {

      if(!deleteDisabled.value){
        
        deleteGroup(route.params.groupID)
      }
      else
      {
         
      }
     
    }

    const cancelDelete = () => 
    {
      deleteDisabled.value = true
      deleteConfirmation.value = ''
      modalVisible.value = false;
    }

    return { dataReady, 
    groupName, 
    date, 
    numberOfParticipants, 
    notes, 
    groupWeek, 
    modalVisible,
    deleteConfirmation,
    oldGroupName,
    deleteDisabled, 
    validate, cancel, DeleteDialogue, validateDelete, FinalizeDelete, cancelDelete };
  },
};
</script>

<style>
.container 
{
    padding: 10px 10px;
}

.addParticipantFormHeader
{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon-delete
{
  padding: 10px 20px;
}
.icon-delete:hover
{
  cursor: pointer;
  color: red;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 500px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-footer
{
  display: flex;
  justify-content: space-around;
  margin: 20px 40px;

}

.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.5s ease;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}

.danger
{
  color: red;
  padding: 20px 20px;
}
.text-input
{
  width: 300px;
  height: 40px;
  border-radius: 10px;
  margin: 20px 10px;
}
.isDisabled
{
  background-color: grey;
  cursor: default ;
}

.isDisabled:hover
{
  background-color: grey;
}
</style>