<template>
  <div class="NavContainer">
      <Navbar />    
  </div>
  <div class="dataReady" v-if="dataReady">
       <CurrentSessionData :SessionData="sessionViewData" :GroupMetaData="groupMetaData"/>
  </div>

  <div class="container" v-if="userNotAssignedIntoGroup">
      <h2>You have not been assigned into a group yet, please contact the system admin.</h2>
  </div>
 
</template>

<script>
import Navbar from '../../components/Navbar.vue'
import {useRouter} from 'vue-router'
import { nextTick, onBeforeMount, onMounted, ref, watch } from '@vue/runtime-core'
import CurrentSessionData from '../../components/ClientComps/CurrentSessionData.vue'
import {projectFirestore} from '../../firebase/config'
import store from '../../store/store'
import convertTimestamp from '../../composables/convertTimestamp'
export default {
    components: {Navbar, CurrentSessionData},
    setup()
    {   
        const sessionViewData = ref('')
        const dataReady = ref(false)
        const userNotAssignedIntoGroup = ref(false)
        const groupMetaData = ref()
        onBeforeMount(async () => {

            let key = store.state.user.key
            let sessionMeta =''

            await projectFirestore.collection('users').doc(key).get().then(res => 
            {
                let data = res.data()   
                sessionMeta = data.SessionMeta
                store.commit('storeSessionData', {sessionMeta: sessionMeta})

                projectFirestore.collection('SessionData').doc(sessionMeta).get()
                .then((result) => {
                    let sessionMetaData = result.data()
                    sessionViewData.value = sessionMetaData
                    console.log(sessionMetaData)
                    if(data.groups[0] !== ''){
                    projectFirestore.collection('groups').doc(data.groups[0]).get().then(resultGroup => {
                     
                        let groupData = resultGroup.data()
                      
                        projectFirestore.collection('GroupMeta').doc(groupData.groupMetaLocation).get().then(metaDataGroup => {
                            groupMetaData.value = metaDataGroup.data()
                           
                            for (let index = 0; index < groupMetaData.value.TaskMeta.length; index++) {
                                const element = groupMetaData.value.TaskMeta[index];
                                let startDate = Date.parse(element.StartDate)

                                if(startDate > Date.now()){
                                    sessionViewData.value.SessionStates[index].SessionState = -1
                                   
                                }
                                else if(startDate < Date.now() && sessionViewData.value.SessionStates[index].SessionState === -1)
                                {
                                    sessionViewData.value.SessionStates[index].SessionState = 0
                                }

                                console.log(sessionViewData.value)
                                sessionViewData.value.SessionStates[index].SessionStartDate = convertTimestamp(startDate)
                            }

                            
                            dataReady.value = true
                        })
                    })
                    }
                    else
                    {
                        userNotAssignedIntoGroup.value = true;
                    }
                })

                
                
            })
        })

        const router = useRouter()
        return { sessionViewData, dataReady, userNotAssignedIntoGroup, groupMetaData }
    }
}
</script>

<style>
    .NavContainer
    {
        background: #2F4858;
    }
 
</style>