<template>
  <div class="scrollBoxView">
     <div class="cardView" v-for="(card, index) in cardUrls" :key="card.index">
         <div v-if="!cardsInteracble">
             <Cards :imgURL="card.cardURL" class="cardView" :small="true"/>
         </div>
         <div v-else>
             <div v-if="viewMode==='false' && rendered">
                         <Cards :imgURL="card.cardURL"  
             :highlighted="highLighted[index]" 
             :small="true"
              @cardClicked="cardClicked(index)"/>
             </div>
            <div v-else="viewMode==='true' && rendered">
            <Cards :imgURL="card.cardURL" 
             :highlighted="highLighted[index]"
             :small="true"/>
            </div>
         </div>
     </div>
            
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import store from '../../../../store/store'
import Cards from '../Cards.vue'
import { projectFirestore } from '../../../../firebase/config'
import { onBeforeMount } from '@vue/runtime-core'
import { nextTick } from 'process'


export default {
    props: ['cardsToDisplay', 'interactable', 'viewMode', 'adminMode'],
    emits: ['cardsSelected', 'SkipSelection'],
    components: {Cards},
    setup(props, context)
    {


        const cardUrls = ref(props.cardsToDisplay)
        const cardsInteracble = ref(props.interactable)
        const clickedCards = ref([])
        const finalCardObjs = ref([])
        const highLighted = ref([])
        const viewMode = ref(props.viewMode)
        const rendered = ref(false);
        const assignCards = () => {
            if(cardsInteracble.value)
            {
                cardUrls.value = store.state.pileData.veryImportantPile
                
                if(cardUrls.value.length < 4)
                {
                    cardUrls.value = store.state.pileData.importantPile
                }
                 if(cardUrls.value.length < 4){
                    cardUrls.value = store.state.pileData.notImportantPile
                }

                for (let index = 0; index < cardUrls.value.length; index++) {
                    highLighted.value.push(false)        
                }
                
             
                
                if(store.state.topFiveData.topFiveSelected && store.state.topFiveData.topFiveSelected.length > 0)
                {
                    finalCardObjs.value = store.state.topFiveData.topFiveSelected
                    

                    for (let i = 0; i < cardUrls.value.length; i++) {
                        for (let y = 0; y < store.state.topFiveData.topFiveSelected.length; y++) {
                            
                            if(cardUrls.value[i].index === store.state.topFiveData.topFiveSelected[y].index){
                                highLighted.value[i] = true
                                clickedCards.value.push(i)
                                
                            }
                            
                        }
                    
                    }

                        projectFirestore.collection('SessionData')
                        .doc(store.state.sessionMeta.sessionMetaDataLocation)
                        .collection('data').doc(store.state.sessionMeta.activeDataFile).update({
                        saveState: 2
                        })
                        //context.emit("SkipSelection")
                }

                else if(cardUrls.value.length <= 5)
                    {
                        for (let index = 0; index < cardUrls.value.length; index++) 
                        {
                            highLighted.value[index] = true;
                            clickedCards.value.push(index) 
                        }

                        finalCardObjs.value = []
                        for (let index = 0; index < clickedCards.value.length; index++) 
                        {
                            finalCardObjs.value.push(cardUrls.value[clickedCards.value[index]])
                        }

                        store.commit('storeTopFive', {topFiveData: finalCardObjs.value})
                        projectFirestore.collection('SessionData')
                        .doc(store.state.sessionMeta.sessionMetaDataLocation)
                        .collection('data').doc(store.state.sessionMeta.activeDataFile).update({
                        Top5: finalCardObjs.value,
                        saveState: 2
                        })

                        //context.emit("SkipSelection")

                    }
            }

            
            if(clickedCards.value.length === 5 || clickedCards.value.length === cardUrls.value.length)
            {
                context.emit('cardsSelected', true)
            }

            nextTick(() => {
                rendered.value = true
            })
            
        }
        

        const cardClicked = (cardIndex) => 
        {

            console.log(cardIndex)
            
            highLighted.value[cardIndex] = !highLighted.value[cardIndex]

            let currentVal = highLighted.value[cardIndex]
            if(currentVal) 
            {
                clickedCards.value.push(cardIndex)
                if(clickedCards.value.length > 5)
                {
                    let val = clickedCards.value.shift()
                    highLighted.value[val] = !highLighted.value[val]
                    console.log( highLighted.value[val])
                }
            }
            else
            {
                var index = clickedCards.value.indexOf(cardIndex)
                if(index !== -1){
                    clickedCards.value.splice(index, 1)
                }
            }
            
            finalCardObjs.value = []
            for (let index = 0; index < clickedCards.value.length; index++) {
                finalCardObjs.value.push(cardUrls.value[clickedCards.value[index]])
                
            }
            console.log(finalCardObjs.value)
            store.commit('storeTopFive', {topFiveData: finalCardObjs.value})
            projectFirestore.collection('SessionData')
            .doc(store.state.sessionMeta.sessionMetaDataLocation)
            .collection('data').doc(store.state.sessionMeta.activeDataFile).update({
                Top5: finalCardObjs.value,
                saveState: 2
            })

                if(clickedCards.value.length === 5 || clickedCards.value.length === cardUrls.value.length){
                    context.emit('cardsSelected', true)
                }
                else
                {
                    context.emit('cardsSelected', false)
                
            //
            
            }
    rendered.value = false
                 nextTick(() => 
            {
                rendered.value = true
                console.log("rerendered")
            })
        }

        assignCards();
        //
        return {cardUrls, cardsInteracble, cardClicked, highLighted, viewMode, rendered}
    }
}
</script>

<style>
.scrollBoxView
{
    width: 1000px;
    height: 570px;
    border-radius: 20px;
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    overflow: scroll;
    overflow-x: hidden;
    border: none;
    padding: 10px;
}

.scrollBoxView .cardView{
    padding: 10px 10px;
}
.scrollBoxView .cardView.high{
    padding: 10px 10px;
}

.highlight{
    border: 5px solid #7CCCBF ;
}
::-webkit-scrollbar {
width: 12px;
height: 12px;
}

::-webkit-scrollbar-track {
border: 1px solid #F26522;
background: white;
border-radius: 10px;
}

::-webkit-scrollbar-thumb {
background: #F26522;  
border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
background: #88ba1c;  
}
</style>