<template>
  <div class="draggableCard">
    <div v-if="!tutorialComplete">
      <h3>Your Card</h3>
      <draggable
        :class="displayCard"
        :list="currentCard"
        group="values"
        @change="log"
        itemKey="index"
        class="cardSlot"
      >
        <template #item="{}">
          <div class="list-group-item">

            <Cards v-if="cardVisible" :imgURL="currentCard" :small="false"/>
          </div>
        </template>
      </draggable>
    </div>
    <div v-else>
      <button @click="emitNextTutorial">Continue tutorial</button>
    </div>
  </div>

<div class="cardHeaders-tut">
  <h2>Very Important</h2>
  <h2>Important</h2>
  <h2>Not Important</h2>
</div>

  <div class="cardRow-tut">
    
    <div class="col-3-tut">
      <draggable
        :class="displayCard"
        :list="veryImportantPile"
        :sort="false"
        :move="itemMoved"
        :onAdd="itemAdded"
        :empty-insert-threshhold="100"
        ghost-class="ghost"
        group="values"
        itemKey="index"
        id="vimp"
      >
      <h2>Drag Here</h2>
        <template #item="{ element }">
          <div class="list-group-item">
            <div class="piles">
              <Cards v-if="cardVisible" :imgURL="element.cardURL" :small="false" :shifted="true" :hoverDisabled="true"/>
            </div>
          </div>
        </template>
      </draggable>
    </div>

    <div class="col-3-tut">
      <draggable
        :class="displayCard"
        :list="importantPile"
        :sort="false"
        :move="itemMoved"
        :empty-insert-threshhold="100"
        ghost-class="ghost"
        :onAdd="itemAdded"
        itemKey="index"
        group="values"
        id="vimp"
      >
        <template #item="{ element }">
          <div class="list-group-item">
            <div class="piles">
              <Cards v-if="cardVisible" :imgURL="element.cardURL" :small="false" :shifted="true" :hoverDisabled="true"/>
            </div>
          </div>
        </template>
      </draggable>
    </div>
    <div class="col-3-tut">
      <draggable
        :class="displayCard"
        :list="notImportantPile"
        :sort="false"
        :onAdd="itemAdded"
        :move="itemMoved"
        :empty-insert-threshhold="100"
        ghost-class="ghost"
        group="values"
        itemKey="index"
        id="vimp"
      >
        <template #item="{ element }">
          <div class="list-group-item">
            <div class="piles">
              <Cards v-if="cardVisible" :imgURL="element.cardURL" :small="false" :shifted="true" :hoverDisabled="true"/>
            </div>
          </div>
        </template>
      </draggable>
    </div>
  </div>

  <div class="invisible">
    <img :src="nextImg" alt="" />
  </div>
</template>

<script>
import { computed, ref } from "@vue/reactivity";
import draggable from "vuedraggable";
import Cards from "../TaskComps/Cards.vue";
import { projectFirestore } from "../../../firebase/config";
import { createWebHistory } from "vue-router";
import { nextTick, onBeforeMount } from "@vue/runtime-core";
import store from '../../../store/store'

export default {
  components: { draggable, Cards },
  emits: ["tutorialFinished"],
  setup(props, context) {
    const testData = ref([]);
    const nextImg = ref("");

    const notImportantPile = ref([]);
    const importantPile = ref([]);
    const veryImportantPile = ref([]);

    const currentCard = ref([
      { cardValue: "to hold nine", index: 10, cardURL: "" },
    ]);

    const displayCard = ref("");
    const tutorialComplete = ref(false);
    const cardVisible = ref(false);

    onBeforeMount(() => {
      //
      projectFirestore
        .collection("CardTasks")
        .doc(store.state.sessionMeta.currentCardPack)
        .get()
        .then((res) => {
          let data = res.data();
          
          testData.value = data[data.name].reverse();
          testData.value = [testData.value[testData.value.length - 1]]
          currentCard.value = [testData.value.pop()];
          testData.value.splice(0, -1);
          nextTick(() => {
            cardVisible.value = true;
          });
        });
    });

    const log = (evt) => {
      const img = new Image();

      if (evt.removed) {
        if (testData.value.length !== 0) {
          cardVisible.value = false;
          currentCard.value = [testData.value.pop()];
          
          if(testData.value.length > 0){
          nextImg.value = testData.value[testData.value.length - 1].cardURL;
          }

          nextTick(() => {
            cardVisible.value = true;
          });
          //
        } else {
          currentCard.value = null;
          tutorialComplete.value = true;
        }

        //testData.value.splice(0, -1);
      }
    };

    const emitNextTutorial = () => {
      context.emit("tutorialFinished");
    };
    const itemMoved = (evt, origEvt) => {
      if (evt.to.id === "vimp" || evt.from.id ==="vimp") {
        return false;
      }
    };

    const itemAdded = (evt, origEvt) => {
        reorderArray(notImportantPile)
        reorderArray(importantPile)
        reorderArray(veryImportantPile)
    }

    const reorderArray = (arr) => {
         for(let i = 1; i < arr.value.length; i++){
          for(let j = i -1; j > -1; j--){
            if(arr.value[j + 1].index < arr.value[j].index){

              [arr.value[j+1], arr.value[j]] = 
              [arr.value[j], arr.value[j +1]]
            }
          }
        }
    }
    return {
      testData,
      notImportantPile,
      importantPile,
      veryImportantPile,
      log,
      currentCard,
      displayCard,
      tutorialComplete,
      itemMoved,
      emitNextTutorial,
      itemAdded,
      cardVisible,
      nextImg,
    };
  },
};
</script>

<style>
.cardRow-tut {
  display: flex;
  justify-content: space-around;
  margin: 20px 0px;
  padding: 10px 10px;
  
}

.list-group-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 400px;
}

.list-group-item .piles {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 400px;
  margin: -120px 20px;
}

.col-3-tut {
  min-width: 440px;
  padding: 10px 10px;
  min-height: 240px;
  max-height: 600px;
  background-color: #e7e7e7;
  border-radius: 20px;
}

.draggableCard 
{
   min-width: 440px;
  padding: 20px 20px;
  min-height: 240px;
  max-height: 600px;
}

.invisible {
  visibility: hidden;
  display: none;
}

.cardHeaders-tut{
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding: 10px 100px;
  margin: 10px 60px;
}


.col-3-tut::before
{
  margin: 3rem;
  content: "Drag Here";
  color: rgb(0, 0, 0);
  top: 50%;
  left: 50%;
  z-index: -1;
}


.ghost 
{
  margin: -120px 20px;
  
}


.cardSlot{
  min-height: 244px;
}
</style>