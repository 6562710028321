<template>
  <div class="taskThreeContainer">

    
    <div class="body">
      <div class="taskThreeInstructions">
        <h2>Instructions</h2>
        <p v-if="!finalSlot && viewMode === 'false'">
        These are the top {{counter}} values you selected. Now pick one to focus on this week.
        </p>
        <p v-else-if="finalSlot && viewMode === 'false'">
          These are your top five values you selected this week.
        </p>
        <p v-else-if="!finalSlot && viewMode === 'true'">
          This is the card you chose from your top {{counter}}.
        </p>
        <p v-else>
          These were your final selections.
        </p>


           <div class="footer">
        <div v-if="cardSelected && !finalSlot">
            <button @click="MoveToFinalScreen">Continue</button>
        </div>

        <div v-if="finalSlot">
          <button @click="endSegment"> Finish </button>
        </div>
    </div>
      </div>
      <div class="taskThreeCards">
        <div
          class="fiveCardView"
          v-for="(card, index) in topFiveData"
          :key="card.index"
        >
        <div v-if="viewMode==='false' && render">
                 <Cards
            :imgURL="card.cardURL"
            @cardClicked="selectCard(index)"
            :highlighted ="selectedCard[index]"
            :small="true"
          />
        </div>
        <div v-else="viewMode === 'true' && render">
                     <Cards
            :imgURL="card.cardURL"
            :highlighted="selectedCard[index]"
            :small="true"
          />
        </div>
        </div>
      </div>
    </div>

 
  </div>
</template>

<script>
import Cards from "../Cards.vue";
import store from "../../../../store/store";
import { ref } from "@vue/reactivity";
import { projectFirestore } from '../../../../firebase/config';
import { onBeforeMount } from '@vue/runtime-core';
import { useRoute, useRouter } from 'vue-router'
import { nextTick } from "process";
export default {
  components: { Cards },
  props: ['viewMode', 'finalSlot'],
  emits: ['finalDisplay'],
 
  setup(props, context) 
  {
    const topFiveData = ref("");
    const finalSlot = ref(props.finalSlot)
    topFiveData.value = store.state.topFiveData.topFiveSelected;

    const selectedCard = ref([false, false, false, false, false]);
    const currentSelected = ref(-1);

    const cardSelected = ref(false);
    const viewMode = ref(props.viewMode)
    const router = useRouter();
    const counter = ref(topFiveData.value.length)
    const render = ref(false)

    onBeforeMount(() => 
    {
      if(store.state.lastChoice.finalCard)
        {
          for (let index = 0; index < topFiveData.value.length; index++) {
            if(topFiveData.value[index].index === store.state.lastChoice.finalCard.index) 
            {
              selectedCard.value[index] = true
              cardSelected.value = true
              currentSelected.value = index
            }
            
          }
        }

        nextTick(() => {
          render.value = true
        })
    })


    const selectCard = (cIndex) => {
      if (cIndex === currentSelected.value) {
        currentSelected.value = -1;
        selectedCard.value[cIndex] = !selectedCard.value[cIndex];
        cardSelected.value = false;
        store.commit('storeFinal', {finalCard: null})
            projectFirestore.collection('SessionData')
            .doc(store.state.sessionMeta.sessionMetaDataLocation)
            .collection('data').doc(store.state.sessionMeta.activeDataFile).update({
                finalCard: null,
                saveState: 3
            })
      } 
      else 
      {
        for (let index = 0; index < selectedCard.value.length; index++) 
        {
          selectedCard.value[index] = false;
        }

        currentSelected.value = cIndex;
        selectedCard.value[cIndex] = true;
        cardSelected.value = true;
        store.commit('storeFinal', {finalCard: topFiveData.value[cIndex]})
          projectFirestore.collection('SessionData')
            .doc(store.state.sessionMeta.sessionMetaDataLocation)
            .collection('data').doc(store.state.sessionMeta.activeDataFile).update({
                finalCard: topFiveData.value[cIndex],
                saveState: 3
            })
      }
      render.value = false
      nextTick(() => {
        render.value = true
      })
    };

    const MoveToFinalScreen = () => 
    {
        context.emit("finalDisplay")
    }

    const endSegment = () => 
    {
        router.push('ClientHome')
    }
    return { topFiveData, selectedCard, cardSelected, viewMode, selectCard, 
    MoveToFinalScreen, finalSlot, endSegment, counter, render };
  },
};
</script>

<style>
.taskThreeContainer {
  width: 1400px;
  min-height: 400px;
  margin: 80px auto;
  border-radius: 20px;
  box-shadow: 2px 4px 6px rgba(28, 6, 49, 0.1);
  background: white;
}
.taskThreeContainer .body {
  display: flex;
}
.taskThreeContainer .footer {
  margin: 50px 10px;
  padding: 30px 20px;
}
.taskThreeContainer .taskThreeInstructions {
  max-width: 20%;
  min-width: 20%;
}
.taskThreeContainer .taskThreeInstructions h2 {
  text-align: left;
  padding: 20px 20px;
}
.taskThreeContainer .taskThreeInstructions p {
  text-align: left;
  padding: 20px 20px;
}
.taskThreeContainer .taskThreeCards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.taskThreeContainer .taskThreeCards .fiveCardView {
  padding: 20px 20px;
}
.taskThreeContainer .taskThreeCards .fiveCardView .selected {
  border: 5px solid #7ccbbf;
}
</style>