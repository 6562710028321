<template>
  <form @submit.prevent="handleSubmit" class="taskEditCSS">
    <h3>Task Start Date:</h3>
    <input type="datetime-local" id="start" v-model="date"/>

    <div class="TaskModules">
      <div class="checkBoxOverride">
        <div class="EditTaskhHeader">
          <h3>Card Sort</h3>
        </div>
        <div class="EditTaskCheckbox">
          <input
            type="checkbox"
            class="largeCheckmark"
            id="SortOptions.CardSort"
            :disabled="CardSortEnabled"
            v-model="CardSort"
            @change="check($event)"
          />
        </div>
      </div>

      <div class="checkBoxOverride" v-if="CardSort">
        <div class="EditTaskhHeader">
          <h3>Top Five</h3>
        </div>
        <div class="EditTaskCheckbox">
          <input
            type="checkbox"
            class="largeCheckmark"
            id="SortOptions.TopFive"
            :disabled="TopFiveEnabled"
            v-model="TopFive"
            @change="check($event)"
          />
        </div>
      </div>
      <div class="checkBoxOverride" v-if="TopFive">
        <div class="EditTaskhHeader">
          <h3>Final Card</h3>
        </div>
        <div class="EditTaskCheckbox">
          <input
            type="checkbox"
            class="largeCheckmark"
            id="SortOptions.FinalCard"
            :disabled="FinalCardEnabled"
            v-model="FinalCard"
            @change="check($event)"
          />
        </div>
      </div>
      <div class="checkBoxOverride" v-if="FinalCard">
        <div class="EditTaskhHeader">
          <h3>Worksheet</h3>
        </div>
        <div class="EditTaskCheckbox">
          <input
            type="checkbox"
            class="largeCheckmark"
            id="SortOptions.Worksheet"
            :disabled="WorksheetEnabled"
            v-model="Worksheet"
            @change="check($event)"
          />
        </div>
      </div>
      <button @click="cancel()">Cancel</button>
      <button @click="save()"> Save </button>
    </div>
  </form>
</template>

<script>
import { ref } from "@vue/reactivity";
import {useRoute, useRouter} from "vue-router"
import { projectFirestore } from '../../../firebase/config';
export default {
  props: ['groupID', 'taskIndex', 'groupName', 'modules'],

  setup(props) {
    const CardSort = ref(false);
    const TopFive = ref(false);
    const FinalCard = ref(false);
    const Worksheet = ref(false);

    const CardSortEnabled = ref(false);
    const TopFiveEnabled = ref(false);
    const FinalCardEnabled = ref(false);
    const WorksheetEnabled = ref(false);

    const date = ref('')

    const router = useRouter();
    const route = useRoute();

    if(route.params.modules.length > 0)
    {
      CardSort.value =  route.params.modules[0]
      TopFive.value =   route.params.modules[1]
      FinalCard.value = route.params.modules[2]
      Worksheet.value = route.params.modules[3]
    }

    if(route.params.startDate !== ''){
      date.value = route.params.startDate
    }
    const check = (evt) => {

      if (!CardSort.value) {
        TopFive.value = false;
        FinalCard.value = false;
        Worksheet.value = false;
      }

      if (!TopFive.value) {
        FinalCard.value = false;
        Worksheet.value = false;
      }

      if (!FinalCard.value) {
        Worksheet.value = false;
      }

      validateEnabledStatus();
    };

    const validateEnabledStatus = () => {
        CardSortEnabled.value = false
        TopFiveEnabled.value = false
        FinalCardEnabled.value = false
        WorksheetEnabled.value = false

        if(Worksheet.value == 'true'){
            CardSortEnabled.value = true;
            TopFiveEnabled.value = true
            FinalCardEnabled.value = true
          return;
        }

        if(FinalCard.value == 'true')
        {
            CardSortEnabled.value = true;
            TopFiveEnabled.value = true
            return;
        }

        if(TopFive.value == 'true'){
            CardSortEnabled.value = true;
            return;
        }
    };
    validateEnabledStatus();
    check();

    const cancel = () => 
    {
        router.push({name:'ManageTasks', groupID: props.groupID, taskIndex: props.taskIndex, taskName: props.groupName})
    }

    const save = () => 
    {
        projectFirestore.collection('groups').doc(route.params.groupID).get().then(res =>{
          let data = res.data()
          

          projectFirestore.collection('GroupMeta').doc(data['groupMetaLocation']).get()
          .then(result =>{
            let taskData = result.data()
            taskData.TaskMeta[route.params.taskIndex] = 
            {
              Modules: [CardSort.value, TopFive.value, FinalCard.value, Worksheet.value],
              StartDate: date.value.toString()
            }



            console.log(data)
            for (let index = 0; index < data.GroupUsers.length; index++) {
              const element =  data.GroupUsers[index];

              if(!element)
              {
                continue;
              }

              projectFirestore.collection("users").doc(element).get().then(userInfo => 
              {
                  let userData = userInfo.data();
                  projectFirestore.collection("SessionData").doc(userData.SessionMeta).get().then(sessionMeta => 
                  {
                     let sessionMetaData = sessionMeta.data();
                     
                     sessionMetaData.SessionStates[props.taskIndex].SessionModules = 
                     {
                        CardFilter: TopFive.value,
                        CardFinal: FinalCard.value,
                        CardSort: CardSort.value,
                        Worksheet: Worksheet.value
                     }
                    console.log(sessionMetaData.SessionStates[props.taskIndex].SessionStartDate)
                     sessionMetaData.SessionStates[props.taskIndex].SessionStartDate = date.value.toString();

                     projectFirestore.collection("SessionData").doc(userData.SessionMeta).update(
                       sessionMetaData
                     )
                  })
              })
            }
            projectFirestore.collection('GroupMeta').doc(result.id).update(taskData)
             router.push({name:'ManageTasks', groupID: props.groupID, taskIndex: props.taskIndex, taskName: props.groupName})
          })
        })
    }
    return {
      CardSort,
      TopFive,
      FinalCard,
      Worksheet,
      CardSortEnabled,
      TopFiveEnabled,
      WorksheetEnabled,
      FinalCardEnabled,
      date,
      check,
      cancel,
      save
    };
  },
};
</script>

<style>
.taskEditCSS {
  width: 100%;
  padding: 10px;
  outline: none;
  color: #2f4858;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
}

.taskEditCSS input {
  width: 90%;
  padding: 15px;
  border-radius: 20px;
  border: 1px solid #eee;
  outline: none;
  color: #999;
  margin: 20px auto;
}

.taskEditCSS button {
  margin: 20px auto;
}

.taskEditCSS h3 {
  text-align: left;
  padding: 5px 20px;
}

.taskEditCSS .titleHeading {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px;
}

.taskEditCSS select {
  width: 90%;
  padding: 15px;
  border-radius: 20px;
  border: 1px solid #eee;
  outline: none;
  color: #2f4858;
  margin: 20px auto;
}

.taskEditCSS option {
  width: 90%;
  padding: 15px;
  border-radius: 20px;
  border: 1px solid #eee;
  outline: none;
  color: #2f4858;
  margin: 20px auto;
}

.TaskModules {
  cursor: pointer;
  width: 100%;
}

.checkBoxOverride {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: space-between;
  cursor: pointer;
}

input.largeCheckmark {
  width: 20px;
  height: 20px;
}
</style>