import { createStore } from 'vuex'
import { projectAuth } from "../firebase/config"
import router from '../router'
import getUser from '../composables/getUser'
import useLogout from '../composables/useLogout'

const store = createStore({
    state: {
        user:
        {
            key: '',
            priv: '',
            firstName: '',
            lastName: '',
            email: '',
            orgUID: '',
            groups: []
        },
        sessionMeta: 
        {
            sessionMetaDataLocation: '',
            currentSessionIndex: '',
            activeDataFile: '',
            currentCardPack: '',
            currentSessionProgress: 0
        },
        pileData:
        {
            notImportantPile: [],
            importantPile: [],
            veryImportantPile: []
        },
        topFiveData: 
        {
            topFiveSelected: []
        },
        lastChoice: 
        {
            finalCard: null
        },
        surveyAnswers: 
        {
            barriers: '',
            goalActionOne: '',
            goalActionTwo: '',
            goalActionThree: '',
            solution: '',
        }
    },
    mutations:
    {
        storeCurrentUser(state, {uid, priv, firstName, lastName, email, orgUID, groups})
        {
            state.user.key = uid;
            state.user.priv = priv;
            state.user.firstName = firstName;
            state.user.lastName = lastName;
            state.user.email = email;
            state.user.orgUID = orgUID;
            state.user.groups = groups;

            console.log("commited")
        },
        storeSessionData(state, {sessionMeta})
        {
            state.sessionMeta.sessionMetaDataLocation = sessionMeta
           
            
        },
        storeActiveDataFile(state, {dataFileLink, cardPack, sessionIndex})
        {
            state.sessionMeta.activeDataFile = dataFileLink
            state.sessionMeta.currentCardPack = cardPack
            state.sessionMeta.currentSessionIndex = sessionIndex
        },
        logoutCurrentUser(state)
        {
            state.user.key = null
            state.user.priv = null
            state.user.firstName = null;
            state.user.lastName = null;
            state.user.email = null;
            state.user.orgUID = null;
            state.user.groups = null;
        },
        storePileData(state, {nimp, vimp, imp})
        {
            state.pileData.notImportantPile = nimp;
            state.pileData.importantPile = imp;
            state.pileData.veryImportantPile = vimp

            
            
            
        },
        storeTopFive(state, {topFiveData})
        {
            state.topFiveData.topFiveSelected = topFiveData
            
        },
        storeFinal(state, {finalCard})
        {
            state.lastChoice.finalCard = finalCard;
        },
        storeBarriers(state, {barriers})
        {
            state.surveyAnswers.barriers = barriers
        },
        storeGoals (state, {goalsOne, goalsTwo, goalsThree})
        {   
            state.surveyAnswers.goalActionOne = goalsOne
            state.surveyAnswers.goalActionTwo = goalsTwo
            state.surveyAnswers.goalActionThree = goalsThree
        },
        storeSolutions(state, {solutions})
        {
            state.surveyAnswers.solution = solutions
        },
        storeCurrentSessionProgress(state, {progress})
        {
            
            state.sessionMeta.currentSessionProgress = progress
        }

    }
})

export default store