<template>
  <Navbar />

  <div class="taskContainer" v-if="welcome && dataReady">
    <h2>{{taskName}}</h2>
    <div class="welcomePanel" v-if="viewMode==='false'">
      
      <div class="textContent">
          
        <h1>Welcome to the values card sort task</h1>
        <TaskDescription :taskDescription="descText"/>
      </div>
      <button @click="moveToInstructionsOne">Next</button>
    </div>

    <div class="welcomePanel" v-if="viewMode ==='true' && dataReady">
      <div class="textContent">
        <h1>Welcome to the values sort task - Viewing mode</h1>
        <p>In this mode you can review answers you've inputed into previous sessions. 
        </p>
        <p>You cannot edit any answers already made.</p>
      </div>
      <button @click="moveToViewMode">Next</button>
    </div>
  </div>

  <div v-if="instructionsOne">
    <TaskOneInstructions @showContinueButton="progressTutorial" />
  </div>

  <div v-if="taskPartOne">
    <TaskOneMain @moveToTaskTwo="moveToTaskTwo" :viewMode="viewMode"/>
  </div>

  <div v-if="taskPartTwo">
    <TaskOnePileSelect @moveToTopOne="moveToTopCards" :viewMode="viewMode"/>
  </div>

  <div v-if="taskPartThree">
      <TaskThreeSelectTopOne @finalDisplay="MoveToFinalScreen" :viewMode="viewMode" :finalSlot="finalStop"/>
  </div>

  <div v-if="taskPartFour">
      <TaskFinalCard :viewMode="viewMode"/>
  </div>
</template>

<script>
import Sortable from "sortablejs";

import { ref } from "@vue/reactivity";
import { nextTick, onBeforeMount } from "@vue/runtime-core";
import { useRoute, useRouter } from 'vue-router'
import {projectFirestore} from "../../firebase/config"
import store from '../../store/store'


import Navbar from "../../components/Navbar.vue";
import TaskDescription from "../../components/ClientComps/TaskComps/TaskOne/TaskDescription.vue"
import TaskOneInstructions from "../../components/ClientComps/TaskComps/TaskOne/TaskOneInstructions.vue";
import TaskOneMain from "../../components/ClientComps/TaskComps/TaskOne/TaskOneMain.vue";
import TaskOnePileSelect from "../../components/ClientComps/TaskComps/TaskOne/TaskOnePileSelect.vue";
import TaskThreeSelectTopOne from "../../components/ClientComps/TaskComps/TaskOne/TaskThreeSelectTopOne.vue"
import TaskFinalCard from '../../components/ClientComps/TaskComps/TaskOne/TaskFinalCard.vue'


export default {
  components: { Navbar, TaskOneInstructions, TaskOneMain, TaskOnePileSelect , TaskThreeSelectTopOne, TaskFinalCard, TaskDescription},
  props: ['sessionMetaDataFile', 'dataFile', 'cardPack', 'viewMode', 'groupMeta'],
  setup(props) 
  {
    const topic = ref("INSERT TOPIC NAME HERE");
    const welcome = ref(false);
    const instructionsOne = ref(false);
    const taskPartOne = ref(false);
    const taskPartTwo = ref(false);
    const taskPartThree = ref(false);
    const taskPartFour = ref(false);
    const route = useRoute()
    const router = useRouter()
    const viewMode = ref(false)

    const taskName = ref('NA')
    const descText = ref('NA')

    const dataReady = ref(false)

    const groupMetaData = ref(route.params.groupMeta)
    const finalStop = ref(false)
    

    if(!route.params.sessionMetaDataFile){
      router.push('/')
    }
    
   
    onBeforeMount(() => {
      viewMode.value = route.params.viewMode
       
      if(route.params.viewMode === 'false')
      {
        
        switch(store.state.sessionMeta.currentSessionProgress){
          case 0:
            welcome.value = true
            break;
          case 1:
            taskPartOne.value = true
            break;
          case 2:
            taskPartTwo.value = true;
            break
          case 3:
            taskPartThree.value = true;
            break;
          case 4: 
            taskPartFour.value = true;
            break;
          case 5:
                if(!groupMetaData.value[3])
                {
                  router.push('/')
                return;
                }
            router.push({name: 'WorkSheet', params: {routeValid: true, viewMode: viewMode.value}})
            break;
         default:
           welcome.value = true
           break
        }
      }
      else{
        welcome.value = true
      }
      
      if(store.state.sessionMeta.currentCardPack.length === 0)
      {
        router.push('/')
        return;
      }
                projectFirestore
        .collection("CardTasks")
        .doc(store.state.sessionMeta.currentCardPack)
        .get()
        .then((res) => {
          let data = res.data();
          console.log(data)
          //
          taskName.value = data.DisplayName
          descText.value = data.descriptionText
          nextTick(() => {
            dataReady.value = true
          })
        });
    })

    const moveToInstructionsOne = () => {
      if(!groupMetaData.value[0])
      {
        FinalizeSaveState()
        router.push('/')
        return;
      }
      welcome.value = false;
      nextTick(function () {
        instructionsOne.value = true;
      });
    };

    const moveToViewMode = () => {
      if(groupMetaData.value[0] === 'false')
      {
        FinalizeSaveState()
        router.push('/')
        return;
      }
      welcome.value = false
      nextTick(taskPartOne.value = true)
    }
    const progressTutorial = () => {
      instructionsOne.value = false;
      nextTick(function () {
        taskPartOne.value = true;
      });
    };

    const moveToTaskTwo = () => {
      if(groupMetaData.value[1] === 'false')
      {
        FinalizeSaveState()
        router.push('/')
        return;
      }
      taskPartOne.value = false;
      nextTick(() => {
        taskPartTwo.value = true;
      });
    };

    const moveToTopCards = () => 
    {
      if(groupMetaData.value[2] === 'false')
      {
        FinalizeSaveState()
        finalStop.value = true;
         taskPartTwo.value = false;
        nextTick(()=> {
            taskPartThree.value = true;
        })
        
        return;
      }
        taskPartTwo.value = false;
        nextTick(()=> {
            taskPartThree.value = true;
        })
    };

    const MoveToFinalScreen = () => {
      taskPartThree.value = false;
      nextTick(() => {
        taskPartFour.value = true;
      })
    }

    const FinalizeSaveState = () => 
    {
      console.log('finalizing')
       projectFirestore
        .collection("SessionData")
        .doc(store.state.sessionMeta.sessionMetaDataLocation)
        .get()
        .then((res) => {
          let data = res.data().SessionStates;
          data[store.state.sessionMeta.currentSessionIndex].SessionState = 2;
          if(!data[store.state.sessionMeta.currentSessionIndex].completedDate)
          {
            data[store.state.sessionMeta.currentSessionIndex].completedDate = new Date();
          }
         
          projectFirestore
            .collection("SessionData")
            .doc(store.state.sessionMeta.sessionMetaDataLocation)
            .update({
              SessionStates: data,
            });


        });
    }
    return {
      topic,
      welcome,
      moveToInstructionsOne,
      instructionsOne,
      progressTutorial,
      taskPartOne,
      moveToTaskTwo,
      moveToTaskTwo,
      taskPartTwo,
      moveToTopCards,
      taskPartThree,
      taskPartFour,
      MoveToFinalScreen,
      viewMode,
      taskName,
      descText,
      dataReady,
      moveToViewMode,
      finalStop
    };
  },
};

function isEmptyOrSpaces(str){
    return str === null || str.match(/^ *$/) !== null;
}
</script>

<style>
.taskContainer {
  width: 90%;
  max-width: 980px;
  min-height: 400px;
  margin: 80px auto;
  border-radius: 20px;
  box-shadow: 2px 4px 6px rgba(28, 6, 49, 0.1);
  background: white;
}

.taskContainer .welcomePanel {
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.taskContainer .welcomePanel h1 {
  padding: 20px 10px;
}

.taskContainer h2 
{
  padding: 20px 30px;
  text-align: left;
  font-size: 20px;
}
.taskContainer .welcomePanel p {
  font-size: 20px;
  padding: 10px 10px;
  font-weight: 400;
}

.taskContainer .welcomePanel button {
  margin: 40px;
  width: 300px;
}
</style>