
<template>
  <Navbar />
  <SideNav :selectedView="{'id': 0}" />
  
  <div class="content" >
    <AddGroupContainer />
  </div>
</template>

<script>
import Navbar from "../../components/Navbar.vue";
import SideNav from "../../components/AdminComps/SideNav.vue";
import AddGroupContainer from "../../components/AddGroupContainer.vue";
import ManageUsersPanel from "../../components/AdminComps/UsersGroup/ManageUsersPanel.vue"

import { useRouter } from "vue-router";
import getUser from "../../composables/getUser";
import { ref, watch } from "@vue/runtime-core";


import generateUserDataBundle from '../../composables/generateUserDataBundle'

import store from '../../store/store';

export default {
  components: { Navbar, SideNav, AddGroupContainer, ManageUsersPanel },

  setup(props, context) {
    const iconClicked = ref([true, false, false]);
    const toggleViews = (item) => {
      for (let index = 0; index < iconClicked.value.length; index++) {
        iconClicked.value[index] = false;
      }

      iconClicked.value[item] = true;
    };

    const {generateGroupDataBundle, error} = generateUserDataBundle();

    //generateGroupDataBundle('ZaE9As3wT7MdjvgVjO6jWHCzSMA2')
    return { toggleViews, iconClicked };
  },
};
</script>

<style>
.content {
  display: flex;
  margin: 10px 100px;
}
</style>