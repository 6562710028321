import { createRouter, createWebHistory, useRouter } from 'vue-router'
import { projectAuth } from '../firebase/config'
import { ref } from '@vue/runtime-dom'

import store from '../store/store'
import getUser from '../composables/getUser'

import Welcome from '../views/Welcome.vue'
import ClientHome from '../views/Clientviews/ClientHome.vue'
import AdminHome from '../views/AdminViews/AdminHome.vue'
import TaskView from '../views/Clientviews/TaskView.vue'
import SurveyView from '../views/Clientviews/SurveyView.vue'
import EditGroup from '../views/AdminViews/EditGroup.vue'
import ManageUsers from '../views/AdminViews/ManageUsers.vue'
import MoniterGroup from '../views/AdminViews/MoniterGroup.vue'
import ManageTasks from '../views/AdminViews/ManageTasks.vue'
import EditTask from '../views/AdminViews/EditTask.vue'
import AssignUsersPanel from '../views/AdminViews/AssignUsersPanel.vue'
import AssignViewersPanel from '../views/AdminViews/AssignViewersPanel.vue'
import GroupViewPage from '../views/AdminViews/GroupViewPage.vue'
import DataView from '../views/AdminViews/DataView.vue'
//auth guard

const routes =
	[
		{
			path: '/',
			name: 'Welcome',
			component: Welcome,
			meta:
			{
				auth: false
			}
		},
		{
			path: '/ClientHome',
			name: 'ClientHome',
			component: ClientHome,
			meta:
			{
				roles: ['user']
			}

		},
		{
			path: '/AdminHome',
			name: 'AdminHome',
			component: AdminHome,
			meta:
			{
				roles: ['admin']
			}

		},
		{
			path: '/Task',
			name: 'TaskView',
			component: TaskView,
			meta: 
			{
				roles: ['user']
			},
			props: 
			{
				sessionMetaDataFile: '',
				dataFile: '',
				cardPack: '',
				groupMeta:'',
				viewMode: Boolean
			}
		},
		{
			path: '/Worksheet',
			name: 'WorkSheet',
			component: SurveyView,
			props: 
			{
				routeValid: Boolean,
				viewMode: ''
			},
			meta: 
			{
				roles: ['user']
			}
		},
		{
			path: '/AdminHome/EditGroup/:groupID',
			name: 'EditGroup',
			component: EditGroup,
			meta: 
			{
				roles: ['admin']
			},
			props: 
			{
				groupID: ''
			}
		},
		{
			path: '/ManageUsers',
			name: 'ManageUsers',
			component: ManageUsers,
			meta: 
			{
				roles: ['admin'] 
			}
		},
		{
			path: '/monitor',
			name: 'MoniterGroup',
			component: MoniterGroup,
			meta: 
			{
				roles: ['admin', 'viewer'] 
			}
		},
		{
			path:'/AdminHome/ManageTasks/:groupID',
			name: 'ManageTasks',
			component: ManageTasks,
			meta: 
			{
				roles: ['admin']
			},
			props: 
			{
				groupID: ''
			}
		},
		{
			path:'/AdminHome/ManageTasks/:groupID/edit-task/:taskName/:taskIndex',
			name: 'EditTask',
			component: EditTask,
			meta: 
			{
				roles: ['admin']
			},
			props: 
			{
				groupID: '',
				taskName: '',
				taskIndex: 0,
				modules: '',
				startDate: ''
			}
		},
		{
			path:'/AdminHome/ManageTasks/:groupID/assignUsers/',
			name: 'AssignUsers',
			component: AssignUsersPanel,
			meta: 
			{
				roles:['admin']
			},
			props:
			{
				groupID: '',
				currentUsers: ''
			}
		},
		{
			path:'/AdminHome/ManageTasks/:groupID/assignViewers/',
			name: 'AssignViewers',
			component: AssignViewersPanel,
			meta: 
			{
				roles:['admin']
			},
			props:
			{
				groupID: '',
				currentViewers: ''
			}
		},
		{
			path:'/monitor/:groupID',
			name: 'GroupView',
			component: GroupViewPage,
			meta: 
			{
				roles:['admin', 'viewer']
			},
			props:
			{
				groupID: ''
			}
		},
		
		{
			path:'/dataview',
			name:'DataView',
			component: DataView,
			meta:
			{
				roles:['admin', 'viewer']
			}
		}
	]


const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

router.beforeEach((to, from, next) => {
	
	let userRole = store.state.user.priv
	console.log(userRole)
	if(to.fullPath === "/")
	{
		if(userRole)
		{
			if(userRole === 'user'){
				
				return next('/ClientHome')
			}
			else if(userRole === 'admin'){
				
				return next('/AdminHome')
			}
			else if(userRole === 'viewer')
			{
				return next('/monitor')
			}
			else{
				return next('/')
			}
		}
		else{
		return next()
		}
	}

	if(to.meta.roles.includes('any')){
		return next()
	}
	
	


	if(userRole){
		if(to.meta.roles.includes(userRole)){
			return next()
		}
		else
		{
			if(userRole === 'user'){
				
				return next('/ClientHome')
			}
			else if(userRole === 'admin'){
				
				return next('/AdminHome')
			}
			else if(userRole === 'viewer')
			{
				return next('/monitor')
			}
			else{
				return next('/')
			}
		}
	}
	else{
		
		return next('/')
	}

});

export default router
