<template>
  <div class="listContainer">
    <div class="groupitem">
      <div class="textContent">
        <h2>{{ groupName }}</h2>
        <h3>Week {{ groupWeek }}</h3>
      </div>
      <span class="material-icons-round arrow"> chevron_right </span>
    </div>
    <hr />
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
export default {
  props: ["data"],

  setup(props) {
    const groupName = ref("");
    const groupWeek = ref("");
    const notes = ref("");
    const numPart = ref("");
    const createdAt = ref("");

    const fillData = () => {
      if (props.data) {
        const data = props.data;
        groupName.value = data.groupName;
        groupWeek.value = data.groupWee;
        notes.value = data.notes;
        numPart.value = data.numParticipants;
        createdAt.value = data.createdAt.toDate();
      }
    };

    fillData();
    return { groupName, groupWeek, notes, numPart, createdAt };
  },
};
</script>

<style>
.listContainer .groupitem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 10px;
}
.listContainer .groupitem .textContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-items: center;
  justify-content: center;
}

.listContainer .arrow {
  font-size: 20px;
  align-items: center;
}
.listContainer h2 {
  font-size: 25px;
}

.listContainer h3 {
  font-size: 15px;
  padding: 3px 2px;
}
.listContainer hr {
  width: 95%;
  margin: 0px auto;
  background-color: #ccc;
}

.listContainer:hover {
  cursor: pointer;
  color: #f26522;
}
.listContainer {
  display: block;
  padding: 10px;
}
</style>