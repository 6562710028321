<template>
  <nav v-if="displayName">
    <div class="titleHeading">
      

      <img
        src="../assets/logo.png"
        alt=""
        width="45"
        height="45"
        class="innerFlex logoclick"
        @click="routeHome"
      />
      <span class="material-icons-round homeIcon" @click="routeHome"> home </span>
      <p class="innerFlex">Welcome back {{ displayName }}.</p>
    </div>
    <button @click="handleClick">Logout</button>
  </nav>
</template>

<script>
import useLogout from "../composables/useLogout";
import { useRouter } from "vue-router";
import { ref } from "@vue/reactivity";
import store from "../store/store";
export default {
  setup() {
    const { logout, error } = useLogout();
    const displayName = ref(
      store.state.user.firstName + " " + store.state.user.lastName
    );
    const router = useRouter();
    const handleClick = async () => {
      console.log("click")
      await logout();
      if (!error.value) {
        
        router.push({ name: "Welcome" });
      }
      else
      {
        console.log(error.value)
      }
    };

    const routeHome = () => 
    {
        let credentials = store.state.user.priv
        if(credentials === 'admin' || credentials === 'viewer'){
            router.push({name: 'AdminHome'})
        }
        else if (credentials === 'user'){
            router.push({name: 'ClientHome'})
        }
        else {
            router.push({name: 'Welcome'})
        }
    }
    return { handleClick, displayName, routeHome };
  },
};
</script>

<style>
nav {
  padding: 14.5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #2f4858;
}

nav p {
  margin: 2px auto;
  font-size: 16px;
  color: white;
}

nav .titleHeading {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 0px 0px;
}

nav .titleHeading .homeIcon{
    color: #F26522;
    cursor: pointer;
    font-size: 40px;
}

nav .titleHeading .homeIcon:hover{
    color: #89D1C6;
}
nav .innerFlex {
  margin: 0px 11px;
}

nav .innerFlex.logoclick 
{
  cursor: pointer;
}
</style>