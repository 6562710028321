import { firebaseStorage, projectFirestore } from "../firebase/config";
import {ref} from "@vue/reactivity"

const error = ref('null')
const fileData = require('../../data.json')

const createImageDB = async (imgBin, imgMaxNum) => 
{
  //
  let cardDataOne = fileData.cardDataOne
  let cardDataTwo = fileData.cardDataTwo
  let cardDataThree = fileData.cardDataThree
  let cardDataFour = fileData.cardDataFour
  let cardDataFive = fileData.cardDataFive

  const promisesDataOne = cardDataFive.map((element, index) => {
      return firebaseStorage.ref('CardTaskFive').child(index + '.jpg').getDownloadURL();

  })

  Promise.all(promisesDataOne).then((data) => {
      

      for (let index = 0; index < data.length; index++) {
          const item = data[index];
          cardDataFive[index].cardURL = item;
      }

      projectFirestore.collection('CardTasks').doc('TaskFive').set({cardDataFive})
  })
}

const generateImageCache = () => {

    return {createImageDB, error}
}


export default generateImageCache