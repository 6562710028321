<template>
  <div class="surveyMonitoringContent">
      <div class="textBoxes" v-if="surveyAnswers">
          <div>
              <h3>Goal One</h3>
              <textarea :disabled="true" v-model="surveyAnswers.goalActionOne"/>
          </div>
            <div>
              <h3>Goal Two</h3>
              <textarea :disabled="true" v-model="surveyAnswers.goalActionTwo"/>
          </div>
            <div>
              <h3>Goal Three</h3>
              <textarea :disabled="true" v-model="surveyAnswers.goalActionThree" />
          </div>
            <div>
              <h3>Barriers</h3>
              <textarea :disabled="true"  v-model="surveyAnswers.barriers" />
          </div>
            <div>
              <h3>Strategies</h3>
              <textarea :disabled="true" v-model="surveyAnswers.resolution" />
          </div>
        <div>
              <h3>User's Top Value</h3>
              <Cards :imgURL="finalCard.cardURL"/>
          </div>
      </div>
      <div v-else>
          <h1>User Has No Survey Data yet.</h1>
      </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import Cards from "../../ClientComps/TaskComps/Cards.vue"
export default 
{
    components: {Cards},
    props: ["userData"],
    setup(props)
    {
        const surveyAnswers = ref(props.userData.data.surveyResponses)
        const finalCard = ref(props.userData.data.finalCard)
        
        
        return {surveyAnswers, finalCard}
    }
}
</script>

<style>
.surveyMonitoringContent .textBoxes textArea
{
    margin: 20px;
}
.textBoxes
{
    display: flex;
    flex-wrap: wrap
}

.surveyMonitoringContent
{
    margin: 14% auto;
}
</style>