<template>
  <div class="listContainerItem">
 
      
      <h3>{{ firstName }} - {{ lastName }}</h3>

    <hr>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
export default {
  props: ["FirstName", "LastName", "SaveState", "OnlineState"],
  setup(props) {
    const firstName = ref(capitalizeFirstLetter(props.FirstName));
    const lastName = ref(capitalizeFirstLetter(props.LastName));
    const iconIndex = ref(0)
    const onlineState = ref(false)
    
    if(props.SaveState)
    {
      iconIndex.value = parseInt(props.SaveState)
    }

    if(typeof props.OnlineState != 'undefined')
    {
      onlineState.value = props.OnlineState
      
    }

    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return { firstName, lastName,iconIndex, onlineState };
  },
};
</script>

<style>
.listContainerItem {
  text-align: left;
  margin: 20px 20px;
  padding: 10px;
}
.listContainerItem h3 {
  font-size: 22px;
}

.inProgress
{
  color: #DC6F2A
}
</style>