<template>
  <form class="addParticipantForm" @submit.prevent>
      <h2>Group Name:</h2>
      <input type="text" required placeholder="Group Name..." v-model="groupName">
      <h2>Notes:</h2>
      <textarea name="notesArea" id="" cols="30" rows="10" 
      class="textBox" placeholder="group notes here..." v-model="notes"></textarea>
        <div class="buttonContainer">
          <button formnovalidate @click="cancel">Cancel</button>
          <button @click="validate">Add Group</button>
      </div>
  </form>
 
</template>

<script>
import { ref } from '@vue/reactivity'
import addGroup from '../../../composables/addGroup'
import {useRouter} from 'vue-router'
export default {
    setup(props, context)
    {
        const groupName = ref('')
        const date = ref('')
        const numberOfParticipants = ref(1)
        const notes  = ref('')

        const {error, newGroup} = addGroup()
        const router = useRouter()

        const cancel = () => {
            groupName.value = ""
            date.value = ""
            numberOfParticipants.value = 1
            notes.value = ""
            context.emit("cancel")
        }
        const validate = async () => {

            await newGroup(groupName.value, date.value, numberOfParticipants.value, notes.value).then(() => {
                     
                groupName.value = null
                date.value = null
                numberOfParticipants.value = 1
                notes.value = null
            })
       
            context.emit("cancel")
        }
        return {groupName, date, numberOfParticipants, notes, cancel, validate}
    }
}
</script>

<style>
.addParticipantForm {
    display: block;
}
.addParticipantForm .buttonContainer{
    display: flex;
    align-items: center;
    justify-content:  space-evenly;
    margin: 40px auto;

}
.addParticipantForm h2{
    text-align: left;
    margin: 5px 30px;
    font-size: 22px;
    font-weight: 300;
}
.addParticipantForm input{
    border-radius: 15px;
    padding: 10px 10px;
    border: 1px solid #eee;
    width: 90%;
    margin: 10px auto;
}

.addParticipantForm .textBox{
    border-radius: 15px;
    padding: 10px 10px;
    border: 1px solid #eee;
    width: 90%;
    height: 100px;
    margin: 10px auto;
    text-align: left;
    vertical-align: top;
}
</style>