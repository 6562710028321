import { ref } from "vue"
import { projectAuth, projectFirestore, timestamp } from "../firebase/config"
import store from "../store/store"


const error = ref(null)

const newGroup = async (groupName, startDate, numberOfParticipants, notes) => 
{
    const groupData = {
        GroupUsers: [""],
        GroupViewers:[""],
        GroupAdmins:[store.state.user.key],
        groupName:groupName,
        groupOrg: store.state.user.orgUID,
        groupWeek: 1,
        notes: notes,
        createdAt: timestamp(),    
        groupMetaLocation: ''
    }

    try{
    await projectFirestore.collection('groups').add(groupData).then((docref) => {
        
        let groups = store.state.user.groups;

        if(!groups || groups.length == 0 || groups[0] == "")
        {
            groups = [docref.id]
        }
        else{
            groups.push(docref.id)
        }

        projectFirestore.collection('GroupMeta').add({
            TaskMeta:[
                {
                    StartDate: '',
                    Modules:[true, true, false, false]
                },
                {
                    StartDate: '',
                    Modules:[true, true, false, false]
                },
                {
                    StartDate: '',
                    Modules:[true, true, true, true]
                },
                {
                    StartDate: '',
                    Modules:[true, true, true, true]
                },
                {
                    StartDate: '',
                    Modules:[true, true, true, true]
                }
            ]
        }).then((docReference) => {
            console.log(docReference.id)
            projectFirestore.collection('groups').doc(docref.id).update({
                "groupMetaLocation": docReference.id
            })
        })

        projectFirestore.collection('admin').doc(store.state.user.key).update({
            "groups": groups
        })
        error.value = null
    })
    }
    catch(err){
        error.value = err
        console.log(err)
    }

}

const addGroup = () => {
    return {error, newGroup}
}

export default addGroup