<template>
    <div class="container selectGroup">
    <h2>Select group for data export</h2>
    <form @submit.prevent="downloadOneGroupData" class="selectGroupForm">
      <h3>Group</h3>
      <select name="group" id="groupID" v-model="groupSelection">
        <option
          v-for="option in groupData"
          :key="option.groupID"
          :value="option.groupID"
        >
          {{ option.element.groupName }}
        </option>
      </select>
      <button>Download</button>
    </form>

    <button @click="downloadAllGroupData">Download All</button>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { onBeforeMount } from '@vue/runtime-core';
import store from '../../store/store';
import { projectFirestore } from '../../firebase/config';
import { async } from 'q';

export default 
{
    setup(props)
    {
        const groupData = ref([]);
        const groupSelection = ref("")
        
        onBeforeMount(() => 
        {
            if(store.state.user.priv === "admin" || store.state.user.priv === "viewer")
            {
                console.log(store.state.user.groups)
                if(store.state.user.groups.length > 0 || store.state.user.groups[0] !== "")
                {
                    const promises = store.state.user.groups.map((id) => 
                    {
                        return projectFirestore.collection("groups").doc(id).get();
                    })

                    Promise.all(promises).then(data => 
                    {
                        groupData.value = [];
                        for (let index = 0; index < data.length; index++) {
                            const element = data[index].data();
                            const groupElement = 
                            {
                                groupID: store.state.user.groups[index],
                                element
                            }

                            groupData.value.push(groupElement)
                            
                        }
                    })
                }
            }
        })

        const downloadAllGroupData = async () => 
        {
            let builtStr = "firstName,lastName,date,finalCard,TopFive1,TopFive2,TopFive3,TopFive4,TopFive5,nimp,imp,vimp,GA1,GA2,GA3,Barriers,Solution\n"
            for(let i = 0; i < groupData.value.length; i++)
            {
                let {newStr, selectedGroupData} = await handleSubmit(groupData.value[i].groupID, false)
                builtStr += newStr
            }
            await saveFile(builtStr, {groupID: "all", element: {groupName: "All Groups"}})
        }

        const downloadOneGroupData = async () => 
        {
            const {newStr, selectedGroupData} = await handleSubmit(groupSelection.value, true);
            saveFile(newStr, selectedGroupData);
        }

        const saveFile = async (data, selectedGroupData) => 
        {
            var filename = 'export_' + selectedGroupData.groupID + '_' +selectedGroupData.element.groupName.replace(/\s/g, '-')+"_"+ new Date().toLocaleDateString() + '.csv';
            const anchor = document.createElement('a');
            anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(data);
            anchor.target = '_blank';
            anchor.download = filename;
            anchor.click();
            console.log(filename)
        }
        const handleSubmit = async (group, hasHeader) => 
        {

            let stringArray = [];

            if(hasHeader == true)
            {
                stringArray = [["firstName,lastName,date,finalCard,TopFive1,TopFive2,TopFive3,TopFive4,TopFive5,nimp,imp,vimp,GA1,GA2,GA3,Barriers,Solution"]]
            }
            else
            {
                stringArray = [];
            }

            let selectedGroupData = -1;

            for (let index = 0; index < groupData.value.length; index++) {
                const element = groupData.value[index];
                if(group === element.groupID)
                {
                    selectedGroupData = element;
                    break;
                }
            }

            if(selectedGroupData === -1)
            {
                console.log("something went wrong")
                return
            }

            let groupUsers = selectedGroupData.element.GroupUsers;
            
            if(groupUsers[0] === "" || groupUsers === undefined || groupUsers.length === 0)
            {
                return;
            }

            for (let index = 0; index < groupUsers.length; index++) {
                const element = groupUsers[index];
                let userData = await projectFirestore.collection("users").doc(element).get();
                let data = userData.data();
                let sessionMetaData = data.SessionMeta;

                let sessionDataFetch = await projectFirestore.collection("SessionData").doc(sessionMetaData).get();
                let resSessionData = sessionDataFetch.data();

                let docData = await projectFirestore.collection("SessionData").doc(sessionMetaData).collection("data").get();
                let docArray = []

                for (let index = 0; index < resSessionData.DataLinks.length; index++) {
                    const element = resSessionData.DataLinks[index];
                    for (let y = 0; y < docData.docs.length; y++) {
                        const doc = docData.docs[y];
                        const docId = docData.docs[y].id;
                        if(docId === element)
                        {
                            docArray.push({completed:resSessionData.SessionStates[index].completedDate, task:resSessionData.SessionStates[index].CardPack, data: doc.data()})
                            break;
                        }
                    }
                }

                stringArray.push(formatDataString(data.FirstName, data.LastName, data.UID, docArray))
            }

            stringArray = stringArray.flat();
            let newStr = ""
            for (let index = 0; index < stringArray.length; index++) {
                const element = stringArray[index];
                newStr += `${element}\n`
            }

            return {newStr, selectedGroupData};
        }

        const formatDataString = (firstName, lastName, UID, data) => 
        {
            let strings = []

            for (let index = 0; index < data.length; index++) {
                const element = data[index];
                let substring = formatSubString(element)
                strings.push(`${firstName},${lastName},${substring}`)
            }

            return strings;
        }

        const formatSubString = (data) => 
        {
            let topFive = data.data.Top5;
            let nimp = data.data.nimp;
            let imp = data.data.imp;
            let vimp = data.data.vimp;
            let surveyResponses = data.data.surveyResponses
            
            let completedDate = data.completed ? new Date(data.completed * 1000) : "";

            let finalCard = `${data.data.finalCard.cardValue.replace(/,/g, '').replace(/(\r\n|\n|\r)/gm, '')},`
            let topFiveString = "";
            let nimpString = formatPileStrings(nimp);
            let vimpString = formatPileStrings(vimp);
            let impString = formatPileStrings(imp);
            let worksheetString = `${surveyResponses.goalActionOne.replace(/,/g, '').replace(/(\r\n|\n|\r)/gm, '')},${surveyResponses.goalActionTwo.replace(/,/g, '').replace(/(\r\n|\n|\r)/gm, '')},` +
            `${surveyResponses.goalActionThree.replace(/,/g, '').replace(/(\r\n|\n|\r)/gm, '')},${surveyResponses.barriers.replace(/,/g, '').replace(/(\r\n|\n|\r)/gm, '')},${surveyResponses.resolution.replace(/,/g, '').replace(/(\r\n|\n|\r)/gm, '')}`;

            for (let index = 0; index < 5; index++) {
                
                if(index <= topFive.length -1)
                {
                    topFiveString += `${topFive[index].cardValue.replace(/,/g, '').replace(/(\r\n|\n|\r)/gm, '')},`
                }
                else
                {
                    topFiveString += ','
                }
                
            }


            let dataString = `${completedDate},${finalCard}${topFiveString}${nimpString}${impString}${vimpString}${worksheetString}`

            return dataString;
            

        }

        const formatPileStrings = (pile) =>
        {
            let str = ""

            for (let index = 0; index < pile.length; index++) {
                const element = pile[index];
                
                if(index === pile.length -1)
                {
                    str += `${element.cardValue},`
                }
                else
                {
                    str += `${element.cardValue.replace(/,/g, '').replace(/(\r\n|\n|\r)/gm, '')}:`
                }
                
            }

            return str;
        }

        return{groupData, groupSelection, handleSubmit, downloadOneGroupData, downloadAllGroupData}
    }
}
</script>

<style>

</style>