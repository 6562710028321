<template>
  <div class="draggableCard">
    <h3>User's Card</h3>
    <draggable
      :list="currentCard"
      group="values"
      itemKey="index"
      class="cardSlot"
      :options="{disabled: true}"
       :move="onMoved"
        :onAdd="onMoved"
    >
      <template #item="{}">
        <div class="list-group-item">
          <div v-if="currentCard[0] !== 1">
              <Cards :imgURL="currentCard" :small="true" :hoverDisabled="true"/>
          </div>
          <div v-else>
            <h2>Task Complete</h2>
          </div>
          
        </div>
      </template>
    </draggable>
  </div>
  <div class="cardHeaders">
    <button @click="expandPile(2)" :class="{selected:isSelected[0]}">
      Very Important to me<span class="material-icons-round">
        chevron_right</span
      >
    </button>
    <button @click="expandPile(1)" :class="{selected:isSelected[1]}">
      Important to me<span class="material-icons-round"> chevron_right</span>
    </button>
    <button @click="expandPile(0)" :class="{selected:isSelected[2]}">
      Not Important to me<span class="material-icons-round">
        chevron_right</span
      >
    </button>
  </div>

  <div class="cardRow">
    <div class="col-3">
      <draggable
        :list="veryImportantPile"
        :sort="false"
        ghost-class="ghost"
        group="values"
        itemKey="index"
        id="vimp"
        :options="{disabled: true}"
        :move="onMoved"
        :onAdd="onMoved"
      >
        <template #item="{ element }">
          <div class="list-group-item">
            <div class="piles">
              <Cards :imgURL="element.cardURL" :small="true" :shifted="true" :hoverDisabled="true"/>
            </div>
          </div>
        </template>
      </draggable>
    </div>

    <div class="col-3">
      <draggable
        :list="importantPile"
        :sort="false"
        ghost-class="ghost"
        itemKey="index"
        group="values"
        id="vimp"
        :options="{disabled: true}"
         :move="onMoved"
        :onAdd="onMoved"
      >
        <template #item="{ element }">
          <div class="list-group-item">
            <div class="piles">
              <Cards :imgURL="element.cardURL" :small="true" :shifted="true" :hoverDisabled="true"/>
            </div>
          </div>
        </template>
      </draggable>
    </div>
    <div class="col-3">
      <draggable
        :list="notImportantPile"
        :sort="false"
        :options="{handle: '.drag-only-this'}"
        ghost-class="ghost"
        group="values"
        itemKey="index"
        id="vimp"
         :move="onMoved"
        :onAdd="onMoved"
      >
        <template #item="{ element }">
          <div class="list-group-item">
            <div class="piles">
              <Cards :imgURL="element.cardURL" :small="true" :shifted="true" :hoverDisabled="true"/>
            </div>
          </div>
        </template>
      </draggable>
    </div>
  </div>

  <div v-if="showPile" class="pileView">
    <TaskOnePileView :cardsToDisplay="currentViewPile" :interactable="false" />
    <button @click="expandPile(-1)">Return</button>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import draggable from "vuedraggable";
import TaskOnePileView from "../../ClientComps/TaskComps/TaskOne/TaskOnePileView.vue";
import Cards from "../../ClientComps/TaskComps/Cards.vue";
import { onBeforeMount, nextTick } from "@vue/runtime-core";
import { projectFirestore } from '../../../firebase/config';
import { NavigationFailureType } from 'vue-router';
export default {
  components: { draggable, Cards, TaskOnePileView },
  props: ["userData", "selectedWeek"],
  setup(props) {
    const notImportantPile = ref([]);
    const importantPile = ref([]);
    const veryImportantPile = ref([]);
    const currentViewPile = ref([]);

    const nextImg = ref("");
    const data = ref(props.userData);
    const isSelected = ref(false, false, false)
    const currentCard = ref([
      { cardValue: "to hold nine", index: 10, cardURL: "" },
    ]);
    const lastPile = ref(-1);
    const showPile = ref(false);
    const cardPack = ref(-1);
    onBeforeMount(async () => {
      notImportantPile.value = data.value.data.nimp;
      importantPile.value = data.value.data.imp;
      veryImportantPile.value = data.value.data.vimp;

      await fetchCardPack();
      findLastCard();
      
    });

    

    const fetchCardPack = async () => 
    {
        let res = await projectFirestore.collection("AvailableTasks").doc("TaskList").get()
        let data = res.data();

        let taskName = data.TaskPackName[props.selectedWeek.id];

        let dataPack = await projectFirestore.collection("CardTasks").doc(taskName).get();
        let dataPackData= dataPack.data();
        
        cardPack.value = dataPackData[dataPackData.name];
        
        
    }

    const findLastCard = () => 
    {
       if(cardPack.value === -1)
       {
         
         return;
       }
       
      if(notImportantPile.value.length === 0 && veryImportantPile.length === 0
      && importantPile.value.length === 0)
      {
        currentCard.value = [cardPack.value[0]]
        return;
      }

       if(notImportantPile.value.length + importantPile.value.length 
       + veryImportantPile.value.length === cardPack.value.length)
       {
         
         currentCard.value = [1]
         return;
       }

       let maxIndex = 0;
       


       if(notImportantPile.value.length > 0 && notImportantPile.value[notImportantPile.value.length -1].index > maxIndex)
       {
         maxIndex = notImportantPile.value[notImportantPile.value.length -1].index
       }
       
         if(importantPile.value.length > 0 && importantPile.value[importantPile.value.length -1].index > maxIndex)
       {
         maxIndex = importantPile.value[importantPile.value.length -1].index
       }

         if(veryImportantPile.value.length > 0 && veryImportantPile.value[veryImportantPile.value.length -1].index > maxIndex)
       {
         maxIndex = veryImportantPile.value[veryImportantPile.value.length -1].index
       }

       
       
       currentCard.value = [cardPack.value[maxIndex + 1]]
    }

    const expandPile = (val) => {
      
      isSelected.value = [false, false, false]
      showPile.value = false;
      if(lastPile.value === val)
      {
        lastPile.value = -1
        return;
      }
      if (val === -1) 
      {
        showPile.value = false;
      } 
      else 
      {
        if (val === 0) 
        {
          currentViewPile.value = notImportantPile.value;
          isSelected.value[0] = true;
          lastPile.value = 0;
        } 
        else if (val === 1) 
        {
          currentViewPile.value = importantPile.value;
          isSelected.value[1] = true;
          lastPile.value = 1;
        } 
        else if (val === 2) 
        {
          currentViewPile.value = veryImportantPile.value;
          isSelected.value[2] = true;
          lastPile.value = 2;
        }
        else 
        {  
        isSelected.value = [false, false, false]
          currentViewPile.value = [];
          return;
        }
        nextTick(() => {
          showPile.value = true;
        });
      }
    };

    const onMoved = () => 
    {
      reorderArray(notImportantPile);
      reorderArray(importantPile);
      reorderArray(veryImportantPile);
      return false;
    }

     const reorderArray = (arr) => {
      for (let i = 1; i < arr.value.length; i++) {
        for (let j = i - 1; j > -1; j--) {
          if (arr.value[j + 1].index < arr.value[j].index) {
            [arr.value[j + 1], arr.value[j]] = [arr.value[j], arr.value[j + 1]];
          }
        }
      }
    };

    return {
      notImportantPile,
      importantPile,
      veryImportantPile,
      currentViewPile,
      showPile,
      currentCard,
      nextImg,
      data,
      expandPile,
      onMoved,
      isSelected
    };
  },
};
</script>

<style>
.cardHeaders .selected
{
  background: #7CCCBF;
}
</style>