<template>
  <Navbar />
  <SideNav :selectedView="{'id': 1}"/>

  <div class="content">
      <ManageUsersPanel />
  </div>
  
</template>

<script>
import Navbar from "../../components/Navbar.vue";
import SideNav from "../../components/AdminComps/SideNav.vue";
import ManageUsersPanel from "../../components/AdminComps/UsersGroup/ManageUsersPanel.vue"


export default 
{
    components: { Navbar, SideNav, ManageUsersPanel},
    setup() 
    {


    }
}
</script>

<style>

</style>